import * as React from "react";
import { Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Title } from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {
    flexWrap: 'wrap',
  },
  grid: {
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  leftGrid: {
    marginBottom: 'auto',
    marginRight: 'auto',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <>
      <Title title={localStorage.getItem('placeName') || "PontuaX Frota"} />
      <div className={classes.root}>
        <Grid container className={classes.grid}>
        </Grid>
      </div>
    </>
  )
}