export enum FillinStatus {
  reproved = 'reproved',
  approved = 'approved',
};

export namespace FillinStatus {
  export const translateStatus = (type: FillinStatus): string => {
    switch (type) {
      case FillinStatus.reproved:
        return "Reprovado"
      case FillinStatus.approved:
        return "Aprovado";
      default: return "";
    };
  };

  export const fillinStatusChoices: Array<{ id: string, name: string }> = Object.values(FillinStatus)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: FillinStatus.translateStatus(type as FillinStatus),
    } as { id: string, name: string }));
};