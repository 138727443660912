import { Box, Typography } from "@mui/material";
import { sanitizeFieldRestProps, useRecordContext } from "react-admin";
import get from "lodash/get";

const CustomizableImageField = (props) => {
  const {
    className,
    imgClass,
    emptyText,
    source,
    title,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <Box className={className} {...sanitizeFieldRestProps(rest)}>
      <img
        src={sourceValue}
        title={titleValue}
        alt={titleValue}
        className={imgClass}
      />
    </Box>
  );
};

export default CustomizableImageField;
