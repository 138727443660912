import React, { useState } from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { NotInterested as NotInterestedIcon } from '@mui/icons-material';
import { getChainId } from '../../utils/utils';

const UnlinkCompanyButton: React.FC = (props) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    dataProvider.create(`chains/${getChainId()}/unlink-company`, {
      data: {
        companyId: record.id
      }
    })
      .then(() => {
        notify(`Transportadora inativada com sucesso`);
        refresh();
      })
      .catch((e) => {
        notify(e.message || 'Não foi possível inativar transportadora', { type: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Button
      {...props}
      disabled={isLoading}
      label="Inativar transportadora"
      onClick={handleClick}
      startIcon={<NotInterestedIcon />}
    />
  );
}

export default UnlinkCompanyButton;