import { BooleanField, BooleanInput, Create, Datagrid, DeleteButton, Edit, EditButton, FormTab, FunctionField, ReferenceField, ReferenceInput, SelectInput, Show, Tab, TabbedForm, TabbedShowLayout, TextField, TextInput, email, minLength } from 'react-admin';
import { CnpjField, List, Permission } from '../components';
import { SearchFilter } from '../components/SearchFilter';
import { PERMISSIONS } from '../constants';
import { CEPInput, CNPJInput, PhoneInput, StateInput } from '../utils/Inputs';
import { formatCellphone, formatCep, getCompanyId } from '../utils/utils';

const required = (message = 'Obrigatório') =>
  value => value || value === 0 ? undefined : message;

const VehicleBaseEdit = (props) => {
  return (
    <Edit
      title='Editar filial de veículos'
      {...props}
    >
      <TabbedForm defaultValues={{ companyId: getCompanyId() }}>
        <FormTab label="Identificação">
          <ReferenceInput
            label="Transportadora"
            source="subCompanyId"
            reference="sub-companies"
            filter={{ companyId: getCompanyId() }}
            sort={{ field: "name", order: "ASC" }}
            validate={required()}>
            <SelectInput label="Transportadora" optionText="name" />
          </ReferenceInput>
          <TextInput source="name" label="Nome" validate={required()} />
          <TextInput source="code" label="Código" validate={required()} />
          <CNPJInput source="cnpj" label="CNPJ" isRequired />
          <TextInput source="corporateName" label="Razão Social" />
          <TextInput source="commercialName" label="Nome Fantasia" />
          <TextInput source="nickName" label="Apelido" />
          <TextInput source="stateRegistration" label="Incrição Estadual" />
          <TextInput source="email" label="Email" type="email" validate={[email(), required()]} />
          <PhoneInput source="phone" label="Telefone" validate={minLength(10)} />
          <BooleanInput source="mustIssueInvoice" label="Exigir emissão de nota fiscal eletrônica" />
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <CEPInput source="cep" label="CEP" isRequired />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const VehicleBaseCreate = (props) => (
  <Create title='Cadastrar filial de veículos' {...props}>
    <TabbedForm defaultValues={{ companyId: getCompanyId() }}>
      <FormTab label="Identificação">
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput source="companyId" reference="companies" >
            <SelectInput label="Grupo de Transportadora" optionText="name" validate={required()} />
          </ReferenceInput>
        </Permission>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: getCompanyId() }}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}>
          <SelectInput label="Transportadora" optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="code" label="Código" validate={required()} />
        <CNPJInput source="cnpj" label="CNPJ" isRequired />
        <TextInput source="corporateName" label="Razão Social" />
        <TextInput source="commercialName" label="Nome Fantasia" />
        <TextInput source="nickName" label="Apelido" />
        <TextInput source="stateRegistration" label="Incrição Estadual" />
        <TextInput source="email" label="Email" type="email" validate={[email(), required()]} />
        <PhoneInput source="phone" label="Telefone" validate={minLength(10)} />
        <BooleanInput source="mustIssueInvoice" label="Exigir emissão de nota fiscal eletrônica" />
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" />
        <TextInput source="streetNumber" label="Número" />
        <TextInput source="neighbourhood" label="Bairro" />
        <TextInput source="city" label="Cidade" />
        <StateInput source="state" label="Estado" />
        <CEPInput source="cep" label="CEP" isRequired />
      </FormTab>
    </TabbedForm>
  </Create>
);

const VehicleBaseList = props => (
  <List
    {...props}
    title="Filiais de veículos"
    filters={<SearchFilter />}
    filter={getCompanyId() ? { companyId: getCompanyId() } : null}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies">
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="code" label="Código" />
      <TextField source="name" label="Nome" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const VehicleBaseShow = (props) => (
  <Show title="Detalhes da filial" {...props}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceField source="companyId" reference="companies" >
            <TextField label="Grupo de Transportadora" source="name" />
          </ReferenceField>
        </Permission>
        <ReferenceField
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
        >
          <TextField label="Transportadora" source="name" />
        </ReferenceField>
        <TextField source="name" label="Nome" />
        <TextField source="code" label="Código" />
        <CnpjField source="cnpj" label="CNPJ" />
        <TextField source="corporateName" label="Razão Social" emptyText="--" />
        <TextField source="commercialName" label="Nome Fantasia" emptyText="--" />
        <TextField source="nickName" label="Apelido" emptyText="--" />
        <TextField source="stateRegistration" label="Incrição Estadual" emptyText="--" />
        <TextField source="email" label="Email" type="email" emptyText="--" />
        <FunctionField label="Telefone" render={record => record && record.phone ? formatCellphone(record.phone) : '--'} />
        <BooleanField source="mustIssueInvoice" label="Exigir emissão de nota fiscal eletrônica" />
      </Tab>
      <Tab label="Endereço">
        <TextField source="street" label="Rua" emptyText="--" />
        <TextField source="streetNumber" label="Número" emptyText="--" />
        <TextField source="neighbourhood" label="Bairro" emptyText="--" />
        <TextField source="city" label="Cidade" emptyText="--" />
        <TextField source="state" label="Estado" emptyText="--" />
        <FunctionField label="CEP" render={record => record && record.cep ? formatCep(record.cep) : '--'} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = {
  list: VehicleBaseList,
  edit: VehicleBaseEdit,
  create: VehicleBaseCreate,
  show: VehicleBaseShow,
  name: 'vehicle-bases',
  permissions: [PERMISSIONS.LIST_VEHICLE_BASES]
}
export default resource;