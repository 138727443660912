import React from 'react';
import { Grid, Typography, Chip } from '@mui/material';
import { StatusEnum } from './statusEnum';
import { formatPrice } from '../../utils/utils';

export const Delivering: React.FC<any> = ({ deliveringInfo, status }) => {

  const FillinStatusChip = ({ status }) => {
    if (status === StatusEnum.pendingAuthorization) {
      return <Chip label="Autorizando" />;
    } else if (status === StatusEnum.authorized || status === StatusEnum.waiting) {
      return <Chip label="Aguardando início" color="warning" />;
    } else if (status === StatusEnum.delivering) {
      return <Chip label="Abastecendo" color="primary" />;
    } else {
      return <Chip label="Finalizado" color="success" />;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1" gutterBottom>Status: </Typography>
        </Grid>
        <Grid item xs={10}>
          <FillinStatusChip status={status} />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" gutterBottom>Valor: </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle1" gutterBottom>{formatPrice(deliveringInfo.value)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" gutterBottom>Litros: </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle1" gutterBottom>{new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(deliveringInfo.amount || 0)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};