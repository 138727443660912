import React from "react";
import { List, ListPagination, Permissions } from '../components';
import { getCompanyId } from "../utils/utils";
import { Button, Datagrid, DateField, FunctionField, ReferenceField, TextField, useDataProvider, useNotify, useRecordContext, useRefresh } from "react-admin";
import { PERMISSIONS } from "../constants";
import { UserRole } from "../providers/authProvider";

const ApprovedFillinProcess = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleAuthorization = () => {
    dataProvider.update("otp-code", {
      id: record.id,
      data: { id: record.id },
      previousData: record
    }).then((_response) => {
      notify("Código Autorizado");
      refresh();
    }).catch((error) => notify(error && error.message));
  };

  return (
    <Button
      disabled={record.authorizedByEmployeeId}
      label={record.authorizedByEmployeeId ? "Autorizado" : "Autorizar"}
      onClick={handleAuthorization}
      color="primary"
      variant="contained"
      size="medium"
      style={{ width: 120 }}
    />
  );
};

const ListOtpCode: React.FC = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      filter={{ companyId: getCompanyId() }}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
      title="Códigos OTP"
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField reference="drivers" source="driverId" label="Motorista">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="stations" source="stationId" label="Posto">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Código" render={(record) => record.code?.toString().padStart(4, 0)} />
        <DateField source="createdAt" label="Solicitado em" locales="pt-BR" showTime />
        <ApprovedFillinProcess />
      </Datagrid>
    </List>
  );
};

const resource = {
  list: ListOtpCode,
  name: 'otp-code',
  permissions: [PERMISSIONS.LIST_OTP_CODES]
};

export default resource;