import React, { useCallback, useEffect, useState } from 'react';
import { WizardForm } from '../../components';
import { SocketProvider } from '../../context/socket';
import { useSocket } from '../../hooks/useSocket';
import { SelectFuelAndLimitType } from './selectFuelAndLimitType';
import { FormInitialInfo } from './formInitialInfo';
import { Button, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { getUserId } from '../../utils/utils';
import { StatusEnum } from './statusEnum';
import { Delivering } from './delivering';
import DialogOptCode from './DialogOtpCode';

export const FillinProcess = () => {

  const { emit, subscribeListener, unsubscribeAllListeners } = useSocket();
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const employeeId = getUserId();
  const [reserveId, setReserveId] = useState<string | null>(null);
  const [showFinished, setShowFinished] = useState<boolean>(false);
  const [deliveringInfo, setDeliveringInfo] = useState<{ value: number, amount: number }>({ value: 0, amount: 0 });
  const [hoses, setHoses] = useState([]);
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.pendingReserve);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [otpData, setOtpData] = useState<{ [key: string]: string }>();
  const [initialInfoData, setInitialInfoData] = useState<{ data: any, func: () => void } | undefined>();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = useCallback(() => {
    unsubscribeAllListeners();
  }, [unsubscribeAllListeners]);

  const resetInitialValues = () => {
    setReserveId(null);
    setShowFinished(false);
    setError(null);
    setStatus(StatusEnum.pendingReserve);
    setHoses([]);
  };

  const createOptCode = ({ code, driverId }) => {
    dataProvider.create('otp-code', { data: { qrcode: code, driverId } })
      .then((respose) => {
        setOtpData({ id: respose.data.id, stationId: respose.data.stationId });
        handleOpen();
      }).catch((err) => {
        notify(err && err.message, { type: 'warning' });
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOtpCode = ({ code }) => {
    dataProvider.create(`otp-code/verify/${otpData?.id}`, {
      data: { code, stationId: otpData?.stationId },
    })
      .then((_respose) => {
        handleClose();
        requestInicialFillin();
      }).catch((err) => {
        notify(err && err.message, { type: 'warning' });
        return;
      });
  };

  const requestInicialFillin = () => {

    if (!initialInfoData) return;

    const { driverId, code } = initialInfoData.data;

    subscribeListener(`/driver/${driverId}/initialInfo/${code}`, (data) => handleInitialInfo(data, initialInfoData.data));
    // start fillin
    requestInitialInfo(initialInfoData.data);
    initialInfoData.func();
  };

  const requestInicialVerify = (formData: any, nextStep: () => void) => {
    const { driverId, password, companyId, odometer, vehicleId, code } = formData;

    setLoading(true);
    setInitialInfoData({ data: formData, func: () => nextStep() });

    dataProvider.create('fillin/verify/rules', { data: { password, companyId, driverId, odometer, vehicleId, code } })
      .then((_response) => {
        createOptCode(formData);
      }).catch((err) => {
        notify(err && err.message, { type: 'warning' });
        setLoading(false);
        return;
      });

  };

  const requestInitialInfo = ({ code, driverId }) => {
    const initialInfoParams = {
      code, driverId, employeeId,
    };

    emit('initialInfo', initialInfoParams);
  };

  const requestAuthorization = ({ fuelType, limitType, limitValue }) => {
    const selectedHose: any = hoses.find((h: any) => h.fuel.fuelType === parseInt(fuelType));
    if (!selectedHose) { return; }

    console.log('autorizando a reserva: ', reserveId);
    emit('authorizeFillIn', {
      reserveId,
      limitType: limitType === 'X' ? 'V' : limitType,
      limitValue: limitType === 'X' ? null : limitValue,
      fuelType: parseInt(fuelType),
      hoseNumber: selectedHose.hoseNumber,
      price: selectedHose.price,
    });
  };

  const handleInitialInfo = (data, { driverId, vehicleId, code, odometer }) => {
    if (data.error) {
      unsubscribeAllListeners();
      setError({ message: data.error });
      return;
    }

    const {
      fuelPointNumber, stationId
    } = data;

    subscribeListener(`/station/${stationId}/pump/${fuelPointNumber}/driver/${driverId}`, (data) => {
      handleFillInData(data);
    });

    emit('reserveFillIn', { fuelPointNumber, stationId, driverId, vehicleId, code, employeeId, odometer });
  };

  const handleFillInData = (reserve: any) => {
    setStatus(reserve.status);

    switch (reserve.status) {
      case StatusEnum.pendingReserve:
        console.log('setando reserveId: ', reserve.reserveId);
        setReserveId(reserve.reserveId);
        break;
      case StatusEnum.reserved:
        setHoses(reserve.hoses || []);
        break;
      case StatusEnum.delivering:
        setDeliveringInfo({ value: reserve.value, amount: reserve.volume });
        break;
      case StatusEnum.finished:
        setDeliveringInfo({ value: reserve.fillin.value, amount: reserve.fillin.amount });
        unsubscribeAllListeners();
        setShowFinished(true);
        break;
      case StatusEnum.error:
        unsubscribeAllListeners();
        setError({ message: reserve.message });
        break;
      default:
        break;
    }
  };

  const handleAuthorizationButton = (formData, nextStep) => {
    requestAuthorization(formData);
    nextStep();
  };

  return (
    <SocketProvider>
      {
        error ?
          <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom textAlign={'center'}>
              {error.message}
            </Typography>
            <Button label="iniciar novamente" onClick={() => resetInitialValues()} variant="outlined" size="medium" />
          </Box>
          :
          showFinished === true ?
            <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
              <Typography variant="h5" gutterBottom textAlign={'center'} style={{ marginBottom: '30px' }}>
                Abastecimento finalizado
              </Typography>
              <Button label="Ir para abastecimentos" onClick={() => redirect('/fillins')} variant="outlined" size="medium" />
            </Box>
            :
            <>
              <WizardForm
                formSteps={[
                  {
                    component: <FormInitialInfo />,
                    onNextFunction: requestInicialVerify,
                    disableToolbarButton: loading,
                    loadingToolbarButton: loading
                  },
                  {
                    component: <SelectFuelAndLimitType hoses={hoses} status={status} />,
                    onNextFunction: handleAuthorizationButton,
                    disableToolbarButton: status === StatusEnum.pendingReserve,
                  },
                  {
                    component: <Delivering deliveringInfo={deliveringInfo} status={status} />,
                    disableToolbar: true,
                  },
                ]}
              />
              <DialogOptCode handleClose={handleClose} open={open} handleOtpCode={handleOtpCode} />
            </>

      }
    </SocketProvider>
  );
};