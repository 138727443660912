import { PERMISSIONS } from '../../constants'
import list from './ProfileList'
import create from './ProfileCreate'
import edit from './ProfileEdit'
import ComponentWithPermissions from '../ComponentWithPermissions'

export default {
  edit: new ComponentWithPermissions({ component: edit, permissions: [PERMISSIONS.UPDATE_PROFILE] }),
  create: new ComponentWithPermissions({ component: create, permissions: [PERMISSIONS.CREATE_PROFILE] }),
  list,
  name: 'profiles',
  permissions: [PERMISSIONS.LIST_PROFILES],
  options: { label: 'Perfis' }
}
