import { PERMISSIONS } from '../../constants';
import ComponentWithPermissions from '../ComponentWithPermissions';
import ContractCreate from './contractCreate';
import ContractEdit from './contractEdit';
import ContractList from './contractList';

const resource = {
  list: ContractList,
  edit: new ComponentWithPermissions({ component: ContractEdit, permissions: [PERMISSIONS.EDIT_CONTRACTS] }),
  create: new ComponentWithPermissions({ component: ContractCreate, permissions: [PERMISSIONS.CREATE_CONTRACTS] }),
  name: 'contracts',
  permissions: [PERMISSIONS.LIST_CONTRACTS]
}

export default resource;