import React from 'react';
import { useListContext } from 'react-admin';

import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: '#f3f3f3',
    padding: 15,
    paddingBottom: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#000',
    borderStyle: 'solid',
  },
  labelToolTip: {
    fontSize: 14,
  },
}));

const VehicleAverageChart = ({ styleContent = {} }) => {
  const props = useListContext();
  const classes = useStyles();
  if (!props.data) { return <></>; }

  const data = Object.keys(props.data).map(k => ({ ...props.data[k], y: 0 }));

  const CustomTooltip = ({ active, payload, label, labelFormatter }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.customTooltip}>
          <span className={classes.labelToolTip}>{labelFormatter(label)}</span>
          {
            payload[0].payload.driver && (
              <p className={classes.labelToolTip}>{`Motorista: ${payload[0].payload.driver.name}`}</p>
            )
          }
          {
            payload.map(data => {
              return (
                <>
                  {
                    data.dataKey === 'average' && (
                      <>
                        {data.payload.average && <p className={classes.labelToolTip} style={{ color: data.color }}>{`${data.name} : ${formatNumber(data.payload.average, { maximumFractionDigits: 2 })} ${data.unit}`}</p>}
                      </>
                    )
                  }
                  {
                    data.dataKey === 'y' && (
                      <>
                        {data.payload.amount && <p className={classes.labelToolTip} style={{ color: data.color }}>{`${data.name} : ${formatNumber(data.payload.amount, { maximumFractionDigits: 2 })} ${data.unit}`}</p>}
                      </>
                    )
                  }
                </>
              );
            })
          }
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: '100%', paddingTop: 0, paddingBottom: 40, ...styleContent }}>
      <Typography variant="subtitle1" component="h5" align="center">
        Obs: A média só é calculada quando o abastecimento for do tipo "Encher tanque".
      </Typography>

      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 40,
            right: 30,
            left: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid />
          <XAxis tickFormatter={value => moment(value).format('DD/MM/YYYY HH:mm')} dataKey="createdAt" tickMargin={10} padding={{ left: 80, right: 80 }} />
          <YAxis label={{ value: 'Km/l', angle: -90, position: 'insideLeft' }} />
          <Tooltip
            labelFormatter={(value) => {
              const formattedValue = moment(value).format('DD/MM/YYYY HH:mm');
              return data.length > 0 ? `${data[0].licensePlate} - ${formattedValue}` : formattedValue;
            }}
            formatter={(value) => {
              return formatNumber(value, { maximumFractionDigits: 2 });
            }}
            content={<CustomTooltip />}
          />
          <Legend
            wrapperStyle={{ bottom: -20 }}
          />
          <Line
            connectNulls
            unit="Km/l"
            name="Média"
            dataKey="average"
            stroke="#ff7300"
          />
          <Scatter dataKey="y" unit="litros" name="Abastecimento" fill="#00a2ffdc" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VehicleAverageChart;