export enum ContractType {
  accepted = 'accepted',
  pending = 'pending',
  notApprove = 'not-approve',
  preApproved = 'pre-approved',
  deprecated = 'deprecated',
};

export namespace ContractType {
  export const transalateType = (type: ContractType): string => {
    switch (type) {
      case ContractType.accepted:
        return "Aprovado"
      case ContractType.pending:
        return "Pendente";
      case ContractType.notApprove:
        return "Não aprovado";
      case ContractType.preApproved:
        return "Pré-aprovado";
      case ContractType.deprecated:
        return "Descontinuado";
      default: return "";
    };
  };
};