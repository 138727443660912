import React from "react";
import { Datagrid, DateField, DateInput, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, useRecordContext } from 'react-admin';
import { ReportList, ListPagination, CircleStatus, ColoredDiferenceValueField, Permission, CustomizableDatagrid, VehicleAverageChart, List } from '../../components';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { PERMISSIONS } from "../../constants";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const AveragePanel = () => {
  const { filterValues } = useListContext();
  const record = useRecordContext();
  const data = record.driverAverages;

  return (
    <>
      <List
        resource="vehicles/averages"
        title="Média"
        filter={{
          vehicleId: record.id,
        }}
        filterDefaultValues={{
          from: filterValues.from,
          to: filterValues.to,
        }}
        pagination={false}
        exporter={false}
      >
        <VehicleAverageChart />
      </List>

      <Datagrid data={data} total={data.length} bulkActionButtons={false} style={{ marginTop: 30, marginBottom: 30 }}>
        <ReferenceField label="Motorista" reference="drivers" source="driverId" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="amount"
          label="Volume Gasto (litros)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="value"
          label="Valor Total"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="expectedAverage"
          label="Média ideal (Km/l)"
          textAlign="center"
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          sortable={false}
        />
        <NumberField
          source="average"
          label="Média Real (Km/l)"
          textAlign="center"
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          sortable={false}
        />
        <FunctionField
          label="Eficiência (%)"
          sortBy="averageEfficiency"
          textAlign="center"
          render={record => (<CircleStatus value={record.averageEfficiency} emptyText="--" />)}
          sortable={false}
        />
        <ColoredDiferenceValueField
          source="diffValue"
          label="Diferença (R$)"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid >
    </>
  );
}


const VehicleAverageList = () => {
  const classes = useStyles();
  const props = useListContext();

  return (
    <>
      <Typography className={classes.title}>Médias por Veículo</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['name', 'model', 'license_plate', 'totalValue', 'totalAmount', 'averageEfficiency', 'diffValue']}
        expand={<AveragePanel />}
        bulkActionButtons={false}
        isRowExpandable={row => row.driverAverages.length > 0}
      >
        <TextField source="name" label="Nome" textAlign="left" />
        <TextField source="model" label="Modelo" textAlign="center" />
        <TextField source="license_plate" label="Placa" textAlign="center" />
        <NumberField
          source="totalAmount"
          label="Volume Gasto (litros)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="totalValue"
          label="Valor Total"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <FunctionField
          label="Eficiência (%)"
          sortBy="averageEfficiency"
          textAlign="center"
          render={record => (<CircleStatus value={record.averageEfficiency} emptyText="--" />)}
        />
        <ColoredDiferenceValueField
          source="diffValue"
          label="Diferença (R$)"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permission permission={PERMISSIONS.ADMIN} label="Grupo de Transportadora" textAlign="center">
        <ReferenceField
          source="companyId"
          reference="companies"
          sort={{ field: "name", order: "ASC" }}
          emptyText="Todos"
        >
          <TextField
            source={'name'}
          />
        </ReferenceField>
      </Permission>,
      <ReferenceField
        label="Motorista"
        source="driverId"
        reference="drivers"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        textAlign="center"
      >
        <TextField
          source={'name'}
        />
      </ReferenceField>,
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const VehicleAveragesReport = (props) => {
  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        style={{ width: 225 }}
      >
        <SelectInput
          label="Grupo de Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <ReferenceInput
      source="managerId"
      reference="managers"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        label="Gestor"
        optionText={'name'}
      />
    </ReferenceInput>,
    <ReferenceInput
      source="vehicleBaseId"
      reference="vehicle-bases"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput
        label="Filial"
        optionText={'name'}
        emptyText="Todos"
      />
    </ReferenceInput>,
    <ReferenceInput
      source="vehicleGroupId"
      reference="vehicle-groups"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        label="Grupo de veículo"
        optionText={'name'}
      />
    </ReferenceInput>,
  ];

  return (
    <ReportList
      {...props}
      basePath="vehicles"
      title="Média por Veículo"
      resource="reports/vehicle-averages"
      filters={listFilters}
      sort={{ field: 'averageEfficiency', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().startOf('day').subtract(1, 'month').toISOString(),
        to: moment().endOf('day').toISOString(),
        companyId: localStorage.getItem('companyId'),
      }}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={false}
      titleOnPrint="Relatório de médias"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <VehicleAverageList />
    </ReportList>
  );
};

export default VehicleAveragesReport;