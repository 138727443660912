import React, { useState } from 'react';
import { Form, minLength, Notification, required, TextInput, useLogin, useNotify, useTranslate } from 'react-admin';

import { Avatar, Box, Button, CardActions, CircularProgress, Container, CssBaseline, Theme, Typography } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import frotaLogo from '../../assets/logo.png';
import useUpdatePassword from '../../hooks/updatePassword';
import { theme } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  containerCard: {
    padding: 20,
    marginTop: '0px !important',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  containerMain: {
    height: '100vh',
    width: '100%',
    background: 'radial-gradient(circle, rgba(41,208,255,1) 0%, rgba(0,145,173,1) 100%);',
    padding: 20,
    paddingTop: 40,
  }
}));

const LoginForm = React.memo(({ handleLogin, loading }: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Form onSubmit={handleLogin} noValidate>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          className={classes.containerCard}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar style={{ width: 70, height: 70 }}>
            <img style={{ width: '100%', height: '100%' }} src={frotaLogo} />
          </Avatar>
          <Typography component="h6" variant="h6">
            Área de acesso
          </Typography>

          <Box sx={{ mt: 1 }}>
            <TextInput
              autoFocus
              source="username"
              label={translate('ra.auth.username')}
              validate={[required()]}
              inputProps={{ disabled: loading }}
              fullWidth
            />
            <TextInput
              source="password"
              type="password"
              label={translate('ra.auth.password')}
              validate={[required()]}
              inputProps={{ disabled: loading }}
              fullWidth
            />
            <CardActions sx={{ padding: '0 1em 1em 1em' }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && (
                  <CircularProgress size={25} thickness={2} />
                )}
                Entrar
              </Button>
            </CardActions>
          </Box>
        </Box>
      </Container>
    </Form>
  );
});


const ChangePasswordForm = ({ handleChangePassword, updatePasswordValidate, loading }: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Form onSubmit={handleChangePassword} validate={updatePasswordValidate} >
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          className={classes.containerCard}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar style={{ width: 70, height: 70 }}>
            <img style={{ width: '100%', height: '100%' }} src={frotaLogo} />
          </Avatar>
          <Typography component="h6" variant="h6">
            Defina uma nova senha
          </Typography>

          <Box sx={{ mt: 1 }}>
            <TextInput
              autoFocus
              inputProps={{ disabled: loading }}
              source="password"
              type="password"
              label={translate('ra.auth.new_password')}
              validate={[minLength(6), required()]}
              fullWidth
            />
            <TextInput
              inputProps={{ disabled: loading }}
              source="passwordConfirmation"
              type="password"
              label={translate('ra.auth.confirm_password')}
              validate={[minLength(6), required()]}
              fullWidth
            />
            <Button
              color="primary"
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <></>}
            >
              Alterar senha
            </Button>
          </Box>
        </Box>
      </Container>
    </Form>
  );
};

const login: React.FC = () => {

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const updatePassword = useUpdatePassword();

  const [loading, setLoading] = useState<boolean>(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState<boolean>(false);
  const [isMustChangePassword, setMustChangePassword] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any | null>(null);

  const errorMessage = {
    401: translate('ra.auth.sign_in_error'),
    404: 'Usuário não cadastrado',
    500: 'Error de servidor',
  }

  const handleLogin = (auth) => {
    setLoading(true);
    login(
      auth,
      location.state ? (location.state as any).nextPathname : '/'
    ).catch(async (error: any) => {

      setLoading(false);

      if (error.mustChangePassword === true) {
        setUserInfo(error);
        setMustChangePassword(true);
        return;
      }

      notify(
        error.status && Object.keys(errorMessage).includes(error.status.toString())
          ? errorMessage[error.status]
          : 'Não foi possível realizar o login',
        {
          type: 'error',
        }
      );
    });
  }

  const handleChangePassword = (params) => {
    const { password } = params;
    setUpdatePasswordLoading(true);
    updatePassword({
      id: userInfo.id,
      params: { password },
      user: userInfo,
      pathName: location.state ? (location.state as any).nextPathname : '/'
    }).catch((error) => {
      setUpdatePasswordLoading(false);

      notify(
        typeof error === 'string'
          ? error
          : Object.keys(errorMessage).includes(error.message)
            ? errorMessage[error.message]
            : error.message,
        {
          type: 'error',
        }
      );
    });
  }

  const updatePasswordValidate = (values: any) => {
    const errors: any = {};
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    if (!values.passwordConfirmation) {
      errors.passwordConfirmation = translate('ra.validation.required');
    } else if (values.passwordConfirmation !== values.password) {
      errors.passwordConfirmation = 'Senhas não coincidem';
    }
    return errors;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.containerMain}>
        {isMustChangePassword === false ?
          <LoginForm handleLogin={handleLogin} loading={loading} />
          :
          <ChangePasswordForm handleChangePassword={handleChangePassword} updatePasswordValidate={updatePasswordValidate} loading={updatePasswordLoading} />
        }
      </div>
      <Notification autoHideDuration={5000} />
    </ThemeProvider>
  );
}

export default login;