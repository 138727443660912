import React, { Fragment, useState } from 'react';
import { Button, Confirm, useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';

const ChatResolveButton = ({ ...props }) => {
  const record = useRecordContext(props);
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const labelbutton = `Marcar como resolvido`;

  const handleClick = (e: any) => {
    e.stopPropagation();
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleResolveChat = () => {
    dataProvider.update(`chats`, {
      id: record.id,
      data: { isResolved: true },
      previousData: record
    })
      .then(() => {
        notify(`Conversa marcada como resolvido`);
        refresh();
      })
      .catch((e) => {
        notify(e.message || 'Não foi possível marcar como resolvido', { type: 'warning' });
      });

    closeDialog();
  }

  return (
    record.isResolved === false ?
      <Fragment>
        <Confirm
          isOpen={showDialog}
          title={`Marcar como resolvido`}
          content={`Você deseja marcar essa conversa como resolvido?`}
          onConfirm={handleResolveChat}
          onClose={closeDialog}
        />
        <Button {...props} style={{ padding: 8 }} label={labelbutton} onClick={handleClick} startIcon={<CheckIcon />} />
      </Fragment>
      : <></>
  );
};

export default ChatResolveButton;