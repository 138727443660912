import { ArrayField, BooleanField, BooleanInput, Create, Datagrid, Edit, EditButton, Filter, FormDataConsumer, FormTab, FunctionField, ReferenceField, ReferenceInput, SearchInput, SelectField, SelectInput, Show, SimpleFormIterator, Tab, TabbedForm, TabbedShowLayout, TextField, TextInput, downloadCSV } from 'react-admin';
import { Grid } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { ArrayInput, List, Permission } from '../components';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';
import jsonExport from 'jsonexport/dist';
import { StateChoices } from '../models';

const required = (message = 'Obrigatório') =>
  (value: any) => value || value === 0 ? undefined : message;

const SelectStationInvoice: React.FC<any> = ({ source, scopedFormData, label, getSource, formData, ...props }) => {

  const field = useFieldArray({ name: getSource('vehicleBaseId') });

  const selectedState = formData.operationInvoices ? formData.operationInvoices.filter((state: any) => state).map(
    state => state.state,
  ) : [];

  const stateFiltredChoices = StateChoices.filter(({ value }) =>
    !selectedState.includes(value) ||
    (scopedFormData ? scopedFormData.state === value : false));

  return (
    <SelectInput
      {...props}
      focus
      choices={stateFiltredChoices}
      source={source}
      label={label}
      optionValue="value"
      optionText="label"
      onChange={() => {
        //@ts-ignore
        field.update(null);
      }}
    />
  );
}

const CodeTextField: React.FC<{ source: string, label: string }> = ({ source, ...rest }) => (
  <FunctionField
    {...rest}
    source={source}
    render={(record: any) => record ? ('0000' + record[source]).slice(-4) : ''}
  />
);

const OperationEdit = (props: any) => {
  return (
    <Edit
      title='Editar operação'
      transform={({ operationInvoices, ...data }: { operationInvoices: any[] }) => ({
        ...data,
        operationInvoices: operationInvoices.map(({ operationId, ...rest }) => rest),
      })}
      {...props}
    >
      <TabbedForm defaultValues={{ companyId: getCompanyId() }}>
        <FormTab label="Identificação">
          <Grid container lg={8} md={12} spacing={2}>
            <Grid item xs={12} md={8}>
              <Permission permission={PERMISSIONS.ADMIN}>
                <ReferenceInput source="companyId" reference="companies">
                  <SelectInput label="Grupo de Transportadora" optionText="name" validate={required()} fullWidth />
                </ReferenceInput>
              </Permission>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput source="name" label="Nome" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={12} md={8}>
              <BooleanInput source="isInactive" label="Inativar operação" />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Fiscal">
          <ArrayInput source="operationInvoices" label="Informe os estados dos postos e o destino da nota fiscal para cada estado selecionado">
            <SimpleFormIterator inline fullWidth disableReordering>
              <FormDataConsumer>
                {({ scopedFormData, getSource, ...props }) =>
                  <SelectStationInvoice {...props} scopedFormData={scopedFormData} source={getSource('state')} label="Estado do posto" getSource={getSource} emptyText="Selecionar" />
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ scopedFormData, getSource }: any) => (
                  scopedFormData.state &&
                  <ReferenceInput reference="vehicle-bases" source={getSource('vehicleBaseId')}>
                    <SelectInput required optionText="name" label="Filial da nota" emptyText="Selecionar" />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const OperationCreate = (props: any) => (
  <Create title='Cadastrar operação' {...props}>
    <TabbedForm defaultValues={{ companyId: getCompanyId() }}>
      <FormTab label="Identificação">
        <Grid container lg={8} md={12} spacing={2}>
          <Grid item xs={12} md={8}>
            <Permission permission={PERMISSIONS.ADMIN}>
              <ReferenceInput source="companyId" reference="companies" >
                <SelectInput label="Grupo de Transportadora" optionText="name" validate={required()} fullWidth />
              </ReferenceInput>
            </Permission>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextInput source="name" label="Nome" validate={required()} fullWidth />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Fiscal">
        <ArrayInput source="operationInvoices" label="Informe os estados dos postos e o destino da nota fiscal para cada estado selecionado">
          <SimpleFormIterator inline fullWidth disableReordering>
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...props }) =>
                <SelectStationInvoice {...props} scopedFormData={scopedFormData} source={getSource('state')} label="Estado do posto" getSource={getSource} emptyText="Selecionar" />
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ scopedFormData, getSource }: any) => (
                scopedFormData.state &&
                <ReferenceInput reference="vehicle-bases" source={getSource('vehicleBaseId')}>
                  <SelectInput required optionText="name" label="Filial da nota" emptyText="Selecionar" />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const FilterOperation = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      <SelectInput
        label="Status"
        source="isInactive"
        emptyText="Todos"
        choices={[{ id: true, name: "Inativos" }, { id: false, name: "Ativos" }]}
        alwaysOn
      />
    </Filter>
  )
}

const OperationList = (props: any) => {

  const operationsExporter = async (data: any[]) => {
    const operations = data.map(item => ({
      codigo: ('0000' + item['code']).slice(-4),
      nome: item['name'],
    }));

    jsonExport(operations, { rowDelimiter: ';' }, (err: Error, csv: string) => {
      downloadCSV(csv, 'operacoes');
    });
  }

  return (
    <List
      {...props}
      title="Operações"
      filters={<FilterOperation />}
      filter={getCompanyId() ? { companyId: getCompanyId() } : null}
      sort={{ field: 'code', order: 'DESC' }}
      filterDefaultValues={{ isInactive: false }}
      exporter={operationsExporter}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <CodeTextField source="code" label="Código" />
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies">
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        <TextField source="name" label="Nome" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

const OperationShow = (props: any) => (
  <Show title="Detalhes da operação" {...props}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <CodeTextField source="code" label="Código" />
        <TextField source="name" label="Nome" />
        <BooleanField source="isInactive" label="Inativar operação" />
      </Tab>
      <Tab label="Fiscal">
        <ArrayField source="operationInvoices" label="Filiais responsáveis pela emissão de Notas Fiscais">
          <Datagrid bulkActionButtons={false}>
            <SelectField source="state" label="Estado" choices={StateChoices} optionText="label" optionValue="value" />
            <ReferenceField source="vehicleBaseId" reference="vehicle-bases" label="Filial">
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = {
  list: OperationList,
  edit: OperationEdit,
  create: OperationCreate,
  show: OperationShow,
  name: 'operations',
  permissions: [PERMISSIONS.LIST_OPERATIONS]
}
export default resource;