import { ArrayField, ArrayInput, Create, Datagrid, Edit, email, Filter, ReferenceInput, required, SearchInput, SelectInput, Show, SimpleForm, SimpleFormIterator, TextField, TextInput, useListContext, useRecordContext, useResourceContext } from "react-admin";
import ComponentWithPermissions from "./ComponentWithPermissions";
import { List } from '../components';
import { getChainId, getCompanyId } from "../utils/utils";
import { Permission } from "../components";
import { PERMISSIONS } from "../constants";

const MailingListCreate = () => {

  return (
    <Create title="Adicionar Lista de Email">
      <SimpleForm sanitizeEmptyValues defaultValues={{ companyId: getCompanyId(), chainId: getChainId() }}>
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} perPage={null}>
                <SelectInput label="Grupo de Transportadora" optionText="name" sx={{ minWidth: 250 }} />
              </ReferenceInput>
            )
          })
        }
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={false}>
                <SelectInput label="Rede" optionText="name" sx={{ minWidth: 250 }} />
              </ReferenceInput>
            )
          })
        }
        <TextInput source="name" label="Nome" />
        <ArrayInput
          label="Emails"
          source="emails"
        >
          <SimpleFormIterator disableReordering fullWidth>
            <TextInput source="email" label="Email" type="email" validate={[email(), required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const MailingListEdit = () => {
  return (
    <Edit title="Editar Lista de Email">
      <SimpleForm sanitizeEmptyValues defaultValues={{ companyId: getCompanyId(), chainId: getChainId() }}>
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} perPage={null}>
                <SelectInput label="Grupo de Transportadora" optionText="name" sx={{ minWidth: 250 }} />
              </ReferenceInput>
            )
          })
        }
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            children: (
              <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={false}>
                <SelectInput label="Rede" optionText="name" sx={{ minWidth: 250 }} />
              </ReferenceInput>
            )
          })
        }
        <TextInput source="name" label="Nome" required />
        <ArrayInput
          label="Emails"
          source="emails"
        >
          <SimpleFormIterator disableReordering fullWidth>
            <TextInput source="email" label="Email" type="email" validate={[email(), required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const ListFilters = (props) => {
  const { filterValues } = useListContext()
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} perPage={null} alwaysOn>
              <SelectInput label="Grupo de Transportadora" optionText="name" sx={{ minWidth: 250 }} disabled={filterValues.chainId} />
            </ReferenceInput>
          )
        })
      }
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={false} alwaysOn>
              <SelectInput label="Rede" optionText="name" sx={{ minWidth: 250 }} disabled={filterValues.companyId} />
            </ReferenceInput>
          )
        })
      }
    </Filter>
  );
}

const MailingList = (props) => {

  return (
    <List
      {...props}
      title="Lista de Emails"
      filters={<ListFilters />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show" >
        <TextField source="name" label="Nome" />
      </Datagrid>
    </List>
  );
};

const EmailName = () => {
  const record = useRecordContext();
  return record ? <span>{record.name}</span> : <></>;
};

const MailingListShow = () => (
  <Show title={<EmailName />}>
    <ArrayField source="emails">
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="email" label="Email" sortable={false} />
      </Datagrid>
    </ArrayField>
  </Show>
);

const resource = {
  edit: MailingListEdit,
  list: MailingList,
  create: MailingListCreate,
  show: MailingListShow,
  name: 'mailing-list',
  permissions: [PERMISSIONS.MAILING_LIST]
};

export default resource;