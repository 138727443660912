import { Datagrid, DateField, DateInput, Filter, NumberField, ReferenceField, SearchInput, TextField, Button, useNotify, useRecordContext } from "react-admin";
import { List, Permissions } from "../../components";
import { saveAs } from 'file-saver';
import moment from "moment";
import { PERMISSIONS } from "../../constants";

const FilterList = (props: any) => (
  <Filter {...props}>
    <DateInput source="from" label="De" alwaysOn sx={{ marginBottom: 0.5 }} />
    <DateInput source="to" label="Até" alwaysOn sx={{ marginBottom: 0.5 }} />
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const DownloadFile = () => {
  const record = useRecordContext();
  const notify = useNotify();

  if (!record) { return null; }

  const handleDownloadXml = (url: string) => {
    try {
      saveAs(url, url.split('/').slice(-1));
    } catch (error) {
      notify(error.message || `Erro ao baixar XML da nota fiscal`, { type: 'warning' });
    }
  }

  return (
    <Button
      label="Baixar XML"
      onClick={(e) => {
        e.stopPropagation();
        handleDownloadXml(record.path);
      }}
    />
  );
};

const InvoiceList = () => {
  return (
    <Permissions permissions={[PERMISSIONS.LIST_INVOICE]}>
      <List
        title="Notas Fiscais"
        filters={<FilterList />}
        sort={{ field: "createdAt", order: "DESC" }}
        filterDefaultValues={{
          from: moment().startOf('day').subtract(1, 'month').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        exporter={false}
        perPage={25}
        actions={false}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="invoiceNumber" label="Número" />
          <NumberField source="totalInvoiceValue" locales="pt-br" label="Valor da nota" textAlign="left" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
          <ReferenceField source="billId" reference="bills" label="Fatura" link="show" emptyText="--">
            <TextField source="numericId" />
          </ReferenceField>
          <DateField source="createdAt" label="Data de criação" />
          <DownloadFile />
        </Datagrid>
      </List>
    </Permissions>
  );
};

export default InvoiceList;