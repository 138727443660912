import { Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteButton,
  FormDataConsumer,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceField,
  ReferenceInput,
  RichTextField,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  maxLength,
  minValue,
  required,
  useGetIdentity,
  useRecordContext
} from 'react-admin';
import { List, ListPagination, Permission, Permissions } from '../components';
import { PERMISSIONS } from '../constants';
import { UserRole } from '../providers/authProvider';
import { addMinutesToDate, getCompanyId } from '../utils/utils';

const DATE_FORMATER = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

// const MAX_LENGTH_SMS = 160;
const MAX_LENGTH_PUSH = 130;

const charCounter = (value: string, max: number) => {
  return (
    <span style={{
      fontFamily: 'Helvetica',
      fontSize: 12,
      opacity: .75,
      marginLeft: '80%',
      marginTop: -16,
    }}>
      {value?.length || 0}/{max}
    </span>
  );
};

const CreateNotification: React.FC = (props) => {
  const nowDatePlus10Minutes = () => addMinutesToDate(new Date(), 10);
  const { identity } = useGetIdentity();

  return (
    <Permissions userRoles={[UserRole.admin, UserRole.company]}>
      <Create {...props} title="Cadastrar nova notificação">
        <SimpleForm defaultValues={{ companyId: getCompanyId() }}>
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput source="companyId" reference="companies">
              <SelectInput optionText="name" label="Transportadora" validate={required()} />
            </ReferenceInput>
          </Permission>

          {/* <ReferenceInput source="driverCategoryId" reference="driver-categories" label="Categoria de Motorista" link={false}>
            <SelectInput optionText="name" />
        </ReferenceField> */}

          <TextInput source="title" label="Título" validate={[required(), maxLength(65)]} />
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="vehicleBaseId"
                reference="vehicle-bases"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={99999}
                filter={identity?.role === UserRole.company ? { companyId: getCompanyId() }
                  : formData.companyId ? { companyId: formData.companyId } : {}}
              >
                <SelectInput
                  emptyText="Todos"
                  label="Filial"
                  optionText="name"
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => !formData.sendNow &&
              <DateTimeInput
                source="sendAt"
                label="Data de Envio"
                validate={[required(), minValue(
                  nowDatePlus10Minutes(),
                  'A hora de envio deve ser no mínimo 10 minutos a partir de agora.',
                )]}
              />}
          </FormDataConsumer>
          <BooleanInput source="sendNow" label="Enviar agora" />
          <FormDataConsumer>
            {({ formData }) => formData.sendNow && (
              <Typography variant="subtitle1" gutterBottom>
                A notifição pode demorar até 10 minutos para ser enviada.
              </Typography>
            )}
          </FormDataConsumer>
          <Labeled label="Descrição: ">
            <RichTextInput
              source="body"
              label="Essa mensagem pode conter formatações e será exibida na tela de notificação do aplicativo. Ela NÃO será enviada na notificação PUSH e no SMS"
              validate={required()}
            />
          </Labeled>
          <Labeled label="Notificar via: ">
            <>
              {/* <BooleanInput source="sms" label="SMS" /> */}
              <BooleanInput source="pushNotification" label="Notificação do aplicativo" />
            </>
          </Labeled>
          {/* <FormDataConsumer>
            {({ formData }) => formData.sms && (
              <Labeled label="Esta mensagem será enviada via SMS: ">
                <>
                  <TextInput
                    source="smsMessage"
                    label="Mensagem SMS"
                    validate={[required(), maxLength(MAX_LENGTH_SMS)]}
                    autoComplete="off"
                  />
                  {charCounter(formData.smsMessage, MAX_LENGTH_SMS)}
                </>
              </Labeled>
            )}
          </FormDataConsumer> */}
          <FormDataConsumer>
            {({ formData }) => formData.pushNotification && (
              <Labeled label="Esta mensagem será enviada na notificação Push:">
                <>
                  <TextInput
                    source="pushMessage"
                    label="Mensagem Push"
                    validate={[required(), maxLength(MAX_LENGTH_PUSH)]}
                    autoComplete="off"
                  />
                  {charCounter(formData.pushMessage, MAX_LENGTH_PUSH)}
                </>
              </Labeled>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </Permissions>
  )
};

const DispatchCancelButton = () => {
  const record = useRecordContext();

  return record && record.status === 'scheduled' ?
    <DeleteButton label="Cancelar Envio" startIcon="" />
    : null;
};

const ExpandDispatchMessages = () => {
  const record = useRecordContext();

  return (
    <Datagrid
      data={record.dispatchMessages}
      bulkActionButtons={false}
    >
      <FunctionField
        label="Mensagem"
        textAlign='center'
        render={r => r.type === 'sms' ? record.smsMessage : record.pushMessage}
      />
      <SelectField
        label='Tipo de envio'
        textAlign='center'
        source='type'
        choices={[
          // { id: 'sms', name: 'SMS' },
          { id: 'push-notification', name: 'Notificação pelo app' }
        ]}
        sortable={false}
      />
      <SelectField
        source="status"
        textAlign='center'
        label="Status"
        choices={[
          { id: 'scheduled', name: 'Agendado' },
          { id: 'canceled', name: 'Cancelado' },
          { id: 'dispatched', name: 'Enviado' },
          { id: 'dispatched-with-error', name: 'Enviado com erro' },
        ]}
        sortable={false}
      />
      <DateField source="sendAt" label="Data para Envio" textAlign='center' options={DATE_FORMATER} sortable={false} />
      <NumberField source='cardinality' label='Cardinalidade' textAlign='center' sortable={false} />
      <DispatchCancelButton />
    </Datagrid>
  );
};

const ListNotification: React.FC = (props) => (
  <Permissions userRoles={[UserRole.admin, UserRole.company]}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      filter={{ companyId: getCompanyId() }}
      exporter={false}
      title="Notificações"
    >
      <Datagrid bulkActionButtons={false} expand={<ExpandDispatchMessages />}>
        <Permission permission={PERMISSIONS.ADMIN} label="Transportadora">
          <ReferenceField reference="companies" source="companyId">
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        <TextField source="title" label="Título" />
        <RichTextField source="body" label="Descrição" sortable={false} />
        {/* <BooleanField source="sms" label="SMS" /> */}
        <BooleanField source="pushNotification" label="Notificação no App" />
        <DateField source="createdAt" label="Data de Criação" />
        {/* <ReferenceField source="driverCategoryId" reference="driver-categories" label="Categoria de Motorista" link={false}>
          <TextField source="name" />
        </ReferenceField> */}
      </Datagrid>
    </List>
  </Permissions>
);

const resource = {
  list: ListNotification,
  create: CreateNotification,
  name: 'company/notification',
  permissions: [PERMISSIONS.LIST_NOTIFICATIONS]
};

export default resource;