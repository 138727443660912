import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { ButtonProps, FormDataConsumer, SimpleForm, Toolbar } from 'react-admin';
import WizardButton from './wizardButton';

interface formStep {
  component: React.ReactElement;
  onNextFunction?: (formData: any, nextStep: () => void) => void;
  disableToolbarButton?: boolean | null;
  disableToolbar?: boolean | null;
  loadingToolbarButton?: boolean | null;
}

interface WizardFormProps {
  formSteps: formStep[];
}

const WizardForm: React.FC<WizardFormProps> = ({ formSteps }) => {

  const [index, setIndex] = useState(0);

  const nextStep = (value?: number | null) => {
    if (value !== null && value !== undefined) { setIndex(value); }

    if ((formSteps.length - 1) > index) {
      setIndex(index => index + 1);
    }
  }

  const SimpleFormButton: React.FC<ButtonProps> = ({ ...props }) => (
    <WizardButton
      {...props}
      onClick={(values) => {
        const { onNextFunction } = formSteps[index];
        if (onNextFunction) {
          onNextFunction(values, nextStep);
        } else {
          nextStep();
        }
      }}
    />
  );

  const ToolbarCustom = () => (
    <Toolbar>
      <SimpleFormButton
        label="Próximo"
        variant="contained"
        size="medium"
        disabled={formSteps[index].disableToolbarButton || false}
        startIcon={formSteps[index].loadingToolbarButton && <CircularProgress size={20} />}
      />
    </Toolbar>
  );

  return (
    <Box style={{ marginTop: 50 }}>
      <SimpleForm
        toolbar={formSteps[index].disableToolbar !== true && <ToolbarCustom />}
        warnWhenUnsavedChanges
      >
        <FormDataConsumer>
          {({ formData }) =>
            React.cloneElement(formSteps[index].component, { nextStep, SimpleFormButton, formData })
          }
        </FormDataConsumer>
      </SimpleForm>
    </Box>
  );
}

export default WizardForm;