import { useState } from "react";
import {
  Create,
  Datagrid,
  DateField, DateInput, DeleteButton, Edit,
  EditButton, EmailField, FormTab, FunctionField, NumberField, Pagination, ReferenceManyField, Show, Tab, TabbedForm, TabbedShowLayout, TextField,
  TextInput, email, useGetIdentity, usePermissions
} from "react-admin";
import { List } from "../components";
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from "../constants";
import { UserRole } from "../providers/authProvider";
import { CEPInput, CPFInput, GenderInput, PhoneInput, StateInput } from "../utils/Inputs";
import { formatCpf } from "../utils/utils";
import { FillinDatagrid } from "./Fillin";
import { RatingDatagrid } from "./Rating";
import { SupportTicketDatagrid } from "./SupportTicket";

const TransactionType = {
  refuel: 'refuel',
  redemption: 'redemption',
  adition: 'adition',
  discount: 'discount',
  marketing: 'marketing',
}

const translateTransactionType = (type) => {
  switch (type) {
    case TransactionType.refuel:
      return 'Abastecimento';
    case TransactionType.redemption:
      return 'Resgate';
    case TransactionType.adition:
      return 'Adição';
    case TransactionType.discount:
      return 'Desconto';
    case TransactionType.marketing:
      return 'Recompensa';
    default: return "";
  }
}

const required = (message = "Required") => value =>
  value ? undefined : message;

const CustomerList = props => {
  const { identity } = useGetIdentity();
  return (
    <List {...props} title="Clientes" filters={<SearchFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <FunctionField render={record => formatCpf(record.cpf)} label="CPF" sortable={false} />
        <EmailField source="email" />
        <TextField source="balance.balance" label="Saldo" sortBy="balance" />
        <DateField
          source="lastLogin"
          label="Último login"
          locales="pt-BR"
          showTime
        />
        {identity?.role === UserRole.admin && <EditButton />}
        {identity?.role === UserRole.admin && <DeleteButton />}
      </Datagrid>
    </List >
  );
};

const ShowPassText = props => (
  <p
    onClick={() => props.onClick()}
    style={{
      margin: 0,
      padding: 0,
      color: "#303f9f",
      fontSize: 10,
      width: 85,
      cursor: "pointer"
    }}
  >
    {props.showPass ? "Esconder a senha" : "Mostrar senha"}
  </p>
);

const CustomerEdit = props => {
  const [showPass, setShowPass] = useState(false);

  return (
    <Edit title="Editar cliente" {...props}>
      <TabbedForm>
        <FormTab label="Identificação">
          <TextInput disabled label="ID" source="id" />
          <TextInput source="name" label="Nome" />
          <TextInput source="email" type="email" validate={email()} />
          <DateInput
            source="birthDate"
            type="date"
            label="Data de Nascimento"
            options={{ format: "dd/MM/YYYY" }}
          />
          <TextInput
            source="password"
            type={showPass ? "text" : "password"}
            label="Senha"
          />
          <ShowPassText
            onClick={() => setShowPass(!showPass)}
            showPass={showPass}
          />
          <CPFInput
            source="cpf"
            label="CPF"
          />
          <PhoneInput
            source="cellphone"
            label="Telefone"
            validate={required()}
          />
          <GenderInput source="gender" label="Sexo" />
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="complement" label="Complemento" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <CEPInput source="cep" label="CEP" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const CustomerCreate = props => (
  <Create title="Adicionar cliente" {...props}>
    <TabbedForm>
      <FormTab label="Identificação">
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
        />
        <DateInput
          source="birthDate"
          type="date"
          label="Data de Nascimento"
          options={{ format: "dd/MM/YYYY" }}
        />
        <CPFInput
          source="cpf"
          label="CPF"
          validate={required()}
        />
        <PhoneInput
          source="cellphone"
          label="Telefone"
          validate={required()}
        />
        <GenderInput source="gender" label="Sexo" />
        <TextInput
          source="password"
          label="Senha"
          type="password"
          defaultValue=""
          validate={required()}
        />
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" />
        <TextInput source="streetNumber" label="Número" />
        <TextInput source="complement" label="Complemento" />
        <TextInput source="neighbourhood" label="Bairro" />
        <TextInput source="city" label="Cidade" />
        <StateInput source="state" label="Estado" />
        <CEPInput source="cep" label="CEP" />
      </FormTab>
    </TabbedForm>
  </Create>
);

const CustomerName = ({ record }) => {
  return <span>{record.name}</span>;
};

const CustomerShow = props => {
  const { identity } = useGetIdentity();
  return (
    <Show title={<CustomerName />} {...props}>
      <TabbedShowLayout>
        <Tab label="Resumo">
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
          <EmailField source="email" />
          <TextField source="balance.balance" label="Saldo" />
          <DateField
            source="lastLogin"
            label="Último login"
            locales="pt-BR"
            showTime
          />
        </Tab>

        <Tab label="Veículos" path="vehicles">
          <ReferenceManyField
            reference="vehicles"
            target="customerId"
            addLabel={false}
            pagination={<Pagination />}
          />
        </Tab>

        <Tab label="Abastecimentos" path="fillins">
          <ReferenceManyField
            reference="fillins"
            target="customerId"
            addLabel={false}
            filter={
              identity?.role === UserRole.chain
                ? { chainId: localStorage.getItem("chainId") }
                : {}
            }
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <FillinDatagrid />
          </ReferenceManyField>
        </Tab>
        {identity?.role === UserRole.admin && (
          <Tab label="Chamados" path="support-tickets">
            <ReferenceManyField
              reference="support-tickets"
              target="customerId"
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
              pagination={<Pagination />}
            >
              <SupportTicketDatagrid />
            </ReferenceManyField>
          </Tab>
        )}
        <Tab label="Avaliações" path="ratings">
          <ReferenceManyField
            reference="ratings"
            addLabel={false}
            target="customerId"
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<Pagination />}
          >
            <RatingDatagrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="Extrato" path="statement">
          <ReferenceManyField
            reference="transactions"
            addLabel={false}
            target="customerId"
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={null}
            perPage={null}
          >
            <Datagrid  {...props}>
              <NumberField source="value" label="Valor" />
              <FunctionField render={record => translateTransactionType(record.type)} label='Tipo' />
              <NumberField source="balance" label="Saldo Parcial" />
              <DateField source="createdAt" label="Data" locales="pt-BR" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const resource = {
  list: CustomerList,
  edit: CustomerEdit,
  create: CustomerCreate,
  show: CustomerShow,
  name: 'customers',
  permissions: [PERMISSIONS.LIST_CUSTOMERS]
};

export default resource;