import { BooleanField, DateField, FunctionField, ReferenceField, Show, SimpleForm, SimpleShowLayout, Tab, TabbedShowLayout, TextField } from 'ra-ui-materialui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Permissions } from '../../components';
import ChatMessage from '../../components/ChatMessage';
import ChatInput from '../../components/ChatInput';
import { Avatar, Box, Card, CardContent, Grid, Typography, styled } from '@mui/material';
import { useCreate, useShowContext } from 'react-admin';
import { getChainId, getCompanyId, getUserId } from '../../utils/utils';
import { orderBy } from 'lodash';
import { grey } from '@mui/material/colors';
import { ChatOrigin, FileData } from '../../models';
import { UserRole } from '../../providers/authProvider';

const BioCard = (props) => {

  const { record } = useShowContext();

  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          {
            (getChainId() && record.company) &&
            <>
              <Avatar sx={{ height: 60, width: 60, marginBottom: 1 }} />
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>{record.company.name}</Typography>
              <Typography variant="overline" sx={{ lineHeight: 1.5 }}>Transportadora</Typography>
            </>
          }
          {
            (getCompanyId() && record.chain) &&
            <>
              <Avatar src={record.chain.logoPath || undefined} sx={{ height: 60, width: 60, marginBottom: 1 }} />
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>{record.chain.name}</Typography>
              <Typography variant="overline" sx={{ lineHeight: 1.5 }}>Rede</Typography>
            </>
          }
        </Box>

        <SimpleShowLayout>
          <TextField source="subject" label="Assunto" />
          {
            Permissions({
              userRoles: [UserRole.chain],
              children: (
                <ReferenceField source="companyId" reference="companies" label="Grupo de transportadora" textAlign="center">
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          {
            Permissions({
              userRoles: [UserRole.company],
              children: (
                <ReferenceField source="chainId" reference="chains" label="Rede" textAlign="center">
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          <FunctionField render={(record: any) => ChatOrigin.transalateType(record.origin)} label="Origem" />
          {
            record.contractId &&
            <ReferenceField source="contractId" reference="contracts" label="Contrato" textAlign="center">
              <TextField source="name" />
            </ReferenceField>
          }
          <DateField source="createdAt" label="Data de criação" textAlign="center" />
          <BooleanField source="isResolved" label="Resolvido" />
        </SimpleShowLayout>
      </CardContent>
    </Card>
  );
}

const ScrollView = styled('div')(() => ({
  overflowY: 'auto',
  padding: 5,
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: grey[50],
    borderRadius: 4,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: grey[500],
    borderRadius: 4,
  }
}));

const ChatView = () => {
  const userId = getUserId();
  const { record } = useShowContext();
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const formRef = useRef(null);
  const listRef = useRef<any>();
  const [create] = useCreate();

  useEffect(() => {
    if (record.chatMessages) {
      setChatMessages(record.chatMessages);
    }
  }, [record]);

  // useEffect(() => {
  //   const polling = setInterval(() => {
  //     refresh();
  //   }, 60000);

  //   return () => {
  //     clearInterval(polling);
  //   }
  // }, []);

  const getChatMessages = useCallback(() => {
    return orderBy(chatMessages, 'createdAt', 'asc').reduce<any>((acc, chatMessage: any) => {
      const attachedMessage = acc.pop();

      if (attachedMessage && attachedMessage.employeeId === chatMessage.employeeId) {
        attachedMessage.messages.push({ body: chatMessage.body, sendAt: chatMessage.createdAt, files: (chatMessage.chatMessageUploads || []).map(cmu => ({ filePath: cmu.filePath })) });
        return [...acc, attachedMessage];
      } else if (attachedMessage) {
        acc = [...acc, attachedMessage];
      }

      const newAttachedMessage = { messages: [{ body: chatMessage.body, sendAt: chatMessage.createdAt, files: (chatMessage.chatMessageUploads || []).map(cmu => ({ filePath: cmu.filePath })) }], side: chatMessage.employeeId === userId ? 'right' : 'left' };
      return [...acc, newAttachedMessage];
    }, []);
  }, [chatMessages, userId]);

  const createMessageApi = (message: string, files?: FileData[]) => {
    const data = {
      employeeId: userId,
      chatId: record.id,
      body: message,
      files,
    };

    create('chat-messages', { data }, {
      onSettled: (_data, error: any) => {
        if (error.status) {
          return;
        }
      }
    });
  }

  const onSubmitMessage = (value: string | undefined, files?: FileData[]) => {
    if (value) {
      setChatMessages([...chatMessages, { body: value, employeeId: userId, chatMessageUploads: files?.map(file => ({ filePath: file.url })) }]);
      createMessageApi(value, files);
    }
  }

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <div style={{ height: 600, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <ScrollView ref={listRef}>
        {
          getChatMessages().map((chatMessage: any, index: number) => (
            <ChatMessage key={index} side={chatMessage.side} messages={chatMessage.messages} />
          ))
        }
      </ScrollView>
      <SimpleForm ref={formRef} sx={{ padding: 0, marginTop: 2 }} toolbar={false} onSubmit={(props) => console.log(props)}>
        <ChatInput
          autoFocus
          source='chatMessage'
          onClickSendButton={onSubmitMessage}
        />
      </SimpleForm>
    </div>
  );
}

const ChatShow: React.FC = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Conversas">
          <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
              <ChatView />
            </Grid>
            <Grid item xs={6} md={4}>
              <BioCard />
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default ChatShow;