import { AuthProvider, Identifier, UserIdentity } from 'react-admin';
import { apiUrl } from './apiProvider';

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: params => {
    const { username, password } = params;
    const request = new Request(`${apiUrl}/employees/login`, {
      method: 'POST',
      body: JSON.stringify({ login: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((user) => {
        if (user?.mustChangePassword === true) {
          throw user;
        }
        setLoginStorage(user);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('id')
    localStorage.removeItem('fullName')
    localStorage.removeItem('role');
    localStorage.removeItem('authorization');
    localStorage.removeItem('chainId');
    localStorage.removeItem('stationId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('chainName');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyCode');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('authorization');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location
  checkAuth: () => {
    return localStorage.getItem('authorization')
      ? Promise.resolve()
      : Promise.reject();
  },

  getPermissions: () => {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    if (!permissions || permissions.length === 0) {
      return Promise.reject('logout');
    }

    return Promise.resolve(permissions);
  },

  getIdentity: () => {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('fullName');
    const code = localStorage.getItem('companyCode');
    const role = localStorage.getItem('role');
    let fullName = name;

    if (!role) { return Promise.reject(); }

    if (role === 'company') {
      fullName = `${name} - ${code}`;
    }

    return Promise.resolve(new AdminUser({ id: id || '', fullName: fullName || '', role: UserRole[role as keyof typeof UserRole] }));
  },

  updatePassword: (id, params, user) => {
    const { password } = params;
    const request = new Request(`${apiUrl}/employees/${id}/update-password`,{
      method: "PATCH",
      body: JSON.stringify({ password: password?.trim() }),
      headers: new Headers({ 'Content-type': 'application/json', 'Authorization': user.authorization })
    });

    return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(() => {
      setLoginStorage(user);
    });
  }
};

const setLoginStorage = ({ authorization, role, id, employeeName, chainId, companyId, companyName, chainName, companyCode, stationId, permissions }: any) => {
  localStorage.setItem('id', id);
  localStorage.setItem('fullName', employeeName);
  localStorage.setItem('authorization', authorization);
  localStorage.setItem('role', role);
  localStorage.setItem('permissions', JSON.stringify(permissions));
  if (companyId) {
    localStorage.setItem('companyId', companyId);
  } else {
    localStorage.removeItem('companyId');
  }
  if (chainId) {
    localStorage.setItem('chainId', chainId);
  } else {
    localStorage.removeItem('chainId');
  }
  if (companyName) {
    localStorage.setItem('companyName', companyName);
  } else {
    localStorage.removeItem('companyName');
  }
  if (chainName) {
    localStorage.setItem('chainName', chainName);
  } else {
    localStorage.removeItem('chainName');
  }
  if (companyCode) {
    localStorage.setItem('companyCode', companyCode)
  } else {
    localStorage.removeItem('companyCode');
  }
  if (stationId) {
    localStorage.setItem('stationId', stationId)
  } else {
    localStorage.removeItem('stationId');
  }
}

export class AdminUser implements UserIdentity {
  id: Identifier = '';
  fullName?: string;
  avatar?: string;
  role?: UserRole;
  [key: string]: any;

  constructor(init?: Partial<AdminUser>) {
    Object.assign(this, init);
  }
}

export enum UserRole {
  admin = 'admin',
  chain = 'chain',
  company = 'company',
  station = 'station',
}

export default authProvider;