import React from "react";
import {
  TabbedForm,
  Create,
  Datagrid,
  Edit,
  TextField,
  TextInput,
  FormTab,
  Filter,
  ReferenceInput,
  SelectInput,
  NumberInput,
  usePermissions
} from "react-admin";
import { List } from "../components";
import { CEPInput, CNPJInput, PhoneInput, StateInput } from "../utils/Inputs";
import { PERMISSIONS } from "../constants";
import { getChainId } from "../utils/utils";

const PartnerFilter = props => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <TextInput label="Pesquisar" source="search" alwaysOn />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput source="chainId" reference="chains" alwaysOn>
          <SelectInput label="Rede" resettable={true} optionText="name" />
        </ReferenceInput>}
    </Filter>
  );
};

const required = (message = "Required") => (value) =>
  value ? undefined : message;

export const PartnerList = props => (
  <List
    {...props}
    exporter={false}
    title="Parceiros"
    filter={getChainId() ? { chainId: getChainId() } : null}
    filters={<PartnerFilter />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nome" />
      <TextField source="cnpj" label="CNPJ" />
      <TextField source="phone" label="Telefone" />
      <TextField source="contact" label="Contato" />
    </Datagrid>
  </List>
);

const PartnerEdit = props => {
  const { permissions } = usePermissions();
  return (
    <Edit title="Editar parceiro" {...props}>
      <TabbedForm defaultValue={permissions === 'manager' && { chainId: getChainId() }}>
        <FormTab label="Identificação">
          <TextInput source="name" label="Nome" />
          <CEPInput source="cep" label="CEP" />
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <PhoneInput source="phone" label="Telefone" />
          <CNPJInput source="cnpj" label="CNPJ" />
          <TextInput source="contact" label="Contato" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceInput source='chainId' reference="chains" validate={required()}>
              <SelectInput label="Rede" optionText="name" />
            </ReferenceInput>
          }
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const PartnerCreate = props => {
  const { permissions } = usePermissions();
  return (
    <Create title="Novo Parceiro" {...props}>
      <TabbedForm defaultValue={permissions === 'manager' && { chainId: getChainId() }}>
        <FormTab label="Identificação">
          <TextInput source="name" label="Nome" validate={required()} />
          <CEPInput source="cep" label="CEP" isRequired />
          <TextInput source="street" label="Rua" validate={required()} />
          <TextInput
            source="streetNumber"
            label="Número"
            validate={required()}
          />
          <TextInput
            source="neighbourhood"
            label="Bairro"
            validate={required()}
          />
          <TextInput source="city" label="Cidade" validate={required()} />
          <StateInput source="state" label="Estado" validate={required()} />
          <PhoneInput source="phone" label="Telefone" />
          <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
          <TextInput source="contact" label="Contato" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceInput source='chainId' reference="chains" validate={required()}>
              <SelectInput label="Rede" optionText="name" />
            </ReferenceInput>
          }
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const resource = {
  list: PartnerList,
  edit: PartnerEdit,
  create: PartnerCreate,
  name: 'partners',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;