

import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps } from 'react-admin';

const ColoredDiferenceValueField: React.FC<NumberFieldProps> = (props) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }
    
    return record[props.source] ? (
        <NumberField {...props} style={{ color: record[props.source] < 0 ? 'red' : 'green' }} />
    ) : (
        <NumberField {...props} />
    );
};

export default ColoredDiferenceValueField;