import { FillinProcess } from './containers/FillinProcess';
import InvoiceUpload from './containers/Invoice/InvoiceUpload';
import {
    AveragesReport,
    BlockedDriversReport,
    DetailedFillinsReport,
    DetailedPriceReport,
    DriverAveragesReport,
    FillinReport,
    FinancialReport,
    BillsReport,
    PriceChangesReport,
    PricesReport,
    TransactionsReport,
    VehicleAveragesReport,
    BestPricesReport,
} from "./containers/reports";

const customRoutes = [
    { path: '/transaction-report', element: <TransactionsReport /> },
    { path: '/average-report', element: <AveragesReport /> },
    { path: '/driver-average-report', element: <DriverAveragesReport /> },
    { path: '/vehicle-average-report', element: <VehicleAveragesReport /> },
    { path: '/fillin-report', element: <FillinReport /> },
    { path: '/detailed-fillins-report', element: <DetailedFillinsReport /> },
    { path: '/detailed-price-report', element: <DetailedPriceReport /> },
    { path: '/prices-report', element: <PricesReport /> },
    { path: '/financial-report', element: <FinancialReport /> },
    { path: '/price-changes-report', element: <PriceChangesReport /> },
    { path: '/bill-report', element: <BillsReport /> },
    { path: '/blocked-drivers-report', element: <BlockedDriversReport /> },
    { path: '/best-prices-report', element: <BestPricesReport /> },
    { path: '/fillin-process', element: <FillinProcess /> },
    { path: '/import-invoice', element: <InvoiceUpload /> },
];

export default customRoutes;
