import { DealValueType } from "./dealValueType";

export class ContractFuel {

  id?: string | undefined;
  contractId?: string | undefined;
  fuelId: string;
  amount?: number | undefined;
  dealType?: string | null | undefined;
  dealValueType: DealValueType | undefined ;
  dealValue: number;
  dealMinValue: number | undefined;

  constructor(props: ContractFuel) {
    this.id = props.id;
    this.contractId = props.contractId;
    this.fuelId = props.fuelId;
    this.amount = props.amount;
    this.dealValueType = props.dealValueType;
    this.dealValue = props.dealValue;
    this.dealMinValue = props.dealMinValue;

    this.dealType = props.dealValueType === DealValueType.prefixed ? null : props.dealType;
  }
}