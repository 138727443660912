import { ThumbDownAlt } from '@mui/icons-material';
import React, { useState } from 'react';
import { Button, Confirm, useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { ContractType } from '../../models';

const ReproveContractButton: React.FC = (props) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleAuthorizeContract = () => {
    setIsLoading(true);
    dataProvider.update('contracts/authorize', {
      id: record.id,
      data: { status: ContractType.notApprove },
      previousData: record,
    })
      .then(() => {
        notify(`Contrado foi marcado como não aceito`);
        refresh();
      })
      .catch((e) => {
        notify(e.message || 'Não foi possível reprovar contrato', { type: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Confirm
        isOpen={showDialog}
        title={`Reprovar contrato`}
        content={`Você deseja reprovar o contrato?`}
        onConfirm={handleAuthorizeContract}
        onClose={closeDialog}
      />
      <Button
        {...props}
        disabled={isLoading}
        label="Reprovar contrato"
        onClick={handleClick}
        startIcon={<ThumbDownAlt />}
        sx={{ color: 'red', marginLeft: 2 }}
      />
    </>
  );
}

export default ReproveContractButton;