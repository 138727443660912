import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { List } from '../components';
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from '../constants';
import { PhoneInput } from '../utils/Inputs';
import { formatCellphone } from '../utils/utils';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const ChainList = (props) => (
  <List {...props} title="Rede de postos"
    filters={<SearchFilter />}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="Rede" />
      <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
      <TextField source="contactName" label="Nome do contato" />
      <DateField source="createdAt" label="Criado em" locales="pt-BR" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const ChainEdit = (props) => (
  <Edit title='Editar rede' {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="ID" />
      <TextInput source="name" label="Nome da rede" validate={required()} />
      <TextInput source="contactName" label="Nome do Contato" />
      <PhoneInput source="phone" label="Telefone" />
      <ImageInput source="logofile" label="Logo" accept="image/*">
        <ImageField source="src" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

const ChainCreate = (props) => (
  <Create title='Nova Rede' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome da rede" validate={required()} />
      <TextInput source="contactName" label="Nome do Contato" validate={required()} />
      <PhoneInput source="phone" label="Telefone" />
      <ImageInput source="logofile" label="Logo" accept="image/*">
        <ImageField source="src" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

const resource = {
  list: ChainList,
  edit: ChainEdit,
  create: ChainCreate,
  name: 'chains',
  permissions: [PERMISSIONS.ADMIN]
}
export default resource;