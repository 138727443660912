export enum DealValueType {
  cents = 'cents',
  percentage = 'percentage',
  prefixed = 'prefixed',
}

export namespace DealValueType {
  export const translateStatus = (type: DealValueType): string => {
    switch (type) {
      case DealValueType.cents:
        return "Centavos"
      case DealValueType.percentage:
        return "Porcentagem";
      case DealValueType.prefixed:
        return "Prefixado"
      default: return "";
    };
  };

  export const dealValueTypeChoices: Array<{ id: string, name: string }> = Object.values(DealValueType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: DealValueType.translateStatus(type as DealValueType),
    } as { id: string, name: string }));
};