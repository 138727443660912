import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SimpleForm, Toolbar, Button } from "react-admin";
import { OtpCodeInput } from "../../utils/Inputs";

const DialogOptCode = ({ open, handleClose, handleOtpCode }) => {

  const ToolBarCustom = () => {
    return (
      <Toolbar style={{ justifyContent: 'space-between', width: 400 }}>
        <Button label="Cancelar" variant="contained" size="medium" onClick={handleClose} />
        <Button label="Confirmar" type="submit" variant="contained" size="medium" />
      </Toolbar>
    );

  };
  return (
    <Dialog
      open={open}
    >
      <DialogTitle>Código OTP</DialogTitle>
      <DialogContent >
        <SimpleForm
          toolbar={<ToolBarCustom />}
          onSubmit={handleOtpCode}
        >
          <OtpCodeInput source="code" label='Digite o código OTP' style={{ width: 400 }} isRequired />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default DialogOptCode;