import { PERMISSIONS } from '../../constants'
import show from './chatShow';
import list from './chatList';
import create from './chatCreate';

export default {
  create,
  list,
  show,
  name: 'chats',
  permissions: [PERMISSIONS.LIST_CHATS],
}