import { cloneElement } from 'react';
import { useGetIdentity, usePermissions } from 'react-admin';

const Permissions = ({ permissions = [], userRoles = [], children, ...props }) => {
  const { permissions: userPermissions } = usePermissions();
  const { identity } = useGetIdentity();
  let granted = true;

  if (permissions && permissions.length && userPermissions && !permissions.some(r => userPermissions.includes(r))) {
    granted = false;
  }

  if (identity && userRoles.length && !userRoles.includes(identity.role)) {
    granted = false;
  }

  return granted ? cloneElement(children, props) : null;
}

export default Permissions;