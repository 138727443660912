import { Box, Grid, Typography } from '@mui/material';
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';
import { List } from '../components';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const FilterCompanyEmployee = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      <SelectInput
        label="Status"
        source="isInactive"
        emptyText="Todos"
        choices={[{ id: true, name: "Inativos" }, { id: false, name: "Ativos" }]}
        alwaysOn
      />
    </Filter>
  )
}

const CompanyEmployeeList = (props) => (
  <List
    {...props}
    title="Usuários da Transportadora"
    filters={<FilterCompanyEmployee />}
    filter={getCompanyId() ? { companyId: getCompanyId() } : null}
    filterDefaultValues={{ isInactive: false }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="login" />
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceField label="Grupo de Transportadora" source="companyId" reference="companies" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <DateField source="lastLogin" label="Último login" locales="pt-BR" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const CompanyEmployeeEdit = (props) => {
  return (
    <Edit title='Editar usuário da transportadora' {...props}>
      <CompanyEmployeeForm editing={true} />
    </Edit>
  );
};

const CompanyEmployeeCreate = (props) => {
  return (
    <Create title='Adicionar usuário da transportadora' {...props}>
      <CompanyEmployeeForm />
    </Create>
  );
}

const CompanyEmployeeForm = ({ editing }) =>
  <SimpleForm defaultValues={{ companyId: getCompanyId() }}>
    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
      <Grid item xs={12} md={8}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput label="Grupo de Transportadora" source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput sx={{ width: '100%' }} optionText="name" label="Grupo de Transportadora" validate={required()} isRequired />
            </ReferenceInput>
          </Permission>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0 }}>
            <TextInput source="name" label="Nome" validate={required()} isRequired fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="login" validate={required()} isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="password" label="Senha" type="password" defaultValue="" validate={editing ? null : required()} isRequired={!editing} fullWidth />
          </Box>
        </Box>
        <Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Perfil
        </Typography>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (<ReferenceInput
              source="profileId"
              reference="profiles"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={null}
              filter={{ companyId: getCompanyId() || formData.companyId }}
              validate={required()}
              isRequired >
              <RadioButtonGroupInput validate={required()} isRequired label="" row={false} optionText="name" />
            </ReferenceInput>)
          }}
        </FormDataConsumer>
        <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        {
          editing &&
          <BooleanInput source="isInactive" label="Inativar usuário" />
        }
      </Grid>
    </Grid>
  </SimpleForm>;

const resource = {
  list: CompanyEmployeeList,
  edit: CompanyEmployeeEdit,
  create: CompanyEmployeeCreate,
  name: 'company-employees',
  permissions: [PERMISSIONS.LIST_USERS]
};

export default resource;