import RemoveIcon from "@mui/icons-material/Delete";
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import { Alert, Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, styled, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import { ArrayField, Button, Datagrid, DateField, FormDataConsumer, FunctionField, Labeled, NumberField, ReferenceField, SimpleForm, SimpleShowLayout, TextField, Toolbar, UseInputValue, required, useCreate, useDataProvider, useGetIdentity, useInput, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { formatCnpj, formatPrice, getStationId, readXmlFile } from '../../utils/utils';
import UploadFileButton from './UploadFileButton';
import { UserRole } from "../../providers/authProvider";
import CnpjField from "../CnpjField";

interface Invoice {
  invoiceFile: Blob | { rawFile: Blob };
  fileName: string;
  invoiceNumber: string;
  serie: string;
  issueDate: string;
  accessKey: string;
  cnpj: string;
  emitCnpj: string;
  invoiceValue: number;
  totalInvoiceValue: number;
  fillinIds: string[];
  xmlData: any;
  invoiceProducts?: any[];
}

type InvoiceFillin = {
  fillinIds: string[];
  invoiceNumber: string;
}

const useStyles = makeStyles({
  fileNameColumn: {
    width: '200px',
  },
  iconButton: {
    width: '60px',
  }
});

const AccessKeyField: React.FC<{ source: string, label: string, emptyText: string }> = (props) => {
  const record = useRecordContext();
  return (
    <Tooltip title={record.accessKey || ''} placement="bottom">
      <Typography variant="body2">{(record[props.source] || '').slice(0, 15).concat('...')}</Typography>
    </Tooltip>
  );
}

const LinkedFillinField: React.FC<{ invoiceFillins: Array<InvoiceFillin> }> = ({ invoiceFillins }) => {
  const record = useRecordContext();
  const invoiceFillin = invoiceFillins.find(invoice => invoice.invoiceNumber === record.invoiceNumber);
  return (
    <FunctionField render={() => `${invoiceFillin ? invoiceFillin.fillinIds?.length : 0}/${record.invoiceProducts.length || 1}`} />
  );
}

const UploadInvoiceXml = ({ selectedIds, selectedFillins, setSelectedIds, currentSelectedId, setCurrentSelectedId, xmlLoaded, setXmlLoaded, ...props }: any) => {
  const [invoiceFillins, setInvoiceFillins] = useState<InvoiceFillin[]>([]);
  const [currentInvoice, setCurrentInvoice] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const classes = useStyles();
  const [validateErrors, setValidateErrors] = useState<any>(null);
  const [validateWarnings, setValidateWarnings] = useState<any>(null);

  const resetInitialValues = (hardReset?: boolean) => {
    setCurrentInvoice(null);
    setValidateErrors(null);
    setValidateWarnings(null);
    props.resetInitialValues(hardReset);
  }

  const uploadFile = async (props: any, clear: () => void) => {

    const { ...invoicePayload } = props as { invoices: Array<Invoice>, invoiceFiles: Array<{ rawFile: Blob }> };

    const invoiceWithFillin = invoicePayload.invoices.map((invoice: Invoice) => ({ ...invoice, fillinIds: invoiceFillins.find(invoiceF => invoiceF.invoiceNumber === invoice.invoiceNumber)?.fillinIds }))

    if (invoiceWithFillin.some(invoice => invoice.fillinIds?.length !== invoice.invoiceProducts?.length)) {
      notify('Há uma ou mais notas fiscais sem abastecimentos associados.', { type: 'warning' });
      return null;
    }

    const invoiceFiles = invoicePayload.invoices.map(invoice => invoicePayload.invoiceFiles.find(invoiceFile => invoiceFile.rawFile === invoice.invoiceFile));

    const invoices = invoiceWithFillin.map(invoice => ({
      fillins: (invoice.invoiceProducts || []).map((invoiceProduct: any, index) => ({
        fillinId: invoice.fillinIds?.[index],
        amount: invoiceProduct.amount,
        value: invoiceProduct.value,
        ...(invoiceProduct.fuelANP ?
          { fuelANP: invoiceProduct.fuelANP } :
          { fuelId: invoiceProduct.fuel?.id }
        ),
      })),
      invoiceCnpj: invoice.cnpj,
      emitCnpj: invoice.emitCnpj,
      invoiceNumber: invoice.invoiceNumber,
      serie: invoice.serie,
      issueDate: invoice.issueDate,
      accessKey: invoice.accessKey,
    }));

    create('invoices', { data: { invoiceFiles, invoices } }, {
      onSuccess: (data) => {
        notify('Upload feito com sucesso');

        resetInitialValues(true);
        setInvoiceFillins([]);
        clear();
        refresh();
      },
      onError: (error: any) => {
        if (error.status) {
          notify(error.message || 'Não foi possível realizar o upload', { type: 'warning' });
          return;
        }
      },
    });
  }

  const UploadInvoiceToolbar = ({ handleSubmit, ...props }: { handleSubmit: (value: object, clear: () => void) => void }) => {
    const { getValues, resetField, getFieldState, ...form } = useFormContext();

    const clear = () => {
      resetField("invoiceFiles");
      resetField("invoiceFilePdf");
      resetField("billNumber");
      resetField("billValue");
      resetField("billExpiration");
      resetField("invoiceNumber");
      resetField("destCNPJ");
      resetField("serie");
      resetField("issueDate");
      resetField("accessKey");
      resetField("totalInvoiceValue");
      resetField("subject");
      resetField("body");
      resetField("chatFiles");
      resetField("vehicleBaseName");
      resetField("invoices");
    }

    return (
      <Toolbar {...props} >
        <Button
          startIcon={isLoading ? <CircularProgress size={20} /> : <UploadIcon style={{ fontSize: 20 }} />}
          disabled={isLoading}
          label="Enviar Nota"
          onClick={form.handleSubmit(() => handleSubmit(getValues(), clear))}
          variant="contained"
          size="medium"
        />
      </Toolbar>
    );
  };

  const getVehicleBaseByCnpjApi = async (cnpj: string, vehicleBaseNameInput: UseInputValue) => {
    // @ts-ignore
    const { data } = await dataProvider.getList('vehicle-bases', { filter: { cnpj } });
    if (data && data.length) {
      return data[0];
    } else {
      return null;
    }
  }

  const getFuelApi = async (filter: any) => {
    // @ts-ignore
    const { data } = await dataProvider.getList('fuels', { filter });
    if (data && data.length) {
      return data[0];
    } else {
      return null;
    }
  }

  const handleFileChange = async (files: Array<Blob | { rawFile: Blob }>, invoicesInput: UseInputValue, invoiceFiles: UseInputValue, destCNPJInput: UseInputValue, totalInvoiceValueInput: UseInputValue, vehicleBaseNameInput: UseInputValue) => {
    if (!Array.isArray(files)) { throw null; }

    try {

      let totalInvoiceValue = 0;
      let invoices: Array<Invoice> = [];
      const pendingInvoices = (invoicesInput.field.value || []).filter(({ invoiceNumber }: { invoiceNumber: string }) => !invoiceFillins.find((invoiceF: { invoiceNumber: string }) => invoiceF.invoiceNumber === invoiceNumber));

      if (files.length > (invoicesInput.field.value || []).length && pendingInvoices.length > 0) {
        invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
        notify('Nota fiscal sem abastecimento vinculado, por favor, selecione os abastecimentos listados abaixo.', { type: 'warning', autoHideDuration: 10000 });
        return;
      }

      if ((files.length - invoicesInput.field.value.length) > 1) {
        files = files.slice(0, invoicesInput.field.value.length + 1);
        invoiceFiles.field.onChange(files);

        notify('Limite-se a um arquivo por vez.', { type: 'warning', autoHideDuration: 10000 });
      }

      if (!files || !files.length) {
        resetInitialValues(true);
        setInvoiceFillins([]);
        invoicesInput.field.onChange([]); // reset
        return;
      }

      let error = false;
      let xmlData: { [k: string]: any } = xmlLoaded;

      setXmlLoaded(null);

      let indexFile = 0;

      for (let file of files) {
        indexFile++;
        if (!(file instanceof Blob) && file.rawFile) { file = file.rawFile; }

        const xml = await readXmlFile(file as Blob);
        totalInvoiceValue += parseFloat(xml.getElementsByTagName('vNF')[0].textContent);
        const invoiceSerie = xml.getElementsByTagName('serie')[0];
        const invoiceIssueDate = xml.getElementsByTagName('dhEmi')[0];
        const invoiceAccessKey = xml.getElementsByTagName('chNFe')[0];
        const invoiceNumber = xml.getElementsByTagName('nNF')[0];
        const invoiceCNPJ = xml.getElementsByTagName('dest')[0].getElementsByTagName('CNPJ')[0];
        const emitCNPJ = xml.getElementsByTagName('emit')[0].getElementsByTagName('CNPJ')[0];
        const products = xml.getElementsByTagName('det');
        const productTag = xml.getElementsByTagName('det')[0].getElementsByTagName('prod')[0];
        const invoiceAmount = productTag.getElementsByTagName('qCom')[0];
        const invoiceValue = parseFloat(productTag.getElementsByTagName('vProd')[0].textContent);

        const invoiceProductPromises = [];

        for (const productTag of products) {
          invoiceProductPromises.push(async () => {
            const anpCode = productTag.getElementsByTagName('cProdANP')[0]?.textContent || null;

            const fuel = await getFuelApi(anpCode ? { anpCode: anpCode } : { fuelType: 16 });

            return {
              amount: parseFloat(productTag.getElementsByTagName('qCom')[0]?.textContent || 0),
              value: parseFloat(productTag.getElementsByTagName('vProd')[0]?.textContent || 0),
              fuelANP: anpCode,
              fuel,
            };
          });
        }

        const invoiceProducts = await Promise.all(invoiceProductPromises.map(f => f()));

        const xmlLoaded: any = {};
        const invoiceNumbers = (invoicesInput.field.value || []).map((item: any) => item.invoiceNumber);
        const invoiceNumberFormated = ("000000000" + invoiceNumber.textContent).slice(-9);

        if (
          invoices &&
          invoices.find((invoice: any) => invoice.invoiceNumber === invoiceNumberFormated) &&
          file === files.slice(-1)[0]
        ) {
          notify('A nota fiscal já foi carregada.', { type: 'warning' });
          invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
          error = true;
          return file;
        }

        const invoiceFillin = (invoicesInput.field.value || []).find((invoice: any) => invoice.invoiceNumber === invoiceNumberFormated);
        if (indexFile === files.length && invoiceFillin) {
          xmlData = invoiceFillin.xmlData;
        }

        const checkInvoiceImported = (invoicesInput.field.value || []).find((invoice: any) => invoice.invoiceNumber === invoiceNumberFormated);
        if (checkInvoiceImported) {
          invoices.push(checkInvoiceImported);
          continue;
        }

        if (invoiceAmount && invoiceAmount.textContent) {
          xmlLoaded.amount = parseFloat(invoiceAmount.textContent || 0);
        }

        if (invoiceValue && invoiceValue) {
          xmlLoaded.value = invoiceValue;
        }

        if (invoiceIssueDate && invoiceIssueDate.textContent) {
          xmlLoaded.issueDate = invoiceIssueDate.textContent;
        }

        const fetchApiPromises = [];

        if (xml && invoiceCNPJ.textContent) {
          xmlLoaded.destCnpj = invoiceCNPJ.textContent;
          fetchApiPromises.push((async () => {
            xmlLoaded.vehicleBase = await getVehicleBaseByCnpjApi(invoiceCNPJ.textContent, vehicleBaseNameInput);
          })());
        }

        xmlLoaded.fuelANP = invoiceProducts[0]?.fuelANP || null;
        xmlLoaded.fuel = invoiceProducts[0]?.fuel;
        xmlLoaded.products = invoiceProducts;

        await Promise.all(fetchApiPromises);

        if (xml && emitCNPJ.textContent) {
          // @ts-ignore
          const stations = await dataProvider.getList('stations', {
            filter: { cnpj: emitCNPJ.textContent },
          });

          if (stations.data.length === 0) {
            notify('Posto não consta em nosso banco de dados.', { type: 'warning' });
            invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
            error = true;
            return file;
          }

          if (identity?.role === UserRole.station && stations.data[0].id !== getStationId()) {
            notify('Posto diverge do cadastrado.', { type: 'warning' });
            invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
            error = true;
            return file;
          }

          xmlLoaded.station = stations.data[0];
        }

        if (!invoiceNumbers.find((number: string) => number === invoiceNumberFormated)) {
          // @ts-ignore
          const invoicesResponse = await dataProvider.getList('invoices', {
            filter: { accessKey: invoiceAccessKey.textContent },
          });
          if (invoicesResponse.data.length > 0) {
            notify('Esta nota fiscal já foi processada e consta em nosso banco de dados.', { type: 'warning' });
            invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
            error = true;
            return file;
          }
        }

        xmlData = { ...xml, ...xmlLoaded };

        invoices.push({
          invoiceFile: file,
          fileName: (file as File).name || 'Não identificado',
          invoiceNumber: invoiceNumberFormated,
          serie: invoiceSerie.textContent,
          issueDate: invoiceIssueDate.textContent,
          accessKey: invoiceAccessKey.textContent,
          cnpj: invoiceCNPJ.textContent,
          emitCnpj: emitCNPJ.textContent,
          totalInvoiceValue: invoiceValue,
          fillinIds: [],
          invoiceValue,
          xmlData,
          invoiceProducts,
        });
      };

      if (!error) {
        resetInitialValues();
      }

      const currentInvoice = invoices[invoices.length - 1];
      if (!currentInvoice) {
        resetInitialValues(true);
      }

      setXmlLoaded((val: object) => ({ ...val, ...xmlData }));

      invoicesInput.field.onChange(invoices);

      // removes all fillins linked to the removed invoice
      //@ts-ignore
      setInvoiceFillins((invoiceFillins: Array<{ invoiceNumber: string }>) =>
        invoiceFillins.filter((invoiceFillin: { invoiceNumber: string }) => invoices.find(invoice => invoice && invoice.invoiceNumber === invoiceFillin.invoiceNumber))
      );

    } catch (error) {
      notify('Falha ao carregar o arquivo XML', { type: 'error' });
      invoiceFiles.field.onChange(invoiceFiles.field.value);
    }
  }

  const invoiceValidation = (invoiceData: any, fillin: any) => {
    const errors: any = {};
    const warnings: any = {};

    if (!invoiceData) { return { errors: null, warnings: null }; }

    const invoiceFillin = invoiceFillins.find(invocieFillin => invocieFillin.invoiceNumber === invoiceData.invoiceNumber);
    const productIndex = invoiceFillin?.fillinIds ? invoiceFillin.fillinIds.length : 0;

    if (invoiceData.cnpj !== fillin.invoiceCnpj) {
      warnings.cnpj = true;
    }

    if (invoiceData.invoiceProducts[productIndex]?.fuel && invoiceData.invoiceProducts[productIndex].fuel.id !== fillin.fuelId) {
      errors.fuel = true;
      errors.fuelMessage = invoiceData.invoiceProducts[productIndex].fuel.name;
    }

    if (invoiceData.invoiceProducts[productIndex] && Math.abs(invoiceData.invoiceProducts[productIndex].value - fillin.value) > 20) {
      errors.value = true;
      errors.valueMessage = invoiceData.invoiceProducts[productIndex].value;
    }

    if (invoiceData.invoiceProducts[productIndex] && Math.abs(invoiceData.invoiceProducts[productIndex].amount - fillin.amount) > 0.01) {
      errors.amount = true;
      errors.amountMessage = invoiceData.invoiceProducts[productIndex].amount;
    }

    return { errors: Object.keys(errors).length ? errors : null, warnings: Object.keys(warnings).length ? warnings : null };
  }

  const handleConfirmInvoice = (invoice: any) => {
    const currentFillin = selectedFillins.find((fillin: any) => fillin.id === currentSelectedId);

    if (!currentSelectedId || !currentFillin) {
      notify('Nenhum abastecimento selecionado', { type: 'warning' });
      return;
    }

    if (!openDialog) {
      const { errors, warnings } = invoiceValidation(invoice, currentFillin);

      if (errors || warnings) {
        setValidateErrors(errors);
        setValidateWarnings(warnings);
        setOpenDialog(true);
        return;
      }
    }

    let productIndex = 0;

    const invoiceFillin = invoiceFillins.find((invoiceFillin: InvoiceFillin) => invoiceFillin.invoiceNumber === invoice.invoiceNumber);
    if (invoiceFillin) {
      invoiceFillin.fillinIds.push(currentSelectedId);
      productIndex = invoiceFillin.fillinIds.length;
      setInvoiceFillins(invoiceFillins);
    } else {
      productIndex = 1;
      setInvoiceFillins((value: any) => ([...value, { invoiceNumber: invoice.invoiceNumber, fillinId: currentSelectedId, fillinIds: [currentSelectedId] }]));
    }

    if (invoice.invoiceProducts && invoice.invoiceProducts[productIndex]) {
      setXmlLoaded((value: any) => ({
        ...invoice.xmlData,
        amount: invoice.invoiceProducts[productIndex].amount,
        value: invoice.invoiceProducts[productIndex].value,
        fuel: invoice.invoiceProducts[productIndex].fuel,
      }));
    }

    setSelectedIds((ids: string[]) => ([...ids, currentSelectedId]));
    setCurrentSelectedId(null);
    setOpenDialog(false);
  }

  const DialogConfirm = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    const closeDialog = () => {
      setOpenDialog(false);
    }

    const currentFillin = selectedFillins.find((fillin: any) => fillin.id === currentSelectedId);

    return (
      <Fragment>
        <ReadyButton headerClassName={classes.iconButton} />
        <Dialog
          fullWidth
          open={openDialog}
        >
          <DialogTitle textAlign="center">Divergência</DialogTitle>
          <DialogContent>
            <Stack gap={1}>
              <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
                <Legend>Detalhes do abastecimento</Legend>
                <SimpleShowLayout record={currentFillin}>
                  <Grid container>
                    <Grid item xs={6}>
                      <SimpleShowLayout sx={{ padding: 0 }}>
                        <ReferenceField source="fuelId" reference="fuels" label="Combustível">
                          <TextField source="name" />
                        </ReferenceField>
                        <CnpjField source="invoiceCnpj" label="CNPJ da nota" isHidden={false} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={6}>
                      <SimpleShowLayout sx={{ padding: 0 }}>
                        <NumberField
                          source="value"
                          label="Valor total"
                          options={{ style: 'currency', currency: 'BRL' }}
                        />
                        <NumberField
                          source="amount"
                          label="Litros"
                          options={{ style: 'unit', unit: 'liter' }}
                        />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>
                </SimpleShowLayout>
              </Box>
              {
                (validateWarnings && validateWarnings.cnpj && record) &&
                <Alert variant="outlined" severity="warning">
                  O CNPJ da nota fiscal está diferente do CNPJ que a transportadora esperava {formatCnpj(record.cnpj)}
                </Alert>
              }

              {
                (validateErrors && validateErrors.fuel && validateErrors.fuelMessage) &&
                <Alert variant="outlined" severity="error">
                  Combustível do abastecimento é diferente da nota: {validateErrors.fuelMessage}
                </Alert>
              }

              {
                (validateErrors && validateErrors.amount && record.xmlData) &&
                <Alert variant="outlined" severity="error">
                  Quantidade em litros diferente do abastecimento: {(new Intl.NumberFormat('pt-BR', { style: 'unit', unit: 'liter' })).format(validateErrors.amountMessage)}
                </Alert>
              }

              {
                (validateErrors && validateErrors.value && record.xmlData) &&
                <Alert variant="outlined" severity="error">
                  Valor total diferente do abastecimento: {formatPrice(validateErrors.valueMessage)}
                </Alert>
              }
            </Stack>
            <DialogActions sx={{ marginTop: 4, padding: 0 }}>
              <Button variant="outlined" onClick={closeDialog} label="ra.action.cancel" />
              <Button variant="contained" onClick={() => handleConfirmInvoice(record)} label="ra.action.confirm" disabled={validateErrors && validateErrors.fuel} />
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  const ReadyButton: React.FC<any> = () => {
    const record = useRecordContext();
    const checked = !!(invoiceFillins || []).find((invoice: any) => invoice.invoiceNumber === record.invoiceNumber && invoice.fillinIds.length === record.invoiceProducts?.length);

    return (
      <Button
        sx={{ minWidth: 'auto' }}
        variant="outlined"
        onClick={() => handleConfirmInvoice(record)}
        startIcon={<CheckIcon style={{ marginLeft: 10 }} />}
        disabled={checked}
      />
    );
  }

  const RemoveButton: React.FC<any> = () => {
    const record = useRecordContext();
    const invoiceFiles = useInput({ source: 'invoiceFiles' });
    const invoices = useInput({ source: 'invoices' });
    const invoicesInput = useInput({ source: 'invoices' });
    const destCNPJInput = useInput({ source: 'destCNPJ' });
    const totalInvoiceValueInput = useInput({ source: 'totalInvoiceValue' });
    const vehicleBaseNameInput = useInput({ source: 'vehicleBaseName' });

    const handleRemove = (record: any) => {
      const indexRemoved = invoices.field.value?.findIndex((invoice: any) => invoice.invoiceNumber === record.invoiceNumber);
      const invoiceFillin = invoiceFillins.find(invoiceFillin => invoiceFillin.invoiceNumber === record.invoiceNumber);

      if (invoiceFillin) {
        setSelectedIds((ids: string[]) => ids.filter(id => !invoiceFillin.fillinIds.includes(id)));
      }

      const files = invoiceFiles.field.value.filter((item: any, index: number) => index !== indexRemoved);
      invoiceFiles.field.onChange(invoiceFiles.field.value.filter((item: any, index: number) => index !== indexRemoved));

      handleFileChange(files, invoicesInput, invoiceFiles, destCNPJInput, totalInvoiceValueInput, vehicleBaseNameInput);
    }

    return (
      <Button
        sx={{ minWidth: 'auto' }}
        variant="outlined"
        onClick={() => handleRemove(record)}
        startIcon={<RemoveIcon style={{ marginLeft: 10 }} />}
        color="warning"
      />
    );
  }

  const invoiceStyle = (record: any) => {
    const isCompleted = (invoiceFillins || []).find((invoice: any) => invoice.invoiceNumber === record.invoiceNumber && invoice.fillinIds.length === record.invoiceProducts.length);
    const inProgress = (invoiceFillins || []).find((invoice: any) => invoice.invoiceNumber === record.invoiceNumber && invoice.fillinIds.length > 0);
    return {
      backgroundColor: isCompleted ? '#caffdb' : inProgress ? '#FFF59D' : '#FFEBE6',
    };
  };

  return (
    <Card sx={{ marginTop: 2 }}>
      <Box>
        <SimpleForm toolbar={<UploadInvoiceToolbar handleSubmit={uploadFile} />} sanitizeEmptyValues>
          <Grid container spacing={2} columns={12}>
            <Grid item sm={12} md={12}>
              <Box component="div" sx={{ p: 2, border: '1px dashed grey', height: '100%', padding: 0 }}>
                <FormDataConsumer>
                  {({ formData }) => {
                    if (!formData.invoiceFiles || formData.invoiceFiles.length === 0) {
                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}>
                          <Typography variant="subtitle1" align="center">Aguardando o arquivo XML</Typography>
                        </Box>
                      );
                    }

                    return (
                      <>
                        <Grid container sm={12}>
                          <Grid item md={12}>
                            <SimpleShowLayout record={formData as any}>
                              <ArrayField source="invoices" label="Notas Fiscais" emptyText="Não identificado">
                                <Datagrid
                                  bulkActionButtons={false}
                                  rowSx={invoiceStyle}
                                  sx={{
                                    '& .MuiTypography-root, .MuiTableCell-head': { fontSize: 12 },
                                  }}
                                >
                                  <TextField source="fileName" label="Nome" headerClassName={classes.fileNameColumn} />
                                  <TextField source="invoiceNumber" label="Número" />
                                  <FunctionField label="CNPJ" render={(record: any) => record.cnpj ? formatCnpj(record.cnpj) : 'Não identificado'} />
                                  <DateField source="issueDate" locales="pt-BR" label="Data de emissão" emptyText="Não identificado" showTime />
                                  <AccessKeyField source="accessKey" label="Chave de acesso" emptyText="Não identificado" />
                                  <FunctionField render={(record: any) => record && record.invoiceValue ? formatPrice(record.invoiceValue) : '--'} label="Valor da nota" />
                                  <LinkedFillinField invoiceFillins={invoiceFillins} />
                                  <RemoveButton headerClassName={classes.iconButton} />
                                  <DialogConfirm />
                                </Datagrid>
                              </ArrayField>
                            </SimpleShowLayout>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </Box>
            </Grid>

            <Grid item sm={12} md={12}>
              <UploadFileButton
                source="invoiceFiles"
                resetInitialValues={resetInitialValues}
                setXmlLoaded={setXmlLoaded}
                invoiceFillins={invoiceFillins}
                setInvoiceFillins={setInvoiceFillins}
                setCurrentInvoice={setCurrentInvoice}
                //@ts-ignore
                onChange={handleFileChange}
                validate={[required('Campo obrigatório')]}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <Divider sx={{ marginBottom: 2 }} />
              <Labeled label="Selecione o abastecimento" fullWidth>
                {props.children}
              </Labeled>
            </Grid>

          </Grid>
        </SimpleForm>
      </Box>
    </Card>
  );
};

const Legend = styled('legend')({
  paddingLeft: 12,
  paddingRight: 12,
});

export default React.memo(UploadInvoiceXml);