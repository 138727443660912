import React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  Empty,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
  useGetIdentity,
} from 'react-admin';
import { getChainId, getCompanyId, getUserId } from '../../utils/utils';
import { ChatResolveButton, Permission, Permissions } from '../../components';
import { ChatOrigin } from '../../models';
import { UserRole } from '../../providers/authProvider';

const ChatList: React.FC = (props) => {
  const companyId = getCompanyId();
  const chainId = getChainId();
  const userId = getUserId();
  const { identity } = useGetIdentity();


  const ChatToolbar = (props) => (
    <TopToolbar {...props}>
      <Permission userRole={UserRole.company}>
        <CreateButton label="Criar um chat" />
      </Permission>
    </TopToolbar>
  );

  const filterDefault: any = {};
  if (companyId) { filterDefault.companyId = companyId; }
  if (chainId) { filterDefault.chainId = chainId; }
  if (userId && companyId) { filterDefault.participantId = userId; }

  const ListFilters = () => (
    <Filter>
      <SelectInput
        label="Status"
        source="isResolved"
        choices={[{ label: 'Resolvido', value: true }, { label: 'Não resolvido', value: false }]}
        optionText="label"
        optionValue="value"
        alwaysOn
      />
      {
        Permissions({
          userRoles: [UserRole.chain],
          children: (
            <ReferenceInput source="companyId" reference="companies" filter={{ chainId: getChainId() }} textAlign="center" alwaysOn>
              <SelectInput sx={{ minWidth: 280 }} label="Grupo de transportadora" optionText="name" />
            </ReferenceInput>
          )
        })
      }
      {
        Permissions({
          userRoles: [UserRole.company],
          children: (
            <ReferenceInput source="chainId" reference="chains" filter={{ companyId: getCompanyId() }} textAlign="center" alwaysOn>
              <SelectInput label="Rede" optionText="name" />
            </ReferenceInput>
          )
        })
      }
      <SelectInput
        label="Origem"
        source="origin"
        choices={[{ label: 'Contrato', value: 'contract' }, { label: 'Nota fiscal', value: 'invoice' }]}
        optionText="label"
        optionValue="value"
        alwaysOn
      />
    </Filter>
  );

  return (
    <List
      {...props}
      title="Chat"
      filter={filterDefault}
      sort={{ field: "createdAt", order: "DESC" }}
      empty={(identity?.role === UserRole.company) && <Empty />}
      actions={<ChatToolbar />}
      filters={<ListFilters />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="subject" label="Assunto" />
        {
          Permissions({
            userRoles: [UserRole.chain],
            children: (
              <ReferenceField source="companyId" reference="companies" label="Grupo de transportadora" textAlign="center">
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.company],
            children: (
              <ReferenceField source="chainId" reference="chains" label="Rede" textAlign="center">
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        <FunctionField render={record => ChatOrigin.transalateType(record.origin)} label="Origem" textAlign="center" />
        <ReferenceField source="employeeId" reference="employees" label="Criado por" textAlign="center" >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Data de criação" textAlign="center" />
        <BooleanField source="isResolved" label="Resolvido" />
        <ChatResolveButton />
      </Datagrid>
    </List>
  );
};

export default ChatList;