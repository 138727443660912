import React, { useEffect, useState } from "react";
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  ExportButton,
  FormDataConsumer,
  FunctionField,
  Pagination,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  minLength,
  required,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRedirect
} from "react-admin";
import { List, Permissions } from "../components";
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from "../constants";
import { UserRole } from "../providers/authProvider";
import { CPFInput, PhoneInput } from "../utils/Inputs";
import { dateInputFormatter, formatCellphone, formatCpf, getCompanyId } from "../utils/utils";
import { FillinDatagrid } from "./Fillin";

const categoriesChoices = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
];

const ValetListActions = () => (
  <TopToolbar>
    <CreateButton resource="valets" />
    <ExportButton />
  </TopToolbar>
);

const ValetShowActions = () => (
  <TopToolbar>
    <EditButton resource="valets" />
  </TopToolbar>
);

const ValetEditActions = () => (
  <TopToolbar>
    <ShowButton resource="valets" />
  </TopToolbar>
);

const ValetList = props => {
  const { identity } = useGetIdentity();
  const redirect = useRedirect();

  return (
    <List {...props}
      title="Manobristas"
      filters={<SearchFilter />}
      filter={identity?.role === UserRole.company ? { companyId: getCompanyId(), isValet: true } : { isValet: true }}
      resource="drivers"
      actions={<ValetListActions />}
      empty={false}
    >
      <Datagrid rowClick="show" resource="valets" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
        <TextField source="cnh" label="CNH" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="mustInformOdometer" label="Obrigar a informar odômetro" />
        {identity && [UserRole.admin, UserRole.company].includes(identity.role) && <EditButton resource="valets" />}
        {identity && [UserRole.admin, UserRole.company].includes(identity.role) && <DeleteButton redirect={() => redirect("list", "valets")} />}
      </Datagrid>
    </List>
  );
};

const ValetEdit = props => {
  const { identity } = useGetIdentity();
  const redirect = useRedirect();
  const transform = data => {
    delete data.company;
    delete data.subCompany;
    return data;
  }

  return (
    <Edit title='Editar manobrista' transform={transform} {...props} resource="drivers" redirect={() => redirect("list", "valets")} actions={<ValetEditActions />}>
      <SimpleForm defaultValues={{ companyId: getCompanyId() }}>
        <Permissions userRoles={[UserRole.admin]}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
            <SelectInput label="Grupo de Transportadora" optionText="name" />
          </ReferenceInput>
        </Permissions>
        <Permissions userRoles={[UserRole.admin, UserRole.company]}>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                label="Transportadora"
                source="subCompanyId"
                reference="sub-companies"
                filter={{ companyId: formData.companyId }}
                sort={{ field: "name", order: "ASC" }}>
                <SelectInput label="Transportadora" optionText="name" validate={required()} />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Permissions>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ReferenceInput
                source="managerId"
                reference="managers"
                sort={{ field: 'name', order: 'ASC' }}
                filter={
                  identity?.role === UserRole.company ? { companyId: getCompanyId() }
                    : formData.companyId ? { companyId: formData.companyId } : null
                }
                pagination={null}
                perPage={null}
                emptyText={""}
                allowEmpty
              >
                <SelectInput label="Gestor" optionText="name" />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <CPFInput source="cpf" label="CPF" isRequired />
        <TextInput source="cnh" label="CNH" inputProps={{ maxLength: 11 }} />
        <SelectArrayInput
          label="Categoria(s) da CNH"
          source="cnhCategories"
          alwaysOn
          choices={categoriesChoices}
        />
        <DateInput
          source='cnhValidate'
          type="date"
          label="Vencimento da CNH"
          format={dateInputFormatter}
        />
        <TextInput source="register" label="Matrícula" />
        <PhoneInput source="phone" label="Telefone" />
        <PasswordInput source="password" label="Senha" validate={[minLength(6)]} />
        <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" />
        <BooleanInput source="mustChangePassword" label="Deve alterar senha ao entrar no aplicativo?" />
      </SimpleForm>
    </Edit >
  );
}

const ValetCreate = props => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const redirect = useRedirect();
  const [odometerDefault, setOdometerDefault] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const companyId = getCompanyId();

  useEffect(() => {
    if (identity?.role === UserRole.company) {
      dataProvider
        .getOne(`company-config`, { id: companyId })
        .then(({ data }) => setOdometerDefault(data.driverOdometer))
        .catch(() => notify('Erro ao buscar configurações atuais', { type: 'warning' }))
    }
  }, [companyId, identity]);

  return (
    <Create title='Adicionar manobrista' {...props} resource="drivers" redirect={() => redirect("list", "valets")} >
      <SimpleForm defaultValues={{ companyId: getCompanyId(), isValet: true }}>
        <Permissions userRoles={[UserRole.admin]}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
            <SelectInput label="Grupo de Transportadora" optionText="name" />
          </ReferenceInput>
        </Permissions>
        <Permissions userRoles={[UserRole.admin, UserRole.company]}>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                label="Transportadora"
                source="subCompanyId"
                reference="sub-companies"
                filter={{ companyId: formData.companyId }}
                sort={{ field: "name", order: "ASC" }}>
                <SelectInput label="Transportadora" optionText="name" validate={required()} />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Permissions>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ReferenceInput
                source="managerId"
                reference="managers"
                sort={{ field: 'name', order: 'ASC' }}
                filter={
                  identity?.role === UserRole.company ? { companyId: getCompanyId() }
                    : formData.companyId ? { companyId: formData.companyId } : null
                }
                pagination={null}
                perPage={null}
                emptyText=""
                allowEmpty
              >
                <SelectInput label="Gestor" optionText="name" />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <CPFInput source="cpf" label="CPF" isRequired />
        <TextInput source="cnh" label="CNH" inputProps={{ maxLength: 11 }} />
        <SelectArrayInput
          label="Categoria(s) da CNH"
          source="cnhCategories"
          alwaysOn
          choices={categoriesChoices}
        />
        <DateInput
          source='cnhValidate'
          type="date"
          label="Vencimento da CNH"
          format={dateInputFormatter}
        />
        <TextInput source="register" label="Matrícula" />
        <PhoneInput source="phone" label="Telefone" />
        <PasswordInput source="password" label="Senha" validate={[required(), minLength(6)]} />
        <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" defaultValue={odometerDefault} />
        <BooleanInput source="mustChangePassword" label="Deve alterar senha ao entrar no aplicativo?" />
      </SimpleForm>
    </Create >
  );
};

const ValetShow = props => {
  const { permissions } = usePermissions();
  return (
    <Show
      {...props}
      title="Manobrista"
      resource="drivers"
      actions={<ValetShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="Resumo">
          <TextField source="name" label="Nome" />
          <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
          <TextField source="cnh" label="CNH" />
          <ArrayField source="showCnhCategories" label="Categoria(s) da CNH">
            <SingleFieldList linkType={false} >
              <ChipField source="category" />
            </SingleFieldList>
          </ArrayField>
          <DateField source="cnhValidate" label="Vencimento da CNH" emptyText="Não definido" />
          <TextField source="register" label="Matrícula" emptyText="Não definida" />
          <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
          <ReferenceField label="Gestor" source="managerId" reference="managers" emptyText="Não definido" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceField label="Grupo de Transportadora" source="companyId" reference="companies">
              <TextField source="name" label="Transportadora" />
            </ReferenceField>
          }
          <TextField source="manager.name" label="Gestor" />
        </Tab>
        <Tab label="Abastecimentos">
          <ReferenceManyField
            reference="fillins"
            target="valetId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <FillinDatagrid />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const resource = {
  list: ValetList,
  edit: ValetEdit,
  create: ValetCreate,
  show: ValetShow,
  name: 'valets',
  permissions: [PERMISSIONS.LIST_VALETS]
}
export default resource;