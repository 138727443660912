import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  BooleanField,
  SearchInput,
  ReferenceField,
} from 'react-admin'
import ListPagination from '../../components/ListPagination'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'
import { getChainId, getCompanyId } from '../../utils/utils'
import { Permissions } from '../../components'
import { UserRole } from '../../providers/authProvider'

const UserFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>
)

const ProfileList = ({ permissions, ...props }) => (
  <Permission permission={PERMISSIONS.LIST_PROFILES}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      title="Perfis"
      filter={getCompanyId() ? { companyId: getCompanyId() } : getChainId() ? { chainId: getChainId() } : {}}
      filters={<UserFilter {...props} />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        {
          Permissions({
            userRoles: [UserRole.admin],
            children: (
              <ReferenceField source="chainId" reference="chains" label="Rede" emptyText="--" textAlign="center">
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.admin],
            children: (
              <ReferenceField source="companyId" reference="companies" label="G. Transportadora" emptyText="--" textAlign="center">
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        <BooleanField source="fullAccess" label="Acesso total" />
      </Datagrid>
    </List>
  </Permission >
);

export default ProfileList;