export enum DealType {
  discount = 'discount',
  increase = 'increase',
}

export namespace DealType {
  export const translateType = (type: DealType): string => {
    switch (type) {
      case DealType.discount:
        return "Desconto"
      case DealType.increase:
        return "Acréscimo";
      default: return "";
    };
  };

  export const dealTypeChoices: Array<{ id: string, name: string }> = Object.values(DealType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: DealType.translateType(type as DealType),
    } as { id: string, name: string }));
};