import FileIcon from '@mui/icons-material/InsertDriveFile';
import PropTypes from 'prop-types';
import {
    Exporter,
    FilterPayload,
    SortPayload,
    fetchRelatedRecords,
    useDataProvider,
    useListContext,
    useNotify,
    useResourceContext,
} from 'ra-core';
import { useCallback } from 'react';
import { Button, ButtonProps, RaRecord } from 'react-admin';

const ExportButton = (props: ExportButtonProps) => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        setProgress,
        hasSummary = true,
        ...rest
    } = props;
    const {
        filter,
        filterValues,
        exporter: exporterFromContext,
        total,
        sort,
    } = useListContext(props);
    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = useCallback(
        (event, pageParam, previousDataParam) => {
            let previousData: RaRecord[]
            if (previousDataParam) {
                previousData = previousDataParam
            } else if (hasSummary) {
                previousData = [{ data: [], total: 0, id: '' }]
            } else { previousData = new Array() }

            const page = pageParam || 1
            if (page === 1) { setProgress && setProgress(0.1) }
            dataProvider
                .getList(resource, {
                    sort,
                    filter: filter
                        ? { ...filterValues, ...filter }
                        : filterValues,
                    pagination: { page, perPage: maxResults },
                })
                .then(
                    ({ data, total }) => {
                        const pageCount = Math.ceil((total || 1) / maxResults)
                        setProgress && setProgress((total || 1) > maxResults ? page / pageCount * 100 : 100)

                        if (hasSummary) {
                            previousData[0].data = previousData[0].data.concat(data[0].data)
                        } else {
                            previousData = previousData.concat(data)
                        }

                        if (pageCount > page) {
                            handleClick(event, page + 1, previousData);
                        } else {
                            exporter &&
                                exporter(
                                    previousData,
                                    fetchRelatedRecords(dataProvider),
                                    dataProvider,
                                    resource
                                )
                        }
                    }
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            sort,
            dataProvider,
            exporter,
            filter,
            filterValues,
            maxResults,
            notify,
            onClick,
            resource,
        ]
    );

    return (
        <Button
            // @ts-ignore
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <FileIcon />;

const sanitizeRestProps = ({
    basePath,
    filterValues,
    resource,
    ...rest
}: Omit<ExportButtonProps, 'sort' | 'maxResults' | 'label' | 'exporter'>) =>
    rest;

interface Props {
    basePath?: string;
    exporter?: Exporter;
    setProgress?: (percent: number) => void;
    hasSummary?: boolean;
    filterValues?: FilterPayload;
    icon?: JSX.Element;
    label?: string;
    maxResults?: number;
    onClick?: (e: Event) => void;
    resource?: string;
    sort?: SortPayload;
}

export type ExportButtonProps = Props & ButtonProps;

ExportButton.propTypes = {
    basePath: PropTypes.string,
    exporter: PropTypes.func,
    setProgress: PropTypes.func,
    hasSummary: PropTypes.bool,
    filterValues: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number,
    resource: PropTypes.string,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    icon: PropTypes.element,
};

export default ExportButton;