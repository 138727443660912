import { Tooltip as MuiTooltip, PopperPlacementType } from '@mui/material';
import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { theme } from '../../theme';
import styled from '@emotion/styled';

const TooltipWrapper = React.forwardRef<any, { children: any, style: object | null }>(({ children, style, ...props }, ref) => (
  <div style={{ display: 'inherit', margin: 2, ...style }} ref={ref}>{React.cloneElement(children, props)}</div>
));

const Tooltip: React.FC<{ title: string, children: any, placement?: PopperPlacementType, [k: string]: any }> = ({ children, placement, sxContainer, sxTooltipContainer, textAlign, ...props }) => (
  <Container textAlign={textAlign} style={{ ...sxContainer }}>
    {children}
    <MuiTooltip
      {...props}
      PopperProps={{ placement: placement || 'auto-end' }}
      arrow
    >
      <TooltipWrapper style={sxTooltipContainer}>
        <HelpIcon style={{ fontSize: 18, color: theme.palette.primary.main, lineHeight: 'auto' }} />
      </TooltipWrapper>
    </MuiTooltip>
  </Container>
);

const Container = styled('div')<{ textAlign: string | null }>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  alignItems: 'flex-end',
  ...(props.textAlign ? { justifyContent: props.textAlign } : {})
}));

export default Tooltip;