import { Component } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import "./App.css";
import Dashboard from './Dashboard';
import containers from './containers';
import ComponentWithPermissions from "./containers/ComponentWithPermissions";
import Layout from './containers/Layout';
import Login from "./containers/login";
import MapStations from "./containers/MapStations";
import customRoutes from './customRoutes';
import { StoreProvider } from "./hooks/useStore";
import ApiProvider from "./providers/apiProvider";
import authProvider from "./providers/authProvider";
import i18nProvider from './providers/i18nProvider';
import { theme } from './theme';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
dayjs.extend(relativeTime);

class App extends Component {
  render() {
    return (
      <StoreProvider>
        <Admin
          dashboard={Dashboard}
          loginPage={Login}
          i18nProvider={i18nProvider}
          title="FleetX"
          authProvider={authProvider}
          dataProvider={ApiProvider}
          theme={theme}
          layout={Layout}
        >
          {permissions => {
            return ([
              ...containers
                .map(r => {
                  const parameters = !r.permissions || r.permissions.length === 0 || r.permissions.some(r => permissions.includes(r)) ? r : {};
                  Object.keys(parameters).forEach(key => {
                    const p = parameters[key];
                    if (p instanceof ComponentWithPermissions) {
                      parameters[key] = !p.permissions || p.permissions.length === 0 || p.permissions.some(r => permissions.includes(r)) ? p.component : undefined;
                    }
                  });
                  return <Resource name={r.name} {...parameters} hasCreate={!!r.create} hasEdit={!!r.edit} hasShow={!!r.show} />;
                }),
              <Resource
                name="map-stations"
                list={MapStations}
              />,
              <Resource name="station-qrcodes" />,
              <Resource name="transactions" />,
              <Resource name="chains" hasCreate />,
              <Resource name="companies" />,
              <Resource name="sub-companies" />,
              <Resource name="companies/code" />,
              <Resource name="reports/fillins" />,
              <Resource name="reports/vouchers" />,
              <Resource name="fillin-attempts" />,
              <Resource name="reports/averages" />,
              <Resource name="reports/driver-averages" />,
              <Resource name="reports/vehicle-averages" />,
              <Resource name="reports/prices" />,
              <Resource name="companies/companyId/stations" />,
              <Resource name="vehicles/averages" />,
              <Resource name="bill-fillins" />,
            ]);
          }
          }
          <CustomRoutes>
            {
              customRoutes.map(customRoute => <Route path={customRoute.path} element={customRoute.element} />)
            }
          </CustomRoutes>
        </Admin>
      </StoreProvider>
    );
  }
}

export default App;
