import {
    ChipField,
    Create,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    FormDataConsumer,
    NumberField,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    SelectArrayInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    required,
    useGetIdentity
} from 'react-admin';
import { List, Permissions } from '../components';
import LazyLoadAutoCompleteInput from '../components/LazyLoadAutoCompleteInput';
import { SearchFilter } from '../components/SearchFilter';
import { PERMISSIONS } from '../constants';
import { UserRole } from '../providers/authProvider';
import { PriceInput } from '../utils/Inputs';
import { formatCpf } from '../utils/utils';

const VoucherTitle = ({ record }) => {
    return <span>{`Voucher`}</span>;
};

const VoucherShow = props => (
    <Show title={<VoucherTitle />} {...props}>
        <SimpleShowLayout>
            <ReferenceField
                label="Motorista"
                reference="drivers"
                source="driverId"
            >
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="value" label="Valor" />
            <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="expiresAt" label="Expira em" locales="pt-BR" emptyText="" />
        </SimpleShowLayout>
    </Show>
);

const VoucherList = props => {
    const { identity } = useGetIdentity();
    return (
        <List
            {...props}
            title="Rotas"
            filters={<SearchFilter />}
            rowClick="show"
        >
            <Datagrid rowClick="show" {...props}
                bulkActionButtons={identity && [UserRole.admin, UserRole.company].includes(identity.role)}>
                <ReferenceField
                    label="Motorista"
                    reference="drivers"
                    source="driverId"
                >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="value" label="Valor" />
                <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <DateField source="expiresAt" label="Expira em" locales="pt-BR" emptyText="" />
                {
                    Permissions({
                        userRoles: [UserRole.admin, UserRole.company],
                        children: (
                            <EditButton />
                        )
                    })
                }
                {
                    Permissions({
                        userRoles: [UserRole.admin, UserRole.company],
                        children: (
                            <DeleteButton />
                        )
                    })
                }
            </Datagrid>
        </List>
    );
};

const VoucherCreate = props => (
    <Create title="Criar Voucher" {...props}>
        <SimpleForm>
            <LazyLoadAutoCompleteInput
                label="Motorista"
                reference="drivers"
                source="driverId"
                format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
                style={{ minWidth: 400, marginBottom: 24 }}
                size={"small"}
            />
            <LazyLoadAutoCompleteInput
                label="Veículos"
                source="vehicleId"
                format={choice => choice.licensePlate}
                style={{ minWidth: 160, marginBottom: 24 }}
                reference="vehicles"
            />
            <PriceInput source="value" label="Valor" locales="pt-BR" emptyText="0.00" validate={required()} />
            <ReferenceArrayInput
                label="Posto"
                reference="stations"
                source="stationIds"
                sort={{ field: 'name', order: 'ASC' }}
            >
                <SelectArrayInput optionText="name" label="Postos" />
            </ReferenceArrayInput>
            <DateInput source="expiresAt" label="Expira em" options={{ format: 'DD/MM/YYYY', disablePast: true }} />
        </SimpleForm>
    </Create>
);

const VoucherEdit = props => (
    <Edit title="Editar Voucher" {...props}>
        <SimpleForm>
            <FormDataConsumer>
                {({ formData }) => (
                    <LazyLoadAutoCompleteInput
                        label="Motorista"
                        reference="drivers"
                        source="driverId"
                        format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
                        style={{ minWidth: 400, marginBottom: 24 }}
                        size={"small"}
                        record={formData}
                    />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    <LazyLoadAutoCompleteInput
                        label="Veículos"
                        source="vehicleId"
                        format={choice => choice.licensePlate}
                        style={{ minWidth: 160, marginBottom: 24 }}
                        reference="vehicles"
                        record={formData}
                    />
                )}
            </FormDataConsumer>
            <PriceInput source="value" label="Valor" locales="pt-BR" validate={required()} />
            <ReferenceArrayInput
                label="Posto"
                reference="stations"
                source="stationIds"
                sort={{ field: 'name', order: 'ASC' }}
            >
                <SelectArrayInput optionText="name" label="Postos" />
            </ReferenceArrayInput>
            <DateInput source="expiresAt" label="Expira em" options={{ format: 'DD/MM/YYYY', disablePast: true }} />
        </SimpleForm>
    </Edit>
);

const resource = {
    list: VoucherList,
    edit: VoucherEdit,
    create: VoucherCreate,
    show: VoucherShow,
    name: 'vouchers',
    permissions: [PERMISSIONS.LIST_VOUCHERS]
}
export default resource;