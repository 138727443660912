export enum ChatOrigin {
  contract = 'contract',
  invoice = 'invoice',
  bill = 'bill',
};

export namespace ChatOrigin {
  export const transalateType = (type: ChatOrigin): string => {
    switch (type) {
      case ChatOrigin.contract:
        return "Contrato"
      case ChatOrigin.invoice:
        return "Nota fiscal";
      case ChatOrigin.bill:
        return "Fatura";
      default: return "Sem origem";
    };
  };
};

export const ChatOriginChoices = [
  {
    label: ChatOrigin.transalateType(ChatOrigin.contract),
    value: ChatOrigin.contract,
  },
  {
    label: ChatOrigin.transalateType(ChatOrigin.invoice),
    value: ChatOrigin.invoice,
  },
  {
    label: ChatOrigin.transalateType(ChatOrigin.bill),
    value: ChatOrigin.bill,
  },
];