import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { AppBar } from 'react-admin';
import { NotificationPanel, Permissions } from '../../components';
import { UserRole } from '../../providers/authProvider';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  userPlace: {
    marginRight: 20,
  }
});

const MyAppBar = props => {
  const classes = useStyles();

  return (
    <AppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />

      <Permissions userRoles={[UserRole.chain, UserRole.company]}>
        <NotificationPanel />
      </Permissions>
    </AppBar>
  );
};

export default MyAppBar;