import React from 'react';

const CircleStatus = ({ value, emptyText }) => {

  const getColor = () => {
    if (value > 100) {
      return '#1982c4';
    } else if (value >= 98) {
      return '#8ac926';
    } else if (value >= 95) {
      return '#ffca3a';
    }

    return '#ff3838';
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      {
        value ?
          <>
            <div style={{
              height: 11,
              width: 11,
              borderRadius: 5.5,
              marginRight: 10,
              backgroundColor: getColor()
            }} />
            <span style={{ fontSize: '0.875rem' }}>{value.toFixed(2)}</span>
          </>
          :
          <span>{emptyText}</span>
      }
    </div>
  );
}

export default CircleStatus;