export enum BankAccountType {
  savings = 'savings',
  checking = 'checking',
};

export namespace BankAccountType {
  export const transalateType = (type: BankAccountType): string => {
    switch (type) {
      case BankAccountType.savings:
        return "Poupança"
      case BankAccountType.checking:
        return "Corrente";
      default: return "";
    };
  };
};

export const BankAccountTypeChoices = [
  {
    value: BankAccountType.savings,
    label: BankAccountType.transalateType(BankAccountType.savings),
  },
  {
    value: BankAccountType.checking,
    label: BankAccountType.transalateType(BankAccountType.checking),
  },
];