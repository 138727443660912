import React from 'react';
import * as RA from 'react-admin';

const List: React.FC<RA.ListProps> = ({ children, ...props }) => {
  return (
    <RA.List
      disableSyncWithLocation
      {...props}
    >
      {children}
    </RA.List>
  );
}

export default List;