import { Avatar, Box, Button, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { grey } from '@mui/material/colors';
import {
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@mui/icons-material';
import { saveAs } from 'file-saver';
import { useNotify } from 'react-admin';
import moment from 'moment';
import dayjs from 'dayjs';

export type FileView = {
  filePath: string;
}
interface ChatMessageProps {
  side: 'right' | 'left';
  avatar?: string | null;
  messages: Array<{ body: string, sendAt: string, files: FileView[] }>;
}

const Row = styled('div')<{ side: string }>((props: any) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: props.side === 'left' ? 'flex-start' : 'flex-end',
}));

const MessageText = styled(Typography)<{ side: 'right' | 'left', index: number, total: number }>(({ theme, side, index, total }) => {
  const radius = theme.spacing(2.5);
  const rightBgColor = theme.palette.primary.main;

  let borderStyle = {};
  if (index === 0) {
    borderStyle = {
      borderTopRightRadius: radius,
    };
  } else if (index === total - 1) {
    borderStyle = {
      borderBottomRightRadius: radius,
    };
  }

  return {
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    marginBottom: 4,
    display: "inline-block",
    wordBreak: "break-all",
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    wordWrap: "break-word",
    ...(side === 'right' ? {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: rightBgColor,
      color: theme.palette.common.white,
    } : {}),
    ...(side === 'left' ? {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: grey[100]
    } : {}),
    ...borderStyle,
  };
});

const ChatFiles: React.FC<{ files: FileView[] }> = ({ files }) => {
  const notify = useNotify();

  const handleDownloadFile = (url: string) => {
    try {
      saveAs(url, `${url.split('/').slice(-1)}`);
    } catch (error) {
      notify(`Erro ao baixar arquivo`, { type: 'warning' });
    }
  };

  return (
    <>
      {
        (files && files.length > 0) &&
        <>
          <div style={{ display: 'flex', gap: 10 }}>
            {
              files.map((fileData, index) => (
                <Button onClick={() => handleDownloadFile(fileData.filePath)}>
                  <Avatar
                    key={index}
                    src={fileData.filePath}
                    variant="rounded"
                    sx={{ width: 56, height: 56 }}>
                    <InsertDriveFileOutlinedIcon />
                  </Avatar>
                </Button>
              ))
            }
          </div>
        </>
      }
    </>
  )
}

const ChatMessage: React.FC<ChatMessageProps> = (props) => {

  const { side, avatar, messages } = props;

  const formatSendTime = (sendAt: string) => {
    const now = dayjs();
    const sendTime = dayjs(sendAt);

    if (sendTime.format('YYYY-MM-DD') === now.subtract(1, 'day').format('YYYY-MM-DD')) {
      return `ontem, ${sendTime.format('HH:mm')}`;
    } else if (now.diff(sendTime, 'hours') > 24) {
      return sendTime.format('DD/MM/YYYY HH:mm');
    } else {
      return sendTime.fromNow(true);
    }
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={side === "right" ? "flex-end" : "flex-start"}
        marginY={1}
        paddingX={1}
      >
        {side === "left" && (
          <Grid item>
            {
              avatar ?
                <Avatar src={avatar} />
                :
                <Avatar />
            }
          </Grid>
        )}
        <Grid item xs>
          {messages.map((msg, i) => (
            <Box display="flex" flexDirection="column" alignItems={side === "right" ? "flex-end" : "flex-start"}>
              <Typography variant="caption" color="text.secondary" noWrap>{formatSendTime(msg.sendAt)}</Typography>
              <Row side={side}>
                <MessageText
                  index={i}
                  total={messages.length}
                  side={side}
                >
                  <ChatFiles files={msg.files} />
                  {(msg.body || '').split(/\n/g).map(line => (
                    <span style={{ display: 'block' }}>
                      {line}
                    </span>
                  ))}
                </MessageText>
              </Row>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default ChatMessage;