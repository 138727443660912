import { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import lodash from 'lodash';
import moment from 'moment';
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  minLength,
  minValue,
  required,
  useCreate,
  useDataProvider,
  useGetList,
  useNotify
} from 'react-admin';
import { AddCustomItemButton, Permission, PriceInput } from '../../components';
import ChatAccordion from '../../components/ChatAccordion';
import { PERMISSIONS } from '../../constants';
import { ContractFuel, ContractProduct, ContractProductMeasureType, DealValueType } from '../../models';
import { getChainId, getUserId } from '../../utils/utils';

const ContractEdit = (props) => {
  const { getList } = useDataProvider();
  const [create] = useCreate();
  const notify = useNotify();

  const { data: fuels } = useGetList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: null });

  const [chatData, setChatData] = useState(null);

  const createChatMessage = ({ contractId, subject, body, chatFiles, participantIds }) => new Promise((resolve, reject) => {
    if (subject || body || chatFiles) {
      notify('Enviando sua mensagem');

      const data = {
        subject: subject || '(Sem assunto)',
        chatMessage: {
          body,
          files: chatFiles,
        },
        employeeId: getUserId(),
        chainId: getChainId(),
        origin: 'contract',
        contractId,
      }

      if (participantIds) {
        data.participantIds = participantIds;
      }

      create('chats', { data }, {
        onSettled: (_data, error) => {
          if (error.status) {
            notify(error.message || 'Não foi possível encaminhar a mensagem, mas pode ser feito uma nova tentativa na tela de notas fiscais', { type: 'warning' });
            reject(error);
            return;
          }

          notify('Mensagem enviada');
          resolve(_data);
        }
      });
    } else {
      resolve(null);
    }
  });

  return (
    <Edit
      {...props}
      title='Editar Contrato'
      transform={({ subject, body, chatFiles, participantIds, ...data }) => {
        setChatData({ subject, body, chatFiles, participantIds });

        if (data.filePath) {
          data.files = data.filePath.filter(file => file.rawFile);
          data.filePath = data.filePath.filter(file => !file.rawFile);
        }

        if (data.contractProducts?.length) {
          data.contractProducts = data.contractProducts.map(contractProduct => lodash.omitBy(new ContractProduct(contractProduct), lodash.isNil));
        }

        if (data.contractFuels?.length) {
          data.contractFuels = data.contractFuels.map(contractFuel => new ContractFuel(contractFuel));
        }

        delete data.contractStations;
        return data;
      }}
      mutationOptions={{
        onSettled: (data, error) => {
          if (error.status) {
            notify(error.message || 'Não foi possível editar o contrato.', { type: 'warning' });
            return;
          }
          createChatMessage({ ...chatData, contractId: data.id });
        },
      }}
    >
      <SimpleForm>
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput sort={{ field: 'name', order: 'ASC' }} source="chainId" reference="chains">
            <SelectInput disabled optionText="name" label="Rede" emptyText="Nenhum" style={{ minWidth: 466 }} validate={[required()]} />
          </ReferenceInput>
        </Permission>
        <TextInput label="Nome" source="name" style={{ minWidth: 466 }} validate={[required()]} />
        <Labeled label="Validade do contrato">
          <FormDataConsumer>
            {({ formData }) => (
              <Box sx={{ paddingTop: 2 }}>
                <DateTimeInput
                  source='startDate'
                  label='Início'
                  locales="pt-BR"
                  parse={(value) => moment(value).toISOString()}
                  defaultValue={moment().startOf('day').toISOString()} sx={{ marginRight: 4 }}
                  required
                />
                <DateTimeInput
                  source='endDate'
                  label='Término'
                  locales="pt-BR"
                  parse={(value) => moment(value).toISOString()}
                  defaultValue={moment().add(30, 'days').endOf('day').toISOString()}
                  validate={[minValue(formData.startDate, 'Data inferior à de início')]}
                  required
                />
              </Box>
            )}
          </FormDataConsumer>
        </Labeled>
        <ReferenceInput sort={{ field: 'name', order: 'ASC' }} source="companyId" reference="companies">
          <SelectInput disabled optionText="name" label="Grupo de Transportadora" emptyText="Nenhum" style={{ minWidth: 466 }} validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput sort={{ field: 'name', order: 'ASC' }} source="stationIds" reference="stations">
          <SelectArrayInput optionText="name" label="Postos" emptyText="Nenhum" style={{ minWidth: 466 }} validate={[required()]} />
        </ReferenceArrayInput>
        <BooleanInput source="isForAggregated" label="Válido para agregados" />
        <BooleanInput source="isPriceLogEnabled" label="Compartilhar histórico de preço" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.isForAggregated &&
            <BooleanInput source="isPriceLogForAggregateEnabled" label="Compartilhar histórico de preço com Agregado" />
          }
        </FormDataConsumer>
        <Labeled label="Faturamento">
          <Box>
            <ArrayInput source="billingDay" label="Dias de Faturamento" style={{ marginTop: 8, marginBottom: 8 }} validate={[minLength(1), required()]}>
              <SimpleFormIterator
                disableReordering
                fullWidth
                sx={{
                  '& .RaSimpleFormIterator-action': { visibility: 'visible' },
                  '& .RaSimpleFormIterator-line': { paddingTop: 2 }
                }}
              >
                <NumberInput label="Dia" min={1} max={31} required />
              </SimpleFormIterator>
            </ArrayInput>
            <NumberInput label="Prazo de Vencimento (em dias)" min={1} source="dueDate" validate={required()} />
            <SelectInput
              label="Forma de Pagamento"
              sx={{ width: 220, marginLeft: 4, marginTop: 0 }}
              validate={required()}
              source="paymentType"
              choices={[
                { id: 'bill', name: 'Boleto' },
                { id: 'deposit', name: 'Depósito' },
              ]}
            />
          </Box>
        </Labeled>
        <Typography variant="caption" sx={{ marginBottom: 2 }}>Este intervalo será considerado para definir se a transportadora atingiu a quantidade mínima de litros para ativar o acordo.</Typography>
        <NumberInput
          source="dealPeriod"
          label="Período de promoção (intervalo em dias)"
          min={1}
          validate={[minValue(1)]}
          style={{ minWidth: 466 }}
        />
        <Labeled label="Acordos de Preço">
          <ArrayInput source="contractFuels" label="" style={{ marginBottom: 10 }}>
            <SimpleFormIterator
              disableReordering
              fullWidth
              sx={{ '& .RaSimpleFormIterator-action': { visibility: 'visible' } }}
              addButton={<AddCustomItemButton name="contractFuels" resetFields={{ dealType: "discount", dealValue: 0, fuelId: "", dealValueType: "", dealMinValue: 0 }} />}
            >
              <FormDataConsumer>
                {({ scopedFormData: scopedFormDataContractFuel, formData, getSource }) => {
                  return (
                    <>
                      <Box sx={{ marginTop: 2 }}>
                        <SelectInput
                          source={getSource('fuelId')}
                          choices={(fuels || []).filter(fuel => fuel.acronym !== 'ARL')}
                          label="Combustível"
                          sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                          record={scopedFormDataContractFuel}
                          optionText="name"
                          style={{ minWidth: 220 }}
                          validate={required()}
                        />
                        <SelectInput
                          label="Tipo"
                          sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                          source={getSource('dealValueType')}
                          record={scopedFormDataContractFuel}
                          choices={DealValueType.dealValueTypeChoices}
                          isRequired
                        />
                        {scopedFormDataContractFuel && (scopedFormDataContractFuel.dealValueType === 'cents' || scopedFormDataContractFuel.dealValueType === 'prefixed')
                          ? <PriceInput
                            sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                            defaultValue={0}
                            source={getSource('dealValue')}
                            label="Valor"
                            record={scopedFormDataContractFuel}
                          />
                          : <NumberInput
                            sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                            defaultValue={0}
                            source={getSource('dealValue')}
                            label="Valor"
                            record={scopedFormDataContractFuel}
                          />
                        }
                        <NumberInput
                          sx={{ width: 200, marginTop: 2 }}
                          source={getSource('dealMinValue')}
                          defaultValue={0}
                          label="Mínimo para promoção (litros)"
                          record={scopedFormDataContractFuel}
                          min={0}
                          validate={[minValue(0)]}
                        />
                      </Box>
                      {scopedFormDataContractFuel && (scopedFormDataContractFuel.dealValueType && scopedFormDataContractFuel.dealValueType !== 'prefixed') &&
                        <SelectInput
                          source={getSource('dealType')}
                          record={scopedFormDataContractFuel}
                          label="Desconto ou Acréscimo?"
                          defaultValue="discount"
                          sx={{ marginRight: 2, minWidth: 200 }}
                          choices={[
                            { id: 'discount', name: 'Desconto' },
                            { id: 'increase', name: 'Acréscimo' },
                          ]}
                          isRequired
                        />
                      }
                    </>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Acordos de Produtos">
          <ArrayInput source="contractProducts" label="" style={{ marginBottom: 40 }}>
            <SimpleFormIterator
              disableReordering
              fullWidth
              sx={{ '& .RaSimpleFormIterator-action': { visibility: 'visible' } }}
              addButton={<AddCustomItemButton name="contractProducts" resetFields={{ dealType: "discount", dealValue: 0, fuelId: "", dealValueType: null, dealMinValue: 0 }} />}
            >
              <FormDataConsumer>
                {({ scopedFormData: scopedFormDataContractProduct, formData, getSource }) => {
                  return (
                   <> 
                    <Box sx={{ marginTop: 2 }}>
                      <ReferenceInput
                        reference="fuels"
                        source={getSource('fuelId')}
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={{ acronym: 'ARL' }}
                        perPage={false}
                      >
                        <SelectInput
                          label="Produto"
                          sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                          record={scopedFormDataContractProduct}
                          optionText="name"
                          validate={required()}
                        />
                      </ReferenceInput>
                      <SelectInput
                        source={getSource('measureType')}
                        label="Medida"
                        choices={ContractProductMeasureType.contractProductMeasureTypeChoices}
                        sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                        record={scopedFormDataContractProduct}
                        optionText="name"
                        style={{ minWidth: 150 }}
                        validate={required()}
                      />
                      {
                        (scopedFormDataContractProduct && (scopedFormDataContractProduct.measureType === ContractProductMeasureType.inBulk)) &&
                        <>
                          <SelectInput
                            label="Tipo de valor"
                            sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                            source={getSource('dealValueType')}
                            defaultValue="prefixed"
                            record={scopedFormDataContractProduct}
                            choices={[
                              { id: 'prefixed', name: 'Prefixado' },
                            ]}
                            isRequired
                          />
                          {scopedFormDataContractProduct && (scopedFormDataContractProduct.dealValueType === 'prefixed') &&
                            <PriceInput
                              sx={{ width: 150, marginRight: 2, marginTop: 2 }}
                              defaultValue={0}
                              source={getSource('dealValue')}
                              label="Valor"
                              record={scopedFormDataContractProduct}
                            />
                          }
                          <NumberInput
                            sx={{ width: 200, marginTop: 2 }}
                            source={getSource('dealMinValue')}
                            defaultValue={0}
                            label="Mínimo para promoção (litros)"
                            record={scopedFormDataContractProduct}
                            min={0}
                            validate={[minValue(0)]}
                          />
                        </>
                      }
                      {
                        (scopedFormDataContractProduct && (scopedFormDataContractProduct.measureType === ContractProductMeasureType.gallon)) &&
                        <>
                          <SelectInput
                            source={getSource('amount')}
                            label="Capacidade"
                            choices={[
                              { id: 5, name: '5 litros' },
                              { id: 10, name: '10 litros' },
                              { id: 20, name: '20 litros' },
                            ]}
                            record={scopedFormDataContractProduct}
                            sx={{ width: 100, marginRight: 2, marginTop: 2 }}
                            validate={[required()]}
                          />
                          <PriceInput
                            sx={{ width: 150, marginRight: 2, marginTop: 2 }}
                            defaultValue={0}
                            source={getSource('dealValue')}
                            label="Valor"
                            record={scopedFormDataContractProduct}
                          />
                        </>
                      }
                    </Box>
                      {scopedFormDataContractProduct && (scopedFormDataContractProduct.dealValueType && scopedFormDataContractProduct.dealValueType !== 'prefixed') &&
                        <SelectInput
                          label="Desconto ou acréscimo?"
                          source={getSource('dealType')}
                          choices={[
                            { id: 'discount', name: 'Desconto' },
                            { id: 'increase', name: 'Acréscimo' },
                          ]}
                          sx={{ width: 220, marginRight: 2, marginTop: 2 }}
                          validate={[required()]}
                          isRequired
                        />
                      }
                    </>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <FileInput
          source="filePath"
          label="Arquivos"
          multiple
          format={values => {
            return values ? values.map(value => ({ src: value, title: typeof value === 'string' ? value.split('/').slice(-1) : value.title })) : null;
          }}
          parse={(values) => {
            return values ? values.map(value => value instanceof File ? { rawFile: value, title: value.name } : value.src) : null;
          }}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ formData }) =>
            <ChatAccordion style={{ marginTop: '40px', minWidth: '100%' }} companyId={formData.companyId} disabledSelectOrigin />
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default ContractEdit;