import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceManyField,
  SelectArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  minValue,
  useGetIdentity,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { List, Permissions } from '../components';
import Permission from '../components/Permission';
import { SearchFilter } from '../components/SearchFilter';
import { PERMISSIONS } from '../constants';
import { UserRole } from '../providers/authProvider';
import { adressFormat, getCompanyId } from '../utils/utils';
import { DriverRoutesDatagrid } from './Driver';

const RouteTitle = () => {
  const record = useRecordContext();
  return <span>{`${record.name}`}</span>;
};

export const RouteDatagrid = props => (
  <Datagrid expand={<DriverRoutesDatagrid />} {...props} hover={false}>
    <TextField source="name" label="Rota" />
    <NumberField source="distance" label="Distância" />
  </Datagrid>
);

const RouteShow = props => (
  <Show title={<RouteTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nome" />
      <NumberField source="distance" label="Distância (km)" locales="pt-BR" />
      <ReferenceManyField
        EditButton={false}
        reference="companies/companyId/stations"
        target="routeId"
        label={"Postos"}
      >
        <Datagrid hover={false} bulkActionButtons={false}>
          <TextField source="name" label="Nome" />
          <FunctionField label="Endereço" render={record => adressFormat(record)} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const RouteList = props => {
  const { identity } = useGetIdentity();
  return (
    <List
      {...props}
      title="Rotas"
      filters={<SearchFilter />}
      filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : null}
      rowClick="show"
    >
      <Datagrid rowClick="show" {...props}
        bulkActionButtons={identity && [UserRole.admin, UserRole.company].includes(identity.role)}
      >
        <TextField source="name" label="Nome" />
        <NumberField source="distance" label="Distância (km)" locales="pt-BR" />
        <Permission userRole={UserRole.admin}>
          <ReferenceField label="Grupo de Transportadora" source="companyId" reference="companies" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        {
          Permissions({
            userRoles: [UserRole.admin, UserRole.company],
            children: (
              <EditButton />
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.admin, UserRole.company],
            children: (
              <DeleteButton />
            )
          })
        }
      </Datagrid>
    </List>
  );
};


const RouteEdit = props => {
  const { identity } = useGetIdentity();
  return (
    <Edit title='Editar rota' {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" />
        <NumberInput source="distance" label="Distância (km)" locales="pt-BR" validate={minValue(1)} />
        <ReferenceArrayInput
          label="Posto"
          reference="stations"
          source="stationIds"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={false}
          filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
        >
          <SelectArrayInput optionText="name" label="Postos" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}

const RouteCreate = props => {
  const { identity } = useGetIdentity();
  return (
    <Create title='Adicionar rota' {...props}>
      <SimpleForm defaultValues={{ companyId: localStorage.getItem('companyId') }}>
        <TextInput source="name" label="Nome" />
        <NumberInput source="distance" label="Distância (km)" locales="pt-BR" validate={minValue(1)} />
        <ReferenceArrayInput
          label="Posto"
          reference="stations"
          source="stationIds"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={false}
          filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
        >
          <SelectArrayInput optionText="name" label="Postos" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

const resource = {
  list: RouteList,
  edit: RouteEdit,
  create: RouteCreate,
  show: RouteShow,
  name: 'routes',
  permissions: [PERMISSIONS.LIST_RATNGS]
};

export default resource;
