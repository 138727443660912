import IconCancel from '@mui/icons-material/Cancel';
import IconContentAdd from '@mui/icons-material/GetApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useState } from 'react';
import { Button, FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, useGetIdentity, useNotify } from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import { UserRole } from '../providers/authProvider';
import { getChainId } from '../utils/utils';

export const ImportCSVButton = props => {
    const { identity } = useGetIdentity();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false)
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="Importar CSV">
                <IconContentAdd style={{ transform: "rotate(180deg)", fontSize: "20" }} />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={closeDialog}
                aria-label="Importar CSV"
            >
                <DialogTitle>Importar CSV</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        // We want no toolbar at all as we have our modal actions
                        toolbar={null}
                        resource="station-attendants"
                    >
                        <ReferenceInput
                            source="stationId"
                            reference="stations"
                            filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
                            sort={{ field: "name", order: "ASC" }}>
                            <SelectInput optionText="name" label="Posto" />
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                const config = {
                                    logging: true,
                                    transformRows: (rows) => {
                                        rows.forEach(row => row.stationId = formData.stationId);
                                        return rows.filter(row => row.identfid);
                                    },
                                    postCommitCallback: (result) => {
                                        closeDialog();
                                        if (result.length && !result[0].success) {
                                            notify(result[0].err.message, { type: 'warning' })
                                        }
                                    }
                                };
                                return (<ImportButton {...props} {...rest} {...config} />);
                            }}
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button label="ra.action.cancel" onClick={closeDialog}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

}
