import { Button, useNotify, useRecordContext } from "react-admin";
import { saveAs } from 'file-saver';

const DownloadFile = () => {
  const record = useRecordContext();
  const notify = useNotify();

  if (!record) { return null; }

  const handleDownloadXml = (url: string) => {
    try {
      saveAs(url, url.split('/').slice(-1));
    } catch (error) {
      notify(error.message || `Erro ao baixar XML da nota fiscal`, { type: 'warning' });
    }
  }

  return (
    <Button
      label={record.invoiceNumber}
      onClick={(e) => {
        e.stopPropagation();
        handleDownloadXml(record.path);
      }}
    />
  )
}

export default DownloadFile;