import React from 'react';
import { FormDataConsumer, maxLength, minLength, NumberInput, PasswordInput, required, TextInput } from 'react-admin';
import { LazyLoadAutoCompleteInput } from '../../components';
import { formatCpf, getChainId } from '../../utils/utils';

export const FormInitialInfo: React.FC<any> = ({ nextStep, SimpleFormButton }) => (
  <>
    {/* @ts-ignore */}
    <LazyLoadAutoCompleteInput
      label="Grupo de Transportadora"
      reference="companies"
      source="companyId"
      format={choice => `${choice.code} - ${choice.name}`}
      style={{ minWidth: 400 }}
      filter={{ chainId: getChainId() }}
      sort={{ field: "name", order: "ASC" }}
      size={"small"}
      validate={[required()]}
    />
    <FormDataConsumer>
      {({ formData }) => (
        formData.companyId &&
        <>
          {/* @ts-ignore */}
          <LazyLoadAutoCompleteInput
            label="Motorista"
            reference="drivers"
            source="driverId"
            format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
            style={{ minWidth: 400 }}
            size={"small"}
            filter={{ companyId: formData.companyId }}
            validate={[required()]}
          />
          <PasswordInput source="password" label="Senha" validate={[required()]} style={{ maxWidth: 250 }} />
          {/* @ts-ignore */}
          <LazyLoadAutoCompleteInput
            label="Placa do Veículo"
            source="vehicleId"
            format={choice => choice.licensePlate}
            style={{ minWidth: 250, marginTop: 8 }}
            filter={{ companyId: formData.companyId }}
            reference="vehicles"
            validate={[required()]}
          />
        </>
      )}
    </FormDataConsumer>
    <NumberInput source="odometer" label="Odômetro" validate={[required()]} style={{ marginTop: 8 }} />
    <TextInput
      source="code"
      label="Código da bomba"
      style={{ marginTop: 8 }}
      // parse={value => (new Array(7).join('0') + value.replace(/\D/g, '')).slice(-7)}
      validate={[required(), maxLength(7), minLength(7)]}
    />
  </>
);
