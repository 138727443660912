import { ArrayField, ChipField, Datagrid, DateField, DateInput, Filter, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useListContext, useRecordContext } from "react-admin";
import { ListPagination, ReportList, Permissions, Permission } from "../../components";
import moment from 'moment';
import { formatPrice, getCompanyId } from "../../utils/utils";
import { Box, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { PERMISSIONS } from "../../constants";
import { UserRole } from "../../providers/authProvider";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const ReportMessage = ({ title }) => (
  <Box sx={{ padding: 2 }}>
    <Typography variant="subtitle1" textAlign="center">{title}</Typography>
  </Box>
);

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ generatedReportAt: new Date(), ...filterValues }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField label="Rede" emptyText="Todos" source="chainId" textAlign="center" basePath="chains" reference="chains" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField emptyText="Todos" source="stationIds" label="Postos" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField emptyText="Todos" source="fuelIds" label="Combustíveis" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" emptyText="" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
};

const DetailedPriceList = () => {
  const props = useListContext();
  const classes = useStyles();

  if (!props.data) { return <></>; }

  if (props.data[0] && props.data[0].error) {
    return (<ReportMessage title={props.data[0].error} />);
  }

  const PriceField = () => {
    const record = useRecordContext();
    if (!record) { return null; }
    const price = `${formatPrice(record, {
      minimumFractionDigits: 3, maximumFractionDigits: 3
    })}`
    return (
      <ChipField record={{ price }} source="price" />
    )
  };

  return (
    <>
      <Typography className={classes.title}>Preço Detalhado</Typography>
      <Datagrid
        bulkActionButtons={false}
        data={props.data}
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        rowClick={false}
      >
        <ReferenceField source="fuel_id" reference="fuels" label="Combustível" link={false} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="prices" label="Preços" sortable={false}>
          <SingleFieldList linkType={false}>
            <PriceField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <DateField source="created_at" label="Data" showTime />
        <NumberField
          source="price_per_liter"
          label="Preço do abastecimento"
          locales="pt-BR"
          textAlign="center"
          sortable={false}
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <DateField source="received_at" label="Data do abastecimento" showTime />
      </Datagrid>
    </>
  );
};

const FilterDetailedPrice = () => {
  const { filterValues } = useListContext();
  return (
    <Filter>
      <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />
      <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />
      {
        Permission({
          userRole: UserRole.admin,
          children: (
            <ReferenceInput
              source="companyId"
              reference="companies"
              perPage={false}
              sort={{ field: "name", order: "ASC" }}
              emptyText="Todos"
              isRequired
              alwaysOn
            >
              <SelectInput label="G. Transportadora" optionText={'name'} style={{ minWidth: 160 }} isRequired />
            </ReferenceInput>
          )
        })
      }
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        filter={{ companyId: filterValues.companyId || getCompanyId(), onlyWithContract: true, }}
        emptyText="Todos"
        alwaysOn
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        filter={filterValues.chainId ? { chainId: filterValues.chainId } : {}}
        alwaysOn
      >
        <SelectArrayInput
          label="Postos"
          optionText="name"
          disabled={!filterValues.chainId}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        sort={{ field: 'name', order: 'ASC' }}
        source="fuelIds"
        reference="fuels"
        alwaysOn
      >
        <SelectArrayInput optionText="name" label="Combustíveis" />
      </ReferenceArrayInput>
    </Filter>
  );
};

const DetailedPrice = (props) => {
  return (
    <Permissions permissions={[PERMISSIONS.REPORT_DETAILED_PRICE]}>
      <ReportList
        {...props}
        basePath="reports/detailed-price"
        title="Preço detalhado"
        resource="reports/detailed-price"
        filters={<FilterDetailedPrice />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Preços Detalhados"
        filterTablePrint={<FilterTablePrint />}
        landscape={true}
      >
        <DetailedPriceList />
      </ReportList>
    </Permissions>
  );
}

export default DetailedPrice;