import React, { useCallback, useState } from "react";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SimpleForm, Toolbar, Button, TextInput, useRecordContext, useNotify, useRefresh, useDataProvider, useResourceContext, required } from "react-admin";
import { FillinAttemptStatus } from "../../models";


const ApproveOrDenyDialog = ({ open, handleClose, status, screen = 'fillin' }: { open: boolean, handleClose: () => void, status: FillinAttemptStatus | undefined, screen?: string }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext();

  const handleClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const screenType = {
    fillin: 'fillin',
    fillinAttempts: 'fillinAttempts',
  }

  const ToolBarCustom = () => {
    return (
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Button label="Cancelar" variant="contained" size="medium" onClick={handleClose} disabled={isLoading} />
        <Button label="Confirmar" type="submit" variant="contained" size="medium" disabled={isLoading} startIcon={isLoading ? <CircularProgress size={20} /> : <></>} />
      </Toolbar>
    );
  };

  const handleApproveOrDeny = ({ statusJustification }: { statusJustification: string }) => {
    setLoading(true)
    dataProvider.update(resource, {
      id: record.id,
      data: { status, statusJustification },
      previousData: record
    })
      .then(() => {
        notify(`${status === FillinAttemptStatus.approved ? 'Aprovado' : 'Reprovado'} com sucesso`);
        refresh();
      })
      .catch((e) => {
        notify(e.message || `Não foi possível ${status === FillinAttemptStatus.approved ? 'aprovar' : 'reprovar'}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      }
      );
  }


  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      onClick={handleClick}
    >
      <DialogTitle>
        {status === FillinAttemptStatus.approved ? 'Aprovar' : 'Reprovar'} {screen === screenType.fillin ? '' : 'tentativa de'} Abastecimento
      </DialogTitle>
      <DialogContent >
        <SimpleForm
          toolbar={<ToolBarCustom />}
          onSubmit={handleApproveOrDeny}
        >
          <TextInput source="statusJustification" label="Justificativa" multiline fullWidth validate={required()} />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default ApproveOrDenyDialog;