import React from 'react';
import { SelectInput, RadioButtonGroupInput, TextInput, required } from 'react-admin';
import { isValidCNPJ, isValidCPF, isValidCEP } from '@brazilian-utils/brazilian-utils';
import { formatCellphone, formatCep, formatCnpj, formatCpf, formatPrice, strip } from './utils';

const validMercosulLicensePlateRegex = /^[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}$/;
const validBrazilianLicensePlateRegex = /^[A-Z]{3}[0-9]{4}$/;

export const StateInput = (props) => {
    return (
        <SelectInput
            {...props}
            choices={[
                { id: 'AC', name: "Acre" },
                { id: 'AL', name: "Alagoas" },
                { id: 'AP', name: "Amapá" },
                { id: 'AM', name: "Amazonas" },
                { id: 'BA', name: "Bahia" },
                { id: 'CE', name: "Ceará" },
                { id: 'DF', name: "Distrito Federal" },
                { id: 'ES', name: "Espírito Santo" },
                { id: 'GO', name: "Goiás" },
                { id: 'MA', name: "Maranhão" },
                { id: 'MT', name: "Mato Grosso" },
                { id: 'MS', name: "Mato Grosso do Sul" },
                { id: 'MG', name: "Minas Gerais" },
                { id: 'PA', name: "Pará" },
                { id: 'PB', name: "Paraíba" },
                { id: 'PR', name: "Paraná" },
                { id: 'PE', name: "Pernambuco" },
                { id: 'PI', name: "Piauí" },
                { id: 'RJ', name: "Rio de Janeiro" },
                { id: 'RN', name: "Rio Grande do Norte" },
                { id: 'RS', name: "Rio Grande do Sul" },
                { id: 'RO', name: "Rondônia" },
                { id: 'RR', name: "Roraima" },
                { id: 'SC', name: "Santa Catarina" },
                { id: 'SP', name: "São Paulo" },
                { id: 'SE', name: "Sergipe" },
                { id: 'TO', name: "Tocantins" }
            ]} />
    );
};

export const GenderInput = (props) => {
    return (
        <RadioButtonGroupInput
            {...props}
            choices={[
                { id: 'M', name: 'Masculino' },
                { id: 'F', name: 'Feminino' },
                { id: 'X', name: 'Não binário' },
            ]}
        />
    );
};

export const CEPInput = (props) => {
    const validateCep = (value) => {
        return value && !isValidCEP(value) ? 'CEP Inválido' : undefined;
    }

    return (
        <TextInput
            {...props}
            format={v => formatCep(v)}
            parse={v => strip(v)}
            inputProps={{ maxLength: 9 }}
            validate={props.isRequired ? [required('CEP é obrigatório'), validateCep] : [validateCep]}
        />
    );
};

export const CPFInput = (props) => {
    const validateCpf = (value) => {
        return value && !isValidCPF(value) ? 'CPF Inválido' : undefined;
    }

    return (
        <TextInput
            {...props}
            format={v => formatCpf(v)}
            parse={v => strip(v).substring(0, 11)}
            inputProps={{ maxLength: 14 }}
            validate={props.isRequired ? [required('CPF é obrigatório'), validateCpf] : [validateCpf]}
        />
    );
};

export const CNPJInput = (props) => {

    const validateCnpj = (value) => {
        return value && !isValidCNPJ(value) ? 'CNPJ Inválido' : undefined;
    }

    return (
        <TextInput
            {...props}
            validate={props.isRequired ? [required('CNPJ é obrigatório'), validateCnpj] : [validateCnpj]}
            format={v => formatCnpj(v)}
            parse={v => strip(v).substring(0, 14)}
        />
    );
};

export const PhoneInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCellphone(v)}
            parse={v => v === '' ? null : strip(v)}
            inputProps={{ maxLength: 15 }}
        />
    );
};

export const PriceInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatPrice(v || 0)}
            parse={v => (+strip(v)) / 100}
        />
    );
};

export const OtpCodeInput = (props) => {
    return (
        <TextInput
            {...props}
            parse={v => v === '' ? null : strip(v)}
            inputProps={{ maxLength: 4, minLength: 4 }}
            validate={props.isRequired && [required('Código é obrigatório')]}

        />
    );
}

export const LicensePlateInput = (props) => {

    const isValidLicensePlate = (value) => {
        return validBrazilianLicensePlateRegex.test(value) || validMercosulLicensePlateRegex.test(value);
    }

    const validateLicense = (value) => {
        return value && !isValidLicensePlate(value) ? 'Placa Inválida' : undefined;
    }

    const parseLicencePlate = (value) => {
        let cleanValue = value.replace(/[^A-Z0-9]/gi, '').toUpperCase();

        for (let i = 0; i < cleanValue.length; i++) {
            const char = cleanValue[i];

            if (i < 3) {
                if (!/[A-Z]/.test(char)) {
                    cleanValue = cleanValue.slice(0, i);
                    break;
                }
            } else if (i === 3) {
                if (!/[0-9]/.test(char)) {
                    cleanValue = cleanValue.slice(0, i);
                    break;
                }
            } else if (i === 4) {
                if (!/[A-Z0-9]/.test(char)) {
                    cleanValue = cleanValue.slice(0, i);
                    break;
                }
            } else if (i > 4) {
                if (!/[0-9]/.test(char)) {
                    cleanValue = cleanValue.slice(0, i);
                    break;
                }
            }
        }
        return cleanValue;
    }

    return (
        <TextInput
            {...props}
            parse={(v) => v && parseLicencePlate(v)}
            inputProps={{ maxLength: 7 }}
            validate={props.isRequired ? [validateLicense, required('Placa obrigatória')] : [validateLicense]}
        />
    );
};
