import React from 'react';
import { Datagrid, DateField, ReferenceField, TextField, Show, SimpleShowLayout, Filter, TextInput, ReferenceInput, SelectInput, EmailField } from 'react-admin';
import { List } from '../components';

const SupportTicketFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    <ReferenceInput source="supportSubjectId" reference="support-subjects" alwaysOn>
      <SelectInput label="Assunto" resettable={true} optionText="name" />
    </ReferenceInput>
  </Filter>
);

const SupportTicketPanel = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="message" label="Mensagem" />
    </SimpleShowLayout>
  </Show>
);

export const SupportTicketDatagrid = (props) => (
  <Datagrid expand={<SupportTicketPanel />} {...props}>
    <ReferenceField
      label="Cliente"
      reference="customers"
      source="customerId"
      link='show'
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Contato"
      reference="customers"
      source="customerId"
      linkType={false}
    >
      <EmailField source="email" />
    </ReferenceField>
    <ReferenceField
      label="Assunto"
      reference="support-subjects"
      source="supportSubjectId"
      linkType={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <DateField source="createdAt" label="Data do chamado" locales="pt-BR" showTime />
  </Datagrid>
);

export const SupportTicketList = (props) => (
  <List {...props} title="Chamado de suporte" filters={<SupportTicketFilter />} bulkActionButtons={false} sort={{ field: "createdAt", order: "DESC" }}>
    <SupportTicketDatagrid />
  </List >
);