import { useContext } from 'react';
import { SocketContext } from '../context/socket';

export const useSocket = () => {
  const { socket } = useContext(SocketContext);

  let socketListeners: string[] = [];

  const subscribeListener = (namespace: string, callback: (props: any) => void) => {
    socket.on(namespace, callback);
    socketListeners.push(namespace);
  }

  const unsubscribeListener = (namespace: string) => {
    socket.removeListener(namespace);
    socketListeners.splice(socketListeners.indexOf(namespace), 1);
  }

  const unsubscribeAllListeners = () => {
    socketListeners.forEach(namespace => {
      socket.removeAllListeners(namespace);
    });
    socketListeners = [];
  }

  const emit = (namespace: string, data: any) => {
    socket.emit(namespace, data);
  }

  return { socket, emit, subscribeListener, unsubscribeListener, unsubscribeAllListeners };
}