import { ThumbUpAlt } from '@mui/icons-material';
import React, { useState } from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { ContractType } from '../../models';

const ApproveContractButton: React.FC = (props) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    dataProvider.update('contracts/authorize', {
      id: record.id,
      data: { status: ContractType.accepted },
      previousData: record,
    })
      .then(() => {
        notify(`Contrado foi aprovado com sucesso`);
        refresh();
      })
      .catch((e) => {
        notify(e.message || 'Não foi possível aprovar contrato', { type: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Button
      {...props}
      disabled={isLoading}
      label="Aprovar contrato"
      onClick={handleClick}
      startIcon={<ThumbUpAlt />}
    />
  );
}

export default ApproveContractButton;