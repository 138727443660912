import React from 'react'
import CardContent from '@mui/material/CardContent'
import { Paper } from '@mui/material'

const divStyle = {
  padding: '20px 20px 20px 20px',
}

export const Wrapper = props => (
  <CardContent>
    <Paper>
      <div style={divStyle}>{props.children}</div>
    </Paper>
  </CardContent>
)

export const RowFilter = props => {
  return (
    <div style={{
      alignSelf: 'center',
      alignItems: 'flex-end',
      display: 'flex',
      ...props.style,
    }}>{
        React.Children.map(props.children, (child) => {
          return child && (
            <div style={{ marginRight: '20px' }}>
              {child}
            </div>
          );
        })}</div>
  );
}