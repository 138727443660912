import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  ListButton,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  required,
} from "react-admin";
import { List, Permissions } from "../components";

import { makeStyles } from '@mui/styles';
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from "../constants";

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
});

const CustomActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const ServiceList = props => {
  const classes = listStyles();

  return (
    <List {...props}
      title="Serviços"
      filters={<SearchFilter />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <TextField source="description" label="Descrição" />
        <ImageField className={classes.image} source="imagePath" label="Imagem" />
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <EditButton />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <DeleteButton />
        </Permissions>
      </Datagrid>
    </List>
  );
}

const ServiceEdit = props => (
  <Edit {...props} actions={<CustomActions />} title='Editar serviço'>
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="description" label="Descrição" />
      <ImageInput source="imagefile" label="Imagem" accept="image/*">
        <ImageField source="src" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

const ServiceCreate = props => (
  <Create {...props} actions={<CustomActions />} title='Adicionar serviço' >
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="description" label="Descrição" />
      <ImageInput source="imagefile" label="Imagem" accept="image/*">
        <ImageField source="src" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

const resource = {
  edit: ServiceEdit,
  list: ServiceList,
  create: ServiceCreate,
  name: 'services',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;