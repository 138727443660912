export enum StatusEnum {
  idle = 'idle',
  discountInfo = 'discountInfo',
  pendingReserve = 'pendingReserve',
  reserved = 'reserved',
  pendingAuthorization = 'pendingAuthorization',
  authorized = 'authorized',
  waiting = 'waiting',
  delivering = 'delivering',
  finished = 'finished',
  error = 'error',
};