import React from 'react'
import {
  Create,
  TextInput,
  required,
  SimpleForm,
} from 'react-admin'

const create = props => (
  <Create {...props} title="Cadastrar novo grupo de permissões">
    <SimpleForm>
      <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
    </SimpleForm>
  </Create>
)
export default create;