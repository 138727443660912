export enum PaymentOrigin {
  ticketLog = 'ticket-log',
};

export namespace PaymentOrigin {
  export const translateOrigin = (type: PaymentOrigin): string => {
    switch (type) {
      case PaymentOrigin.ticketLog:
        return "Ticket Log";
      default: return "";
    };
  };

  export const paymentOriginChoices: Array<{ id: string, name: string }> = Object.values(PaymentOrigin)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: PaymentOrigin.translateOrigin(type as PaymentOrigin),
    } as { id: string, name: string }));
};