import { useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  minValue,
  number,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { Wrapper } from '../components/PageComponents';
import { PERMISSIONS } from '../constants';

const VALUE_VALIDATION = [number(), minValue(1)]

const Rule = () => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const patchRules = data => {
    setLoading(true);
    dataProvider
      .CREATE(`rules`, { data })
      .then(() => notify('Regras salvas com sucesso'))
      .catch(() => notify('Erro ao salvar regras', { type: 'warning' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    dataProvider.getList(`rules`, { sort: { field: 'label', order: 'ASC' } })
      .then(({ data }) => setInitialValues({ rules: data }))
      .catch((err) => notify('Erro ao buscar regras', { type: 'warning' }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Wrapper>
      <SimpleForm onSubmit={patchRules} saving={loading} defaultValues={initialValues}>
        <ArrayInput source="rules" label="">
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                getSource && getSource('');
                return (
                  <>
                    <TextField
                      source={"label"}
                      record={scopedFormData}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <BooleanInput label="Habilitar" source={"isActive"} />
            <NumberInput
              source="value"
              label="Valor"
              validate={VALUE_VALIDATION}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Wrapper>
  );
};

const resource = {
  list: Rule,
  name: 'rules',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;