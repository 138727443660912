import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { FunctionField, TextFieldProps } from 'ra-ui-materialui';
import React, { useCallback } from 'react';

interface CnpjFieldProps extends TextFieldProps {
  source: string;
  isHidden: boolean;
}

const CnpjField: React.FC<CnpjFieldProps> = (props) => {

  const {
    source,
    label,
    isHidden,
    emptyText,
  } = props;

  const formatValue = useCallback((cnpj: string) => {
    const cnpjFormated = formatCNPJ(cnpj, { pad: true })
    return isHidden ? '**.***' + cnpjFormated.slice(6) : cnpjFormated;
  }, [isHidden]);

  return (
    <FunctionField
      label={label}
      render={(record: any) => record && record[source] ? formatValue(record[source]) : (emptyText || '')}
      noWrap
    />
  );
}

export default CnpjField;