import {
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useGetIdentity,
  usePermissions,
} from "react-admin";
import { List } from "../components";
import { ImportCSVButton } from "../components/ImportCSVButton";
import { PERMISSIONS } from "../constants";
import { UserRole } from "../providers/authProvider";
import { getChainId } from "../utils/utils";

const ListActions = props => {
  const {
    className,
    basePath,
  } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportCSVButton {...props} />
    </TopToolbar>
  );
};

const AttendantFilter = props => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <TextInput label="Pesquisar" source="search" alwaysOn />
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput source="chainId" reference="chains" alwaysOn>
          <SelectInput label="Rede" resettable={true} optionText="name" />
        </ReferenceInput>}
    </Filter>
  );
};

const AttendantList = props => {
  const { identity } = useGetIdentity();
  return (
    <List {...props}
      title="Frentistas"
      filters={<AttendantFilter />}
      actions={<ListActions />}
      filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <TextField source="identfid" label="Identfid" />
        <ReferenceField label="Posto" source="stationId" reference="stations">
          <TextField source="name" />
        </ReferenceField>
        {identity && [UserRole.admin, UserRole.chain].includes(identity.role) && <EditButton />}
        {identity && [UserRole.admin, UserRole.chain].includes(identity.role) && <DeleteButton />}
      </Datagrid>
    </List>
  );
};

const AttendantEdit = props => {
  const { identity } = useGetIdentity();
  return (
    <Edit title='Editar frentista' {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <TextInput source="identfid" label="Identificador" isRequired />
        <ReferenceInput
          source="stationId"
          reference="stations"
          filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
          sort={{ field: "name", order: "ASC" }}>
          <SelectInput label="Posto" optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const AttendantCreate = props => {
  const { identity } = useGetIdentity();
  return (
    <Create title='Adicionar frentista' {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
        <TextInput source="identfid" label="Identificador" isRequired />
        <ReferenceInput
          source="stationId"
          reference="stations"
          filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
          sort={{ field: "name", order: "ASC" }}>
          <SelectInput label="Posto" optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const resource = {
  list: AttendantList,
  edit: AttendantEdit,
  create: AttendantCreate,
  name: 'station-attendants',
  permissions: [PERMISSIONS.LIST_ATTENDANTS]
};

export default resource;