import { makeStyles } from "@mui/styles";
import {
  ArrayInput,
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ExportButton,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Pagination,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleFormIterator,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  minLength,
  required,
  usePermissions,
  useRedirect
} from "react-admin";
import { List, Permissions } from "../components";
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from "../constants";
import { CPFInput } from "../utils/Inputs";
import { formatCpf } from "../utils/utils";
import { FillinDatagrid } from "./Fillin";
import { UserRole } from "../providers/authProvider";

const useStyles = makeStyles({
  arrayInput: {
    '& p': {
      color: 'grey',
      'margin-right': '1em',
      'font-weight': 'bold',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '10px 0 0 0'
  }
});

const AggregatedListActions = () => (
  <TopToolbar>
    <CreateButton resource="aggregated-drivers" />
    <ExportButton />
  </TopToolbar>
);

const AggregatedShowActions = () => (
  <TopToolbar>
    <EditButton resource="aggregated-drivers" />
  </TopToolbar>
);

const AggregatedEditActions = () => (
  <TopToolbar>
    <ShowButton resource="aggregated-drivers" />
  </TopToolbar>
);

const validatePlate = (message = 'Placa inválida') =>
  value => !value || value.length === 7 ? undefined : message;

const AggregatedDriverList = props => {
  const redirect = useRedirect();

  return (
    <List {...props}
      title="Motoristas Agregados"
      filters={<SearchFilter />}
      filter={localStorage.getItem('companyId') ? { companyId: localStorage.getItem('companyId'), isAggregated: true } : { isAggregated: true }}
      actions={<AggregatedListActions />}
      resource="drivers"
      empty={false}
    >
      <Datagrid rowClick={(id, _resource, _record) => redirect("show", "aggregated-drivers", id)} bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
        <EditButton resource="aggregated-drivers" />
        <DeleteButton redirect={() => redirect("list", "aggregated-drivers")} />
      </Datagrid>
    </List>
  );
};

const AggregatedDriverEdit = props => {
  const redirect = useRedirect();
  const transform = data => {
    delete data.subCompany;
    delete data.company;
    return data;
  }
  const classes = useStyles();

  return (
    <Edit
      title='Editar motorista agregado'
      transform={transform} {...props}
      resource="drivers" redirect={() => redirect("list", "aggregated-drivers")}
      actions={<AggregatedEditActions />}
    >
      <TabbedForm defaultValues={{ companyId: localStorage.getItem('companyId') }}>
        <FormTab label="Identificação">
          <Permissions userRoles={[UserRole.admin]}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Grupo de Transportadora" optionText="name" />
            </ReferenceInput>
          </Permissions>
          <Permissions userRoles={[UserRole.admin, UserRole.company]}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  label="Transportadora"
                  source="subCompanyId"
                  reference="sub-companies"
                  filter={{ companyId: formData.companyId }}
                  sort={{ field: "name", order: "ASC" }}>
                  <SelectInput label="Transportadora" optionText="name" validate={required()} />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Permissions>
          <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
          <CPFInput source="cpf" label="CPF" isRequired />
          <PasswordInput source="password" label="Senha" validate={[minLength(6)]} />
        </FormTab>
        <FormTab label="Veículos">
          <ArrayInput
            label="Veículos"
            source="aggregatedVehicles"
            className={classes.arrayInput}
          >
            <SimpleFormIterator disableReordering>
              <FormDataConsumer>
                {({ formData, getSource, scopedFormData }) => {
                  return getSource && (
                    <>
                      <div className={classes.row}>
                        {formData.aggregatedVehicles && (
                          <>
                            <TextInput source={getSource("name")} label="Nome" style={{ marginRight: 25 }} disabled={scopedFormData && scopedFormData.id} />
                            <TextInput source={getSource("licensePlate")} label="Placa" disabled={scopedFormData && scopedFormData.id} />
                          </>
                        )}
                      </div>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit >
  );
}

const AggregatedDriverCreate = props => {
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <Create title='Adicionar motorista agregado' {...props} resource="drivers" redirect={() => redirect("list", "aggregated-drivers")}>
      <TabbedForm defaultValues={{ companyId: localStorage.getItem('companyId'), isAggregated: true }}>
        <FormTab label="Identificação">
          <Permissions userRoles={[UserRole.admin]}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Grupo de Transportadora" optionText="name" />
            </ReferenceInput>
          </Permissions>
          <Permissions userRoles={[UserRole.admin, UserRole.company]}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  label="Transportadora"
                  source="subCompanyId"
                  reference="sub-companies"
                  filter={{ companyId: formData.companyId }}
                  sort={{ field: "name", order: "ASC" }}>
                  <SelectInput label="Transportadora" optionText="name" validate={required()} />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Permissions>
          <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
          <CPFInput source="cpf" label="CPF" isRequired />
          <PasswordInput source="password" label="Senha" validate={[required('Senha é obrigatória'), minLength(6)]} />
        </FormTab>
        <FormTab label="Veículos">
          <ArrayInput
            label="Veículos"
            source="aggregatedVehicles"
            className={classes.arrayInput}
          >
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  return getSource && (
                    <>
                      <div className={classes.row}>
                        {formData.aggregatedVehicles && (
                          <>
                            <TextInput source={getSource("name")} label="Nome" style={{ marginRight: 25 }} />
                            <TextInput source={getSource("licensePlate")} label="Placa" validate={[required(), validatePlate()]} />
                          </>
                        )}
                      </div>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create >
  );
}
const AggregatedDriverShow = props => {
  const { permissions } = usePermissions();
  return (
    <Show
      {...props}
      title="Motorista Agregado"
      actions={<AggregatedShowActions />}
      resource="drivers"
    >
      <TabbedShowLayout>
        <Tab label="Resumo">
          <TextField source="name" label="Nome" />
          <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceField label="Transportadora" source="companyId" reference="companies">
              <TextField source="name" label="Transportadora" />
            </ReferenceField>
          }
        </Tab>
        <Tab label="Abastecimentos">
          <ReferenceManyField
            reference="fillins"
            target="driverId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <FillinDatagrid permissions={permissions} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const resource = {
  edit: AggregatedDriverEdit,
  list: AggregatedDriverList,
  create: AggregatedDriverCreate,
  show: AggregatedDriverShow,
  name: 'aggregated-drivers',
  permissions: [PERMISSIONS.LIST_AGGREGATED_DRIVES]
};

export default resource;