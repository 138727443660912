import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

const choices = [
  {
    value: 'chain',
    label: "Rede",
  },
  {
    value: 'company',
    label: "Transportadora",
  },
];

const PermissionCreate = props => (
  <Create {...props} title="Cadastrar nova permissão">
    <SimpleForm>
      <TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} validate={required()} />
      <TextInput source="label" label="Descrição" InputProps={{ autoComplete: 'off' }} validate={required()} />
      <ReferenceInput
        source="groupId"
        reference="permission-groups"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={null}
        allowEmpty>
        <SelectInput optionText="name" label="Grupo" />
      </ReferenceInput>
      <SelectInput
        choices={choices}
        source="type"
        label="Tipo"
        optionValue="value"
        optionText="label"
      />
    </SimpleForm>
  </Create>
);

export default PermissionCreate;