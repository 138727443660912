import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ReferenceManyField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { Link } from 'react-router-dom';
import { List } from '../components';
import { PERMISSIONS } from '../constants';

const AddNewButton = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      variant="raised"
      to={`/fuels/create?parentFuelId=${record.id}&acronym=${record.acronym}`}
      label="Adicionar combustível filho"
      title="Adicionar combustível filho"
    >
      <AddIcon />
    </Button>
  );
}

const FuelsList = props => {
  const { permissions } = usePermissions();
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=' '
    >
      <SimpleShowLayout>
        <ReferenceManyField reference="fuels" target="parentFuelId" addLabel={false}>
          <Datagrid rowClick="show">
            <TextField source="acronym" label="Sigla" />
            <TextField source="name" label="Nome" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        {permissions && permissions.includes(PERMISSIONS.ADMIN) && <AddNewButton />}
      </SimpleShowLayout>
    </Show>
  );
};

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const ParentFuelFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
);

const ParentFuelList = (props) => (
  <List {...props} title="Combustíveis" filters={<ParentFuelFilter />} bulkActionButtons={false}>
    <Datagrid expand={<FuelsList />}>
      <TextField source="acronym" label="Sigla" />
      <TextField source="name" label="Nome" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List >
);

const ParentFuelEdit = (props) => (
  <Edit title='Editar combustível' {...props}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="acronym" label="Sigla" maxlength="3" />
      <TextInput source="name" label="Nome" />
    </SimpleForm>
  </Edit>
);

const ParentFuelCreate = (props) => (
  <Create title='Adicionar combustível' {...props}>
    <SimpleForm>
      <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
      <TextInput source="name" label="Nome" validate={required()} />
    </SimpleForm>
  </Create>
);

const resource = {
  list: ParentFuelList,
  edit: ParentFuelEdit,
  create: ParentFuelCreate,
  name: 'parent-fuels',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;