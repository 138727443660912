import React, { Fragment, useState } from 'react';
import { Button } from 'react-admin';
import ThumbUp from '@mui/icons-material/ThumbUp';
import { FillinAttemptStatus } from '../../models';
import { ApproveOrDenyDialog } from '..';

const ApproveButton = ({ record, ...props }) => {
  const [showDialog, setShowDialog] = useState(false);

  const labelbutton = `${props.label}`;

  const handleClick = (e) => {
    e.stopPropagation();
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <Fragment>
      {
        record.status !== 'approved' &&
        <>
          <ApproveOrDenyDialog open={showDialog} handleClose={closeDialog} status={FillinAttemptStatus.approved} />
          <Button {...props} type="button" label={labelbutton} onClick={handleClick} startIcon={<ThumbUp />} />
        </>
      }
    </Fragment>
  );
}

export default ApproveButton;