import { Box, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import {
	BooleanInput,
	CheckboxGroupInput,
	Edit,
	FormDataConsumer,
	Labeled,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	required,
	useChoicesContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId, getCompanyId } from '../../utils/utils';

const useStyles = makeStyles(() => ({
	nestedPermission: {
		width: 250,
		marginLeft: 30,
	},
	checkbox: {
		width: '100%',
	},
}));

const ProfileEdit = (props) => {
	const transform = data => ({
		...data,
		permissions: data.fullAccess ? [] : data.permissions.map(p => typeof p === 'object' ? p.id : p)
	});

	return (
		<Permission permission={PERMISSIONS.LIST_PROFILES}>
			<Edit {...props} transform={transform} title="Editar usuário">
				<SimpleForm>
					<Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
						<Grid item xs={12} md={8}>
							<Permission permission={PERMISSIONS.ADMIN}>
								<ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
									<SelectInput optionText="name" label="Transportadora" isRequired fullWidth />
								</ReferenceInput>
							</Permission>
							<Permission permission={PERMISSIONS.ADMIN}>
								<ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
									<SelectInput optionText="name" label="Rede" isRequired fullWidth />
								</ReferenceInput>
							</Permission>
							<Box display={{ xs: 'block', sm: 'flex' }}>
								<TextInput source="name" label="Nome" InputProps={{ autoComplete: 'off' }} isRequired fullWidth validate={required()} />
							</Box>
							<Box display={{ xs: 'block', sm: 'flex' }}>
								<BooleanInput source="fullAccess" label="Todas as permissões" />
							</Box>
							<Box display={{ xs: 'block', sm: 'flex' }}>
								<FormDataConsumer>
									{({ formData }) => !formData.fullAccess &&
										<Labeled label="Permissões">
											<ReferenceArrayInput
												sort={{ field: 'name', order: 'ASC' }}
												perPage={null}
												label=""
												source="permissions"
												reference="permission-groups"
												filter={getCompanyId() ? { type: 'company' } : getChainId() ? { type: 'chain' } : {}}
											>
												<MyCheckboxGroupInput />
											</ReferenceArrayInput>
										</Labeled>
									}
								</FormDataConsumer>
							</Box>
						</Grid>
					</Grid>
				</SimpleForm>
			</Edit>
		</Permission >
	)
}

const MyCheckboxGroupInput = props => {
	const classes = useStyles();
	const choices = useChoicesContext();
	const groups = choices && choices.allChoices;

	if (groups) {
		return groups.map(group => {
			if (group.permissions.length > 0) {
				const permissions = group.permissions
					.sort((a, b) => { return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0; });
				return (
					<>
						<SelectAllBtn group={group} />
						<CheckboxGroupInput
							className={classes.nestedPermission}
							source={`permissions`}
							choices={permissions}
							optionText="label"
							row={false}
							format={permissions => {
								return permissions ? permissions.map(p => typeof p === 'object' ? p.id : p) : []
							}}
							label=""
						/>
					</>
				)
			}
			return <div />
		})
	}

	return <div />
};

const SelectAllBtn = props => {
	const { group } = props
	const form = useFormContext();
	const classes = useStyles();
	const permissions = form.getValues("permissions");
	let allAllowed = false;

	if (permissions) {
		const allowedPermissions = permissions.map(p => typeof p === 'object' ? p.id : p);
		allAllowed = group.permissions.map(p => p.id).every(id => allowedPermissions.includes(id));
	}

	return (
		<FormControlLabel
			className={classes.checkbox}
			htmlFor={group.id}
			key={group.id}
			onChange={event => {
				const checked = event.target.checked;
				const previousPermissions = (form.getValues("permissions") || []).map(p => typeof p === 'object' ? p.id : p);

				group.permissions.forEach(p => {
					const index = previousPermissions.indexOf(p.id);
					const contains = index > -1;

					if (checked && !contains) {
						previousPermissions.push(p.id);
					} else if (!checked && contains) {
						previousPermissions.splice(index, 1);
					}
				});

				form.setValue('permissions', previousPermissions)
			}}
			control={
				<Checkbox
					id={group.id}
					color="primary"
					checked={allAllowed}
				/>
			}
			label={group.name}
		/>
	)
}
export default ProfileEdit;