export enum FillinAttemptStatus {
  pending = 'pending',
  approved = 'approved',
  denied = 'denied',
};

export const FillinAttemptStatusChoices = [
  { id: FillinAttemptStatus.approved, name: 'Aprovado' },
  { id: FillinAttemptStatus.pending, name: 'Pendente' },
  { id: FillinAttemptStatus.denied, name: 'Negado' },
]
