import { Card, Typography, Box } from '@mui/material';
import React, { useRef, cloneElement } from 'react';
import { TopToolbar, ListBase, Title, ListToolbar, Pagination, ExportButton as RaExportButton } from 'react-admin';
import { CustomExportButton, ExportButton, PrintButton, ReportPrint } from '..';

const ReportList = ({ children, actions, bulkActionButtons, filters, title, pagination, exporter, exporterXLSX = false, disablePrint, landscape, titleOnPrint, filterTablePrint, showPagination, customExporter, hasSummary, fileName, ...props }) => {
  const reportRef = useRef();

  const PostShowActions = () => (
    <TopToolbar>
      {(exporter && exporterXLSX) &&
        <ExportButton resource={props.resource} exporter={exporter || undefined} />
      }
      {customExporter &&
        <CustomExportButton preExport={customExporter} hasSummary={hasSummary} fileName={fileName} />
      }
      {(exporter && !exporterXLSX) &&
        <RaExportButton resource={props.resource} exporter={exporter || undefined} />
      }
      {!disablePrint &&
        <PrintButton printRef={reportRef} sx={{}} />
      }
    </TopToolbar>
  );

  return (
    <ListBase disableSyncWithLocation {...props}>
      <Title title={title} />
      <ListToolbar
        filters={filters}
        actions={<PostShowActions />}
      />
      <ReportPrint ref={reportRef} title={titleOnPrint || title} landscape={landscape || false}>
        {filterTablePrint &&
          <Box sx={{
            display: 'none',
            '@media print': {
              display: 'block'
            },
            marginBottom: 10,
          }}>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Filtros</Typography>
            <Card>
              {filterTablePrint}
            </Card>
          </Box>
        }
        <Card>
          {
            cloneElement(children)
          }
          {
            showPagination !== false &&
            <Box sx={{ '@media print': { display: 'none' } }}>
              {pagination ? pagination : <Pagination />}
            </Box>
          }
        </Card>
      </ReportPrint>
    </ListBase>
  )
};

export default ReportList;