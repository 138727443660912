import { Button, DateField, NumberField, ReferenceField, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField, useNotify, usePermissions, useRecordContext } from "react-admin"
import { FillinDatagrid, ScreenType } from "../Fillin"
import { saveAs } from 'file-saver';

const DownloadFile = () => {
  const record = useRecordContext();
  const notify = useNotify();

  if (!record) { return null; }

  const handleDownloadXml = (url: string) => {
    try {
      saveAs(url, url.split('/').slice(-1));
    } catch (error) {
      notify(error.message || `Erro ao baixar XML da nota fiscal`, { type: 'warning' });
    }
  }

  return (
    <Button
      sx={{ maxWidth: '100px' }}
      label="Baixar XML"
      onClick={(e) => {
        e.stopPropagation();
        handleDownloadXml(record.path);
      }}
    />
  );
};

const InvoiceShow = () => {
  const permissions = usePermissions();
  return (
    <Show title="Nota Fiscal">
      <TabbedShowLayout>
        <Tab label="Identificação">
          <TextField source="invoiceNumber" label="Número" />
          <NumberField source="totalInvoiceValue" label="Valor da nota" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
          <ReferenceField source="billId" reference="bills" label="Fatura" link={"show"} emptyText="--">
            <TextField source="numericId" />
          </ReferenceField>
          <DateField source="createdAt" label="Data de criação" />
          <DownloadFile />
        </Tab>
        <Tab label="Abastecimentos" >
          <ReferenceManyField reference="fillins" target="invoiceId" label={false}>
            <FillinDatagrid screen={ScreenType.billShow} permissions={permissions} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default InvoiceShow;