import React, { MouseEventHandler, useCallback } from 'react';
import { Button, ButtonProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface WizardButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (value: any) => void,
}

const WizardButton: React.FC<WizardButtonProps> = (props) => {

  const {
    color = 'primary',
    onClick,
    variant = 'contained',
    ...rest
  } = props;

  const form = useFormContext();

  const handleSubmit = useCallback(
    async values => {
      if (onClick) {
        onClick(values);
      }
    },
    [onClick]
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async event => {
      if (event.defaultPrevented) {
        return;
      }

      event.stopPropagation();
      await form.handleSubmit(handleSubmit)(event);
    },
    [form, handleSubmit]
  );

  return (
    <Button
      {...rest}
      variant={variant}
      color={color}
      onClick={handleClick}
    />
  );
}

export default WizardButton;