export enum BillStatus {
  pending = 'pending',
  paid = 'paid',
};

export namespace Bill {
  export const transalateStatus = (status: BillStatus): string => {
    switch (status) {
      case BillStatus.pending:
        return "Pendente"
      case BillStatus.paid:
        return "Pago";
      default: return "";
    };
  };
};

export const BillStatusChoices = [
  {
    label: Bill.transalateStatus(BillStatus.paid),
    value: BillStatus.paid,
  },
  {
    label: Bill.transalateStatus(BillStatus.pending),
    value: BillStatus.pending,
  },
];