import { Avatar, Grid, IconButton, InputAdornment, Typography, TextField, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import {
  AttachFile as AttachFileIcon,
  UploadFile as UploadFileIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { FileData } from '../../models';

interface ChatInputProps {
  source: string;
  sourceFiles?: string | null;
  validate?: any[];
  onClickSendButton?: (value: string | undefined, files?: FileData[]) => void;
  [key: string]: any;
}

const ChatInput: React.FC<ChatInputProps> = ({ onClickSendButton, sourceFiles, ...props }) => {

  const bodyInput = useInput({ ...props });
  const fileInput = useInput({ source: sourceFiles || 'files' });
  const { resetField } = useFormContext();
  const inputRef = useRef<any>(null);

  const [files, setFiles] = React.useState<FileData[]>([]);

  const clear = () => {
    resetField(props.source);
    resetField(props.sourceFiles || 'files');
    setFiles([]);
  };

  const handleChangeFile = (files: FileList | null) => {
    if (files && files.length) {

      const filesData: FileData[] = [];
      for (const file of files) {
        const fileItem: FileData = { rawFile: file };
        if (file.type.includes('image')) {
          fileItem["url"] = URL.createObjectURL(file);
        }

        filesData.push(fileItem);
      }
      setFiles((val) => ([...val, ...filesData]));
      fileInput.field.onChange([...(fileInput.field.value || []), ...filesData]);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (onClickSendButton) {
        onClickSendButton(bodyInput.field.value, fileInput.field.value);
        clear();
        inputRef.current.blur();
        setTimeout(() => {
          inputRef.current.focus();
        }, 200);
      }
    }
  };

  return (
    <>
      <TextField
        {...bodyInput.field}
        {...props}
        error={(bodyInput.fieldState.isTouched || bodyInput.formState.isSubmitted) && bodyInput.fieldState.invalid}
        helperText={(bodyInput.fieldState.isTouched || bodyInput.formState.isSubmitted) && bodyInput.fieldState.invalid ? bodyInput.fieldState.error?.message : ''}
        fullWidth
        placeholder="Digite aqui sua mensagem"
        size="small"
        multiline
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        sx={{
          '& textarea': {
            paddingBottom: '10px',
            paddingTop: '5px',
          },
          '& .MuiInputBase-root': {
            paddingTop: '10px',
          },
        }}
        InputProps={{
          endAdornment: <>
            <InputAdornment position="end">
              <label htmlFor="upload-file">
                <input
                  style={{ display: "none" }}
                  id="upload-file"
                  name="upload-file"
                  type="file"
                  onChange={(event) => handleChangeFile(event.target.files)}
                  multiple
                />
                <IconButton
                  onClick={() => { }}
                  edge="end"
                  component="span"
                >
                  <AttachFileIcon />
                </IconButton>
              </label>
            </InputAdornment>
            {
              onClickSendButton &&
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    onClickSendButton(bodyInput.field.value, files);
                    clear();
                  }}
                  edge="end"
                  component="span"
                  color="primary"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          </>
        }}
      />

      {
        (fileInput.field.value && fileInput.field.value.length > 0) &&
        <>
          <Typography variant="subtitle2" sx={{ marginTop: '15px', marginBottom: '5px' }}>Arquivos ({fileInput.field.value.length})</Typography>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {
              fileInput.field.value.map((fileData, index) => (
                <Grid item xs={2} sm={4} md={2} key={index}>
                  <Tooltip title={fileData.rawFile.name} arrow>
                    {
                      fileData.url ?
                        <Avatar
                          src={fileData.url as string}
                          variant="rounded"
                          sx={{ width: 56, height: 56 }} />
                        :
                        <Avatar
                          variant="rounded"
                          sx={{ width: 56, height: 56 }}>
                          <UploadFileIcon />
                        </Avatar>
                    }
                  </Tooltip>
                </Grid>
              ))
            }
          </Grid>
        </>
      }
    </>
  );
}

export default ChatInput;