import React from 'react';
import { Create, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'ra-ui-materialui';
import ChatInput from '../../components/ChatInput';
import { required } from 'react-admin';
import { getCompanyId, getUserId } from '../../utils/utils';
import { Send as SendIcon } from '@mui/icons-material';
import { ChatOriginChoices } from '../../models/chatOrigin';

const choicesOrigin = [
  {
    value: 'contract',
    label: "Contrato",
  },
  {
    value: 'invoice',
    label: "Nota fiscal",
  },
  {
    value: 'Bill',
    label: "Nota fiscal",
  },
];

const ChatCreate: React.FC = (props) => {

  const ChatToolbar = (props) => (
    <Toolbar {...props} >
      <SaveButton label="Enviar mensagem" icon={<SendIcon />} />
    </Toolbar>
  );

  return (
    <Create
      {...props}
      title="Enviar mensagem"
      transform={(data) => {
        const { body, origin, ...restData } = data;
        return {
          ...restData,
          ...(origin ? { origin } : {}),
          employeeId: getUserId(),
          companyId: getCompanyId(),
          chatMessage: {
            body,
          },
        };
      }}
    >
      <SimpleForm toolbar={<ChatToolbar />}>
        <div style={{ maxWidth: 600 }}>
          <TextInput
            fullWidth
            source="subject"
            label="Assunto"
            validate={[required()]}
          />
          <SelectInput
            fullWidth
            choices={ChatOriginChoices}
            source="origin"
            label="Origem"
            optionValue="value"
            optionText="label"
          />
          <ReferenceInput source="chainId" reference="chains" filter={{ companyId: getCompanyId() }} >
            <SelectInput fullWidth optionText="name" label="Rede" validate={[required()]} />
          </ReferenceInput>
          <ChatInput
            source="body"
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </Create>
  );
}

export default ChatCreate;