import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  usePermissions
} from "react-admin";
import { List, StarRatingField } from '../components';
import { PERMISSIONS } from "../constants";

const RatingFilter = props => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      {permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceInput source="chainId" reference="chains" alwaysOn>
          <SelectInput label="Rede" resettable={true} optionText="name" />
        </ReferenceInput>}
    </Filter>
  );
};

export const RatingDatagrid = (props) => (
  <Datagrid bulkActionButtons={false} {...props}>
    <ReferenceField source="customerId" label="Usuário" reference="customers" link='show'>
      <TextField source="name" />
    </ReferenceField>
    <StarRatingField source="attendantRating" label="Avaliação do frentista" />
    <StarRatingField source="stationRating" label="Avaliação do Posto" />
    <DateField source='createdAt' label='Data' showTime />
    <ReferenceField label="Abastecimento" source="fillinId" reference="fillins" link='show' sortable={false}>
      <FunctionField render={() => 'Abastecimento'} />
    </ReferenceField>
  </Datagrid>
);

const RatingList = props => (
  <List
    {...props}
    exporter={false}
    title="Avaliações"
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<RatingFilter />}
  >
    <RatingDatagrid />
  </List>
);

const resource = {
  list: RatingList,
  name: 'ratings',
  permissions: [PERMISSIONS.LIST_RATNGS]
};

export default resource;