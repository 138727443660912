import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  useRecordContext
} from 'react-admin';
import { List } from '../components';
import Permission from '../components/Permission';
import { SearchFilter } from '../components/SearchFilter';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';

const CostCenterTitle = () => {
  const record = useRecordContext();
  if (!record) { return <></>; };
  return <span>{`${record.name}`}</span>;
};

const CostCenterShow = props => {
  return (
    <Show title={<CostCenterTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
        <Permission permission={PERMISSIONS.ADMIN} label="Grupo de Transportadora">
          <ReferenceField source="companyId" reference="companies" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        <ReferenceField label="Transportadora" source="subCompanyId" reference="sub-companies" emptyText="Não definido">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="identifier" label="Identificador" />
      </SimpleShowLayout>
    </Show>
  );
};

const CostCenterList = props => {
  return (
    <List
      {...props}
      title="Centros de custos"
      filters={<SearchFilter />}
      filter={getCompanyId() ? { companyId: getCompanyId() } : null}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="identifier" label="Identificador" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const CostCenterEdit = props => {
  return (
    <Edit title='Editar Centro de Custo' {...props}>
      <SimpleForm>
        <TextInput disabled label="ID" source="id" />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
            <SelectInput label="Grupo de Transportadora" optionText="name" emptyValue={undefined} emptyText="Nenhum" />
          </ReferenceInput>
        </Permission>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: getCompanyId() }}
          sort={{ field: "name", order: "ASC" }}>
          <SelectInput label="Transportadora" optionText="name" emptyValue={undefined} emptyText="Nenhum" validate={required()} />
        </ReferenceInput>
        <TextInput source="name" label="Nome" />
        <TextInput source="identifier" label="Identificador" />
      </SimpleForm>
    </Edit>
  );
}

const CostCenterCreate = props => {
  return (
    <Create title='Adicionar Centro de Custo' {...props}>
      <SimpleForm defaultValues={{ companyId: getCompanyId() }}>
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
            <SelectInput label="Grupo de Transportadora" optionText="name" />
          </ReferenceInput>
        </Permission>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: localStorage.getItem('companyId') }}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput label="Transportadora" optionText="name" validate={required()} />
        </ReferenceInput>
        <TextInput source="name" label="Nome" />
        <TextInput source="identifier" label="Identificador" />
      </SimpleForm>
    </Create>
  );
}

const resource = {
  list: CostCenterList,
  edit: CostCenterEdit,
  create: CostCenterCreate,
  show: CostCenterShow,
  name: 'cost-centers',
  permissions: [PERMISSIONS.LIST_COST_CENTERS]
}
export default resource;