import { FormControlLabel, Switch } from '@mui/material';
import React, { useState } from 'react';
import { useDataProvider, useNotify, useRecordContext, useRefresh, useResourceContext } from 'react-admin';
import { getCompanyId } from '../../../utils/utils';

interface SwitchPreApprovedContracts {
  label?: string;
  source: string;
}

const SwitchPreApprovedContracts: React.FC<SwitchPreApprovedContracts> = (props) => {

  const {
    source,
  } = props;

  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext();

  const [value, setValue] = useState<boolean>(record[source] || false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent, value: boolean) => {
    setValue(value);
    setIsLoading(true);

    dataProvider.update(resource, {
      id: record.chainId,
      data: { preApprovedContracts: value, companyId: getCompanyId() },
      previousData: {},
    })
      .then(() => {
        notify('Alteração realizada com sucesso', { type: 'info' });
        refresh();
      })
      .catch(() => {
        setValue(record[source]);
        notify('ra.notification.update_error', { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <FormControlLabel
      control={
        <Switch checked={value} onChange={handleChange} disabled={isLoading} />
      }
      label="Contratos pré-provados"
    />
  );
}

export default SwitchPreApprovedContracts;