import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  FunctionField,
  NumberField,
  Show,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  email,
  minLength,
  useRecordContext
} from 'react-admin';
import { List } from '../components';
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from '../constants';
import { CEPInput, CNPJInput, PhoneInput, StateInput } from '../utils/Inputs';
import { formatCellphone, formatCnpj } from '../utils/utils';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const CompanyList = (props) => (
  <List {...props} title="Grupo de Transportadoras" filters={<SearchFilter />} hasCreate>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Transportadora" />
      <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
      <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
      <DateField source="createdAt" label="Adicionado em" locales="pt-BR" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const CompanyEdit = (props) => (
  <Edit title='Editar Grupo de Transportadoras' {...props}>
    <TabbedForm>
      <FormTab label="Identificação">
        <TextField disabled label="Código" source="code" />
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="email" label="Email" type="email" validate={createCompanyEmailValidate} />
        <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
        <PhoneInput source="phone" label="Telefone" />
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" validate={required()} />
        <TextInput source="streetNumber" label="Número" validate={required()} />
        <TextInput source="neighbourhood" label="Bairro" validate={required()} />
        <TextInput source="city" label="Cidade" validate={required()} />
        <StateInput source="state" label="Estado" validate={required()} />
        <CEPInput source="cep" label="CEP" isRequired />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const createCompanyEmailValidate = [email(), required()];
const createStationPhoneValidate = [minLength(10), required()];

const CompanyCreate = (props) => (
  <Create title='Novo Grupo de Transportadora' {...props}>
    <TabbedForm>
      <FormTab label="Identificação">
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="email" label="Email" type="email" validate={createCompanyEmailValidate} />
        <CNPJInput source="cnpj" label="CNPJ" isRequired />
        <PhoneInput source="phone" label="Telefone" validate={createStationPhoneValidate} />
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" validate={required()} />
        <TextInput source="streetNumber" label="Número" validate={required()} />
        <TextInput source="neighbourhood" label="Bairro" validate={required()} />
        <TextInput source="city" label="Cidade" validate={required()} />
        <StateInput source="state" label="Estado" validate={required()} />
        <CEPInput source="cep" label="CEP" isRequired />
      </FormTab>
    </TabbedForm>
  </Create>
);

const CompanyName = () => {
  const record = useRecordContext();
  return <span>{record && record.name}</span>;
};

const CompanyShow = props => (
  <Show title={<CompanyName />} {...props}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <TextField disabled label="ID" source="id" />
        <TextField source="code" label="Código" />
        <TextField source="name" label="Nome" />
        <TextField label="Email" source="email" type="email" />
        <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
      </Tab>
      <Tab label="Endereço">
        <TextField source="street" label="Rua" />
        <TextField source="streetNumber" label="Número" />
        <TextField source="neighbourhood" label="Bairro" />
        <TextField source="city" label="Cidade" />
        <TextField source="state" label="Estado" />
        <NumberField source="cep" label="CEP" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = {
  list: CompanyList,
  edit: CompanyEdit,
  create: CompanyCreate,
  show: CompanyShow,
  name: 'companies',
  permissions: [PERMISSIONS.ADMIN]
}
export default resource;