import React, { Fragment } from 'react';
import { useRecordContext } from 'react-admin';
import ApproveButton from './approveButton';

const ApproveFillinButton = ({ ...props }) => {
  const record = useRecordContext(props);

  return (
    <Fragment>
      {
        record.status !== 'approved' &&
        <>
          <ApproveButton {...props} record={record} />
        </>
      }
    </Fragment>
  );
};

export default ApproveFillinButton;