import react, { useEffect, useState } from 'react';
import { useCreatePath } from 'react-admin';

export const useRowClick = () => {
  const [controlPressed, setControlPressed] = useState<boolean>(false);
  const createPath = useCreatePath();

  const handleKeyDown = (props: KeyboardEvent) => {
    if (props.key === 'Control') {
      setControlPressed(true);
    }
  }

  const handleKeyUp = (props: KeyboardEvent) => {
    if (props.key === 'Control') {
      setControlPressed(false);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
      document.removeEventListener('keyup', handleKeyUp, false);
    }
  }, []);


  const handleRowClick = (type: 'show' | 'edit' | null, id: string, resource: string, record: any) => {
    if (!type) { return null; }
    if (controlPressed) {
      window.open(`#${createPath({ resource, id, type })}`);
    } else {
      return type;
    }
  }

  return { handleRowClick };
}