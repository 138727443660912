export enum ContractProductMeasureType {
  inBulk = 'in-bulk',
  gallon = 'gallon',
};

export namespace ContractProductMeasureType {
  export const translateType = (type: ContractProductMeasureType): string => {
    switch (type) {
      case ContractProductMeasureType.inBulk:
        return "Granel"
      case ContractProductMeasureType.gallon:
        return "Galão";
      default: return "";
    };
  };

  export const contractProductMeasureTypeChoices: Array<{ id: string, name: string }> = Object.values(ContractProductMeasureType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: ContractProductMeasureType.translateType(type as ContractProductMeasureType),
    } as { id: string, name: string }));
};