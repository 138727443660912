import { parse } from 'query-string';
import { Create, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { PERMISSIONS } from '../constants';

const required = (message = 'Obrigatório') =>
  value => value || value === 0 ? undefined : message;

const FuelEdit = (props) => (
  <Edit title='Editar combustível filho' {...props}>
    <SimpleForm redirect={`/parent-fuels`}>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="acronym" label="Sigla" maxlength="3" />
      <TextInput source="name" label="Nome" />
      <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
      <ReferenceInput source="parentFuelId" reference="parent-fuels">
        <SelectInput label="Combustível pai" optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const FuelCreate = (props) => {
  const { parentFuelId, acronym } = parse(props.location.search);
  return (
    <Create title='Adicionar combustível' {...props}>
      <SimpleForm defaultValue={{ parentFuelId, acronym }} redirect={`/parent-fuels`}>
        <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
        <TextInput source="name" label="Nome" validate={required()} />
        <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
        <ReferenceInput source="parentFuelId" reference="parent-fuels">
          <SelectInput label="Combustível pai" optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};

const resource = {
  edit: FuelEdit,
  create: FuelCreate,
  name: 'fuels',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;
