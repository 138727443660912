import React from "react";
import { io, Socket } from "socket.io-client";

export interface socketContextProps {
  socket: Socket,
}

export const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5001';

export const socket = io(socketUrl, {
  upgrade: false,
  transports: ['websocket']
});

export const SocketContext = React.createContext<socketContextProps>({ socket });

export const SocketProvider = ({ children }) => (
  <SocketContext.Provider value={{ socket }}>
    {children}
  </SocketContext.Provider>
);