import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import {
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  useGetList,
  useListContext
} from 'react-admin';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { LazyLoadAutoCompleteInput, Permissions, ReportList } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { StateChoices } from '../../models';
import { UserRole } from '../../providers/authProvider';
import { StateInput } from '../../utils/Inputs';
import { formatCpf, formatNumber, getCompanyId } from "../../utils/utils";

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#E05C3F', '#9934E0', '#34E034', '#E0793F', '#17E0DC', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const PriceHistoryChart = ({ title, chartData, fuelIds, fuels, lineKeys, height, containerStyle }) => (
  <Box sx={containerStyle}>
    <Typography textAlign="center" gutterBottom>{title}</Typography>
    <ResponsiveContainer width="100%" height={height || 600}>
      <LineChart data={chartData}>
        <XAxis
          dataKey="date"
          tickFormatter={value => moment(value, 'DD/MM/YYYY').format('DD/MM')}
          label={{ value: 'Dia/Mês', position: 'insideBottom', offset: 0 }}
          height={50}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          domain={['auto', 'auto']}
          type='number'
          padding={{ bottom: 30 }}
          tickFormatter={value => formatNumber(value, { style: 'currency', currency: 'BRL', maximumFractionDigits: 1, minimumFractionDigits: 0 })}
        />
        <Tooltip
          formatter={(value) => {
            return formatNumber(value, { style: 'currency', currency: 'BRL' });
          }}
        />
        <Legend verticalAlign="top" height={50} />
        {
          fuelIds && fuelIds.map((fuelId, index) => (
            <Line
              connectNulls
              type='monotone'
              dataKey={`average_price_${fuelId}`}
              stroke={index <= 11 ? colors[index] : colors[((index + 1) % 12) - 1]}
              name={fuels.find(item => item.id === fuelId)?.name}
              activeDot={{ r: 5 }}
              key={fuelId}
            />
          ))
        }
        {
          lineKeys && lineKeys.map(({ key, label }, index) => (
            <Line
              connectNulls
              type='monotone'
              dataKey={key}
              stroke={index <= 11 ? colors[index] : colors[((index + 1) % 12) - 1]}
              name={label}
              activeDot={{ r: 5 }}
              key={key}
            />
          ))
        }
      </LineChart>
    </ResponsiveContainer>
  </Box>
);

const AverageFuelPriceCard = ({ averagePrice, fuelName }) => (
  <>
    <Card variant="outlined" sx={{ width: '100%', margin: '10px 0' }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: 14, marginBottom: 1 }} color="text.secondary">
          Preço Médio
        </Typography>
        <Typography variant="h6" gutterBottom>
          {formatNumber(averagePrice, { style: 'currency', currency: 'BRL' })}
        </Typography>
        <Typography color="text.secondary">
          {fuelName}
        </Typography>
      </CardContent>
    </Card>
  </>
);

const ReportMessage = ({ title }) => (
  <Box sx={{ padding: 2 }}>
    <Typography variant="subtitle1" textAlign="center">{title}</Typography>
  </Box>
);

const PriceChart = ({ fuels }) => {

  const props = useListContext();

  if (!props.data || !fuels) { return <></>; }

  if (props.data[0] && props.data[0].error) {
    return (<ReportMessage title={props.data[0].error} />);
  }

  const fuelIds = uniqBy(props.data[0]?.priceHistories.map(item => item.fuel_id));

  const averagePricePerFuels = props.data[0]?.averagePricePerFuel;

  const priceHistories = Object.entries(groupBy(props.data[0]?.priceHistories, 'date')).flatMap(([key, data]) => {
    return data.reduce((acc, item) => ({ ...acc, [`average_price_${item.fuel_id}`]: item.average_price }), { date: key });
  }, []);

  const anpPriceHistoriesPerFuel = groupBy(props.data[0]?.anpPriceHistoriesPerFuel || [], 'fuel_id');
  const contractPriceFuel = groupBy(props.data[0]?.averageContract || [], 'fuel_id');
  const priceHistoriesPerFuel = groupBy(props.data[0]?.priceHistories.map(priceHistory => {
    const anpPrice = anpPriceHistoriesPerFuel[priceHistory.fuel_id]?.find(anpP => anpP.collection_date === priceHistory.date);
    const contractPrice = contractPriceFuel[priceHistory.fuel_id]?.find((cp) => cp.created_at === priceHistory.date);

    return { ...priceHistory, anpPriceAverage: anpPrice ? anpPrice.average : null, contractAvarege: contractPrice ? contractPrice.avg : null };
  }), 'fuel_id');

  if (priceHistories.length === 0 && averagePricePerFuels.length === 0) {
    return (<ReportMessage title="Nenhum resultado encontrado" />);
  }

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} xl={2}>
          {
            averagePricePerFuels.map(({ average_price, fuel_id }) => (
              <AverageFuelPriceCard averagePrice={average_price} fuelName={fuels.find(f => f.id === fuel_id)?.name} />
            ))
          }
        </Grid>
        <Grid item xs={12} sm={6} md={8} xl={10}>
          <PriceHistoryChart title="Geral - Preço do Combustível" chartData={priceHistories} fuelIds={fuelIds} fuels={fuels} />
          <Typography fontSize={12}>*PM: Preço Médio</Typography>
          <Grid container spacing={2}>
            {
              Object.entries(priceHistoriesPerFuel).map(([key, data], index) => (
                <Grid item xs={12} sm={12} md={6}>
                  <PriceHistoryChart
                    chartData={data}
                    lineKeys={[{ key: 'average_price', label: 'PM Bomba' }, { key: 'anpPriceAverage', label: 'PM ANP' }, { key: 'contractAvarege', label: 'PM Contrato' }]}
                    title={`Preço Médio - ${fuels.find(f => f.id === key)?.name}`}
                    height={400}
                    containerStyle={{ marginTop: 5 }}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const FilterTablePrint = () => {
  const { filterValues } = useListContext();

  return (
    <Datagrid data={[{ ...filterValues, generatedReportAt: new Date() }]} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField label="Rede" emptyText="Todos" source="chainId" textAlign="center" basePath="chains" reference="chains" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Posto" emptyText="Todos" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Combustíveis" emptyText="Todos" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Motorista" emptyText="Todos" source="driverId" textAlign="center" basePath="drivers" reference="drivers" link={false}>
        <FunctionField render={record => `${formatCpf(record.cpf)} ${record.register ? '- ' + record.register : ''} - ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="Veículo" emptyText="Todos" source="vehicleId" textAlign="center" basePath="vehicles" reference="vehicles" link={false}>
        <TextField source="licensePlate" />
      </ReferenceField>
      <SelectField label="Estado" source="state" emptyText="Todos" choices={StateChoices} optionValue="value" optionText="label" />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const FiltersReportList = (props) => {
  const { filterValues } = useListContext();

  return (
    <Filter {...props}>
      <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />
      <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />
      <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
        <ReferenceInput
          source="companyId"
          reference="companies"
          perPage={false}
          sort={{ field: "name", order: "ASC" }}
          emptyText="Todos"
          isRequired
          alwaysOn
        >
          <SelectInput label="G. Transportadora" optionText={'name'} style={{ minWidth: 160 }} isRequired />
        </ReferenceInput>
      </Permission>
      <ReferenceInput
        source="chainId"
        reference="chains"
        perPage={false}
        sort={{ field: "name", order: "ASC" }}
        filter={{ companyId: filterValues.companyId || getCompanyId(), onlyWithContract: true }}
        emptyText="Todos"
        alwaysOn
      >
        <SelectInput label="Rede" optionText={'name'} style={{ minWidth: 160 }} />
      </ReferenceInput>
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        perPage={false}
        filter={{ chainId: filterValues.chainId }}
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        alwaysOn
      >
        <SelectArrayInput label="Posto" optionText={'name'} style={{ minWidth: 160, paddingBottom: 4, paddingTop: 8 }} disabled={!filterValues.chainId} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        perPage={false}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput label="Combustíveis" optionText={'name'} style={{ minWidth: 160, paddingBottom: 4, paddingTop: 8 }} disabled={!filterValues.chainId} />
      </ReferenceArrayInput>
      <LazyLoadAutoCompleteInput
        label="Motorista"
        reference="drivers"
        source="driverId"
        format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
        style={{ minWidth: 400 }}
        size={"small"}
        disabled={!filterValues.chainId}
        alwaysOn
      />
      <LazyLoadAutoCompleteInput
        label="Veículo"
        reference="vehicles"
        source="vehicleId"
        format={choice => choice.licensePlate}
        style={{ minWidth: 160 }}
        size={"small"}
        disabled={!filterValues.chainId}
        alwaysOn
      />
      <StateInput
        label="Estado"
        source="state"
        style={{ minWidth: 160 }}
        disabled={!filterValues.chainId}
        alwaysOn
      />
    </Filter>
  );
}

const PricesReport = (props) => {

  const { data: fuels } = useGetList('fuels');

  const filterValues = {
    companyId: getCompanyId(),
    from: moment().startOf('day').subtract(1, "month").toISOString(),
    to: moment().endOf('day').toISOString(),
  };

  return (
    <Permissions userRoles={[UserRole.admin, UserRole.company]}>
      <ReportList
        {...props}
        title="Relatório de Preços"
        resource="reports/prices"
        filters={<FiltersReportList />}
        filterDefaultValues={filterValues}
        showPagination={false}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Preços"
        filterTablePrint={<FilterTablePrint />}
        landscape={true}
      >
        <PriceChart fuels={fuels} />
      </ReportList>
    </Permissions>
  );
};

export default PricesReport;