import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  email,
} from 'react-admin';
import { List } from '../components';
import Permission from '../components/Permission';
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from '../constants';
import { CPFInput, PhoneInput } from '../utils/Inputs';
import { formatCellphone, formatCpf } from '../utils/utils';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const choices = [
  { id: 'driver', name: "Motorista" },
  { id: 'fleet', name: "Frota" },
  { id: 'both', name: "Geral" },
];

const ManagerList = (props) => (
  <List
    {...props}
    title="Gestores"
    filters={<SearchFilter />}
    filter={localStorage.getItem('companyId') ? { companyId: localStorage.getItem('companyId') } : null}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nome" />
      <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
      <ReferenceField source="subCompanyId" reference="sub-companies" label="Transportadora" emptyText="Não definido">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="registration" label="Matrícula" />
      <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
      <TextField source="email" type="email" />
      <FunctionField label="Tipo" render={record => record.type === 'driver' ? 'Motorista' : record.type === 'fleet' ? 'Frota' : 'Geral'} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const ManagerEdit = (props) => {
  return (
    <Edit title='Editar gestor' {...props}>
      <SimpleForm defaultValues={{ companyId: localStorage.getItem('companyId') }}>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: localStorage.getItem('companyId') }}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}>
          <SelectInput label="Transportadora" optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Nome" validate={required()} />
        <CPFInput source="cpf" label="CPF" isRequired />
        <TextInput source="registration" label="Matrícula" />
        <PhoneInput source="phone" label="Telefone" />
        <TextInput source="email" type="email" validate={email()} />
        <SelectInput source="type" label="Tipo" choices={choices} />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput label="Grupo de Transportadora" optionText="name" />
          </ReferenceInput>
        </Permission>
      </SimpleForm>
    </Edit>
  );
};

const ManagerCreate = (props) => {
  return (
    <Create title='Adicionar gestor' {...props}>
      <SimpleForm defaultValues={{ companyId: localStorage.getItem('companyId') }}>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: localStorage.getItem('companyId') }}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}>
          <SelectInput label="Transportadora" optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Nome" validate={required()} />
        <CPFInput source="cpf" label="CPF" isRequired />
        <TextInput source="registration" label="Matrícula" />
        <PhoneInput source="phone" label="Telefone" />
        <TextInput source="email" type="email" validate={email()} />
        <SelectInput source="type" label="Tipo" choices={choices} />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput label="Grupo de Transportadora" optionText="name" />
          </ReferenceInput>
        </Permission>
      </SimpleForm>
    </Create>
  );
}

const resource = {
  list: ManagerList,
  edit: ManagerEdit,
  create: ManagerCreate,
  name: 'managers',
  permissions: [PERMISSIONS.LIST_MANAGERS]
};

export default resource;