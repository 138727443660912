import FileDownload from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import { Button, Datagrid, DateField, DateInput, FunctionField, NumberField, ReferenceField, ReferenceInput, ReferenceManyField, SelectField, SelectInput, TextField, useGetIdentity, useListContext, useNotify, useRecordContext } from 'react-admin';
import { ListPagination, Permission, Permissions, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { getChainId, getCompanyId } from "../../utils/utils";
import { FillinDatagrid } from "../Fillin";
import { BillStatusChoices } from '../../models/bill';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30,
    paddingBottom: 30,
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));


const DownloadInvoiceButton = (props) => {
  const record = useRecordContext();
  const { data } = useListContext();
  const fileUrls = (data || []).filter(item => item.path).map((item) => item.path);

  const notify = useNotify();

  const handleDownloadXmls = async () => {
    const zip = new JSZip();

    const downloadPromises = fileUrls.map(async (fileUrl, index) => {
      try {
        const response = await fetch(fileUrl);
        const fileData = await response.text();

        const fileName = `nota-${index + 1}.${fileUrl.split('.').pop()}`;
        zip.file(fileName, fileData);
      } catch (error) {
        console.error(`Error downloading file from ${fileUrl}: ${error.message}`);
        throw error;
      }
    });

    await Promise.all(downloadPromises)
      .then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const zipBlob = new Blob([content], { type: 'application/zip' });

          // Create a temporary link and trigger the download
          const a = document.createElement('a');
          a.href = URL.createObjectURL(zipBlob);
          a.download = `fatura.zip`;
          a.click();

          URL.revokeObjectURL(a.href);
        });
      })
      .catch(() => {
        notify(`O download dos arquivos não pode ser executado no momento. Tente novamente mais tarde.`, { type: 'error' });
      });
  };

  return (
    fileUrls.length ?
      <Button label={props.label} startIcon={<FileDownload />} onClick={handleDownloadXmls} />
      :
      <></>
  );
}

const DownloadInvoicePdfButton = (props) => {
  const record = useRecordContext(props);
  const notify = useNotify();

  const handleDownloadPdf = () => {
    try {
      saveAs(record.pdfPath, `invoice-${record.id}.pdf`);
    } catch (error) {
      notify(error.message || `Erro ao baixar arquivo`, { type: 'warning' });
    }
  };

  return (
    <Button label={props.label} startIcon={<FileDownload />} onClick={handleDownloadPdf} />
  );
}

const differentValue = (record) => ({
  backgroundColor: record.billValue !== record.totalInvoiceValue ? '#ffd6cc' : '#ffffff',
})

const BillList = () => {
  const classes = useStyles();
  const props = useListContext();

  return (
    <>
      <Typography className={classes.title}>Faturas</Typography>
      <Datagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        rowSx={differentValue}
        bulkActionButtons={false}
      >
        <ReferenceField source="chainId" label="Rede" reference="chains" emptyText="Não definido">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="stationId" label="Posto" reference="stations" emptyText="Não definido" textAlign="center">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido" textAlign="center">
          <TextField source="name" />
        </ReferenceField>
        <Permissions label="Filial" emptyText="Não definido" userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField source="vehicleBaseId" reference="vehicle-bases" textAlign="center">
            <TextField source="name" />
          </ReferenceField>
        </Permissions>
        <TextField source="billNumber" label="Número do Boleto" emptyText="Não informado" textAlign="center" />
        <NumberField
          source="billValue"
          label="Valor do boleto"
          locales="pt-BR"
          textAlign="center"
          emptyText="Não informado"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="totalInvoiceValue"
          label="Total em notas"
          locales="pt-BR"
          textAlign="center"
          emptyText="Não informado"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="billExpiration" label="Vencimento do boleto" emptyText="Não informado" textAlign="center" />
        <DateField source="createdAt" label="Enviado em" showTime textAlign="center" />
        {/* <SelectField
          source="status"
          choices={BillStatusChoices}
          optionText="label"
          optionValue="value"
          label="Status"
          textAlign="center"
        /> */}
        <ReferenceManyField target="billId" reference="invoices">
          <DownloadInvoiceButton label="XML" />
        </ReferenceManyField>
        <FunctionField render={(record) => record.pdfPath && <DownloadInvoicePdfButton label="PDF" />} />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" label="De" options={{ format: 'DD/MM/YYYY' }} />,
      <DateField source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} />,
      <Permissions userRoles={[UserRole.admin]}>
        <ReferenceField emptyText="Todos" label="Grupo de Transportadora" source="companyId" textAlign="center" reference="companies" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceField label="Transportadora" emptyText="Todos" source="subCompanyId" textAlign="center" reference="sub-companies" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <Permissions userRoles={[UserRole.admin, UserRole.company]}>
        <ReferenceField label="Filial" emptyText="Todos" source="vehicleBaseId" textAlign="center" reference="vehicle-bases" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      {/* <SelectField
        source="status"
        choices={BillStatusChoices}
        optionText="label"
        optionValue="value"
        label="Status"
      /> */}
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const BillsReport = (props) => {
  const { identity } = useGetIdentity();

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Grupo de Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <ReferenceInput
      source="subCompanyId"
      reference="sub-companies"
      filter={{ companyId: localStorage.getItem('companyId') }}
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        emptyText="Todos"
        label="Transportadora"
        optionText={'name'}
      />
    </ReferenceInput>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          emptyText="Todos"
          label="Filial"
          optionText="name"
        />
      </ReferenceInput>
    </Permissions>,
    <ReferenceInput
      source="stationId"
      reference={identity?.role === UserRole.company ? 'companies/companyId/stations' : 'stations'}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput
        emptyText="Todos"
        label="Posto"
        optionText="name"
      />
    </ReferenceInput>,
    // <SelectInput
    //   source="status"
    //   choices={BillStatusChoices}
    //   optionText="label"
    //   optionValue="value"
    //   label="Status"
    //   emptyText="Todos"
    //   alwaysOn
    // />,
  ];

  return (
    <ReportList
      {...props}
      basePath="bills"
      title="Faturas"
      resource="bills"
      filters={listFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().startOf('day').subtract(1, 'month').toISOString(),
        to: moment().endOf('day').toISOString(),
      }}
      filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={false}
      titleOnPrint="Relatório de Faturas"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <BillList />
    </ReportList>
  );
};

export default BillsReport;