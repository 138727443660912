import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ArrayField, Datagrid, DateField, Filter, FunctionField, Labeled, NumberField, ReferenceArrayInput, ReferenceField, SelectArrayInput, SelectField, Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField, useListContext, useRecordContext, useResourceContext } from 'react-admin';
import { List, Permissions } from '../../components';
import { PERMISSIONS } from '../../constants';
import { BankAccountTypeChoices } from '../../models';
import { BillStatusChoices } from '../../models/bill';
import { UserRole } from '../../providers/authProvider';
import { formatCnpj } from '../../utils/utils';
import { FillinDatagrid, ScreenType } from '../Fillin';
import BillStatusButton from './BillStatusButton';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const BillFillinsList = () => {
  const record = useRecordContext();

  const FillinsView = () => {
    const classes = useStyles();
    const props = useListContext();

    let data = [];
    let ids = [];
    let summaryData: any[] | undefined = [];

    if (props.data) {
      const fillinInfo = Object.values(props.data)[0];
      if (fillinInfo) {
        data = fillinInfo.data;
        ids = fillinInfo.data.map((item: any) => item.id);
        summaryData = [fillinInfo.summaryData];
      }
    }

    return (
      <>
        <Datagrid data={summaryData} bulkActionButtons={false}>
          <NumberField
            source="total_value"
            label="Valor total"
            locales="pt-BR"
            textAlign="center"
            emptyText="R$ 0,00"
            options={{
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }}
            sortable={false}
          />
          <NumberField
            source="total_amount"
            label="Litros total"
            textAlign="center"
            emptyText="0"
            options={{
              maximumFractionDigits: 2
            }}
            sortable={false}
          />
          <NumberField
            source="total"
            label="Quantidade"
            textAlign="center"
            emptyText="0"
            sortable={false}
          />
        </Datagrid>
        <Typography className={classes.title}>Abastecimentos</Typography>
        <FillinDatagrid screen={ScreenType.billShow} data={data} bulkActionButtons={false} />
      </>
    );
  }

  const FillinFilter = (props: any) => (
    <Filter {...props}>
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
      >
        <SelectArrayInput optionText="name" label="Combustíveis" />
      </ReferenceArrayInput>
    </Filter>
  );

  return (
    <List
      resource="bill-fillins"
      filter={{ billId: record.id }}
      filters={<FillinFilter />}
      title=" "
      exporter={false}
    >
      <FillinsView />
    </List>
  );
}

const BillShow: React.FC = (props) => {
  const resource = useResourceContext();
  return (
    <Show
      {...props}
      title="Fatura"
    >
      <TabbedShowLayout>
        <Tab label="Geral">
          <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="vehicleBaseId" label="Filial" reference="vehicle-bases" emptyText="Não definido" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            source="billValue"
            label="Valor do boleto"
            locales="pt-BR"
            textAlign="center"
            emptyText="Não informado"
            options={{
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <DateField source="billExpiration" label="Vencimento do boleto" emptyText="Não informado" />
          <DateField source="createdAt" label="Enviado em" showTime />
          {/* <SelectField
            label="Status"
            source="status"
            choices={BillStatusChoices}
            optionText="label"
            optionValue="value"
          />
          <Permissions permissions={[PERMISSIONS.EDIT_BILLS]} userRoles={[UserRole.company, UserRole.admin]}>
            <Labeled label="Ações">
              <BillStatusButton style={{ width: 240 }} resource={resource} variant="outlined" />
            </Labeled>
          </Permissions> */}
        </Tab>
        <Tab label="Notas">
          <NumberField
            source="totalInvoiceValue"
            label="Valor Total"
            locales="pt-BR"
            textAlign="center"
            emptyText="Não informado"
            options={{
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
            />
          <ArrayField source="invoices" label="Notas Fiscais" emptyText="Não identificado">
            <Datagrid bulkActionButtons={false}>
              <TextField source="invoiceNumber" label="Número" />
              <TextField source="serie" label="Série" emptyText="Não identificado" />
              <DateField source="issueDate" locales="pt-BR" label="Data de emissão" emptyText="Não identificado" showTime />
              <TextField source="accessKey" label="Chave de acesso" emptyText="Não identificado" />
              <NumberField
                source="totalInvoiceValue"
                label="Valor"
                locales="pt-BR"
                textAlign="left"
                emptyText="Não informado"
                options={{
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Abastecimentos">
          <BillFillinsList />
        </Tab>
        <Tab label="Informações de pagamento">
          <ReferenceField source="stationId" reference="stations" label=" " link={false}>
            <SimpleShowLayout>
              <TextField source="accountName" label="Nome da Conta" emptyText="--" />
              <FunctionField label="CNPJ" render={(record: any) => (record && record.accountCnpj) ? formatCnpj(record.accountCnpj) : '--'} />
              <SelectField source="accountType" label="Tipo" choices={BankAccountTypeChoices} optionText="label" optionValue="value" emptyText="--" />
              <TextField source="accountNumber" label="Número" emptyText="--" />
              <TextField source="agency" label="Agência" emptyText="--" />
              <TextField source="bankCode" label="Código do Banco" emptyText="--" />
            </SimpleShowLayout>
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default BillShow;