import React, { Fragment, MouseEvent, useState } from 'react';
import { Button, Confirm, useDataProvider, useNotify, useRecordContext, useRefresh, useResourceContext } from 'react-admin';
import ApprovedIcon from '@mui/icons-material/Check';
import DeniedIcon from '@mui/icons-material/DoDisturb';
import { Stack, Tooltip } from '@mui/material';
import { FillinReviewStatus } from '../../models';

interface ConfirmButtonProps {
  title: string;
  titleTooltip: string;
  description: string;
  icon: React.ReactElement;
  color?: any;
  isLoading?: boolean;
  handleConfirm: () => void;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({ title, titleTooltip, description, icon, isLoading = false, handleConfirm, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  }

  return (
    <>
      <Tooltip title={titleTooltip}>
        <div>
          <Button
            startIcon={icon}
            label=""
            onClick={handleClick}
            variant="outlined"
            sx={{
              '& .MuiButton-startIcon': { marginRight: '-2px' }
            }}
            disabled={isLoading}
            {...props}
          />
        </div>
      </Tooltip>
      <Confirm
        isOpen={isOpen}
        title={title}
        content={description}
        onConfirm={() => {
          handleClose();
          handleConfirm();
        }}
        onClose={handleClose}
      />
    </>
  );
}

const FillinReviewButton: React.FC<{ source: string }> = (props) => {

  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isLoading, setIsloading] = useState<boolean>(false);

  const handleReviewFillin = (status: FillinReviewStatus) => {
    setIsloading(true);
    dataProvider.update(resource, { id: record.id, data: { [props.source]: status }, previousData: record })
      .then(() => {
        notify(`Abastecimento foi ${FillinReviewStatus.translateStatus(status).toLowerCase()} com sucesso`, { type: 'info' });
        refresh();
      })
      .catch(() => {
        notify(`Não foi possível realizar a operação`, { type: 'error' });
      })
      .finally(() => {
        setIsloading(false);
      });
  }

  return (
    <Fragment>
      {
        record[props.source] === FillinReviewStatus.pending &&
        <Stack flexDirection="row" gap={1}>
          <ConfirmButton
            title="Reprovar abastecimento manual"
            titleTooltip="Reprovar abastecimento - Valor diferente da comanda"
            description="Você confirma que a quantidade está divergente da comanda?"
            handleConfirm={() => handleReviewFillin(FillinReviewStatus.denied)}
            icon={<DeniedIcon />}
            color="warning"
            isLoading={isLoading}
          />
          <ConfirmButton
            title="Confirmar abastecimento manual"
            titleTooltip="Confirmar abastecimento"
            description="Você confirma que a quantidade está de acordo com a comanda?"
            handleConfirm={() => handleReviewFillin(FillinReviewStatus.approved)}
            icon={<ApprovedIcon />}
            isLoading={isLoading}
          />
        </Stack>
      }
    </Fragment>
  );
};

export default FillinReviewButton;