import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SearchInput,
  SelectArrayInput,
  SelectInput,
  Show,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  maxValue,
  minValue,
  required,
  useChoicesContext,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { List, VehicleAverageChart } from '../components';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { UserRole } from '../providers/authProvider';
import { getCompanyId } from '../utils/utils';
import { DriverVehiclesDatagrid } from './Driver';
import { FillinDatagrid } from './Fillin';
import { LicensePlateInput } from '../utils/Inputs';

const VehicleTitle = () => {
  const record = useRecordContext();
  if (!record) { return <></>; };
  return <span>{`${record.name} - ${record.licensePlate}`}</span>;
};

const OperationSelectInput = (props) => {
  const context = useChoicesContext(props);
  return (
    <SelectInput
      {...props}
      validate={required()}
      source={context.source}
      choices={(context.allChoices || []).map(item => ({ id: item.id, [props.optionText]: `${('0000' + item.code).slice(-4)} - ${item.name}` }))}
    />
  );
}

const OperationTextField = (props) => (
  <FunctionField {...props} render={record => record ? `${('0000' + record.code).slice(-4)} - ${record.name}` : ''} />
);

export const VehicleDatagrid = props => {
  return (
    <Datagrid
      {...props}
      bulkActionButtons={false}
      expand={<DriverVehiclesDatagrid bulkActionButtons={false} />}
    >
      <TextField source="name" label="Nome" />
      <TextField source="licensePlate" label="Placa" />
      <TextField source="brand" label="Marca" />
      <TextField source="model" label="Modelo" />
      <TextField source="year" label="Ano" />
      <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
      <BooleanField source="mustInformOdometer" label="Obrigar a informar odômetro" />
      <NumberField source="consumptionAverageTopLimit" label="Média máxima de consumo (km/L)" locales="pt-BR" emptyText='Sem limite' />
      <NumberField source="consumptionAverageBottomLimit" label="Média mínima de consumo (km/L)" locales="pt-BR" emptyText='Sem limite' />
    </Datagrid>
  );
};

const VehicleShow = props => {
  const { id } = useParams();
  return (
    <Show title={<VehicleTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Resumo">
          <TextField source="id" label="ID" />
          <TextField source="name" label="Nome" />
          <ReferenceField label="Transportadora" source="subCompanyId" reference="sub-companies" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Gestor" source="managerId" reference="managers" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="vehicleGroupId" reference="vehicle-groups" label="Grupo" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="operationId" reference="operations" label="Operação" emptyText="Não definido">
            <OperationTextField />
          </ReferenceField>
          <TextField source="licensePlate" label="Placa" />
          <TextField source="brand" label="Marca" />
          <TextField source="model" label="Modelo" />
          <TextField source="year" label="Ano" />
          <TextField source="brand" label="Família" />
          <NumberField source="tankCapacity" label="Capacidade do tanque (L)" locales="pt-BR" />
          <NumberField source="arlaCapacity" label="Capacidade de arla" locales="pt-BR" />
          <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
          <BooleanField source="mustInformOdometer" label="Obrigar a informar odômetro" />
          <NumberField source="consumptionAverageTopLimit" label="Média máxima de consumo (km/L)" locales="pt-BR" />
          <NumberField source="consumptionAverageBottomLimit" label="Média mínima de consumo (km/L)" locales="pt-BR" />
          <NumberField source="expectedAverage" label="Média de consumo esperada (Km/L)" locales="pt-BR" />
        </Tab>
        <Tab label="Abastecimentos">
          <ReferenceManyField
            reference="fillins"
            target="vehicleId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <FillinDatagrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="Média">
          <div>
            <List
              resource="vehicles/averages"
              title="Média"
              filters={[
                <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
                <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
              ]}
              filter={{
                vehicleId: id,
              }}
              filterDefaultValues={{
                from: moment().startOf('day').subtract(1, 'month').toISOString(),
                to: moment().endOf('day').toISOString(),
              }}
              pagination={false}
              exporter={false}
            >
              <VehicleAverageChart styleContent={{ paddingTop: 20 }} />
            </List>
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const FilterVehicle = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      <SelectInput
        label="Status"
        source="isInactive"
        emptyText="Todos"
        choices={[{ id: true, name: "Inativos" }, { id: false, name: "Ativos" }]}
        alwaysOn
      />
    </Filter>
  )
}

const VehicleList = props => {
  const { identity } = useGetIdentity();
  return (
    <List
      {...props}
      title="Veículos"
      filters={<FilterVehicle />}
      filter={getCompanyId() ? { companyId: getCompanyId() } : null}
      filterDefaultValues={{ isInactive: false }}
      >
      <Datagrid rowClick="show"
        bulkActionButtons={identity && [UserRole.admin, UserRole.company].includes(identity.role)}>
        <TextField source="name" label="Nome" />
        <TextField source="licensePlate" label="Placa" />
        <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
        <Permission userRole={UserRole.admin}>
          <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="mustInformOdometer" label="Obrigar a informar odômetro" />
        {identity && [UserRole.admin, UserRole.company].includes(identity.role) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const VehicleEdit = props => {
  const { identity } = useGetIdentity();
  const [useTankCapacity, setUseTankCapacity] = useState(true);

  return (
    <Edit title='Editar veículo' {...props}>
      <TabbedForm>
        <FormTab label="Geral">
          <TextInput disabled label="ID" source="id" />
          <Permission userRole={UserRole.admin}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Transportadora" optionText="name" emptyText="Nenhum" />
            </ReferenceInput>
          </Permission>
          <ReferenceInput
            label="Transportadora"
            source="subCompanyId"
            reference="sub-companies"
            filter={{ companyId: localStorage.getItem('companyId') }}
            sort={{ field: "name", order: "ASC" }}>
            <SelectInput label="Transportadora" optionText="name" emptyText="Nenhum" validate={required()} />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="costCenterId"
                  reference="cost-centers"
                  sort={{ field: 'name', order: 'ASC' }}
                  pagination={null}
                  perPage={null}
                  filter={
                    identity?.role === UserRole.company ? { companyId: getCompanyId() }
                      : formData.companyId ? { companyId: formData.companyId } : null
                  }
                >
                  <SelectInput label="Centro de Custo" optionText="name" emptyText="Nenhum" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="managerId"
                  reference="managers"
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={
                    identity?.role === UserRole.company ? { companyId: getCompanyId() }
                      : formData.companyId ? { companyId: formData.companyId } : null
                  }
                  pagination={null}
                  perPage={null}
                  allowEmpty
                >
                  <SelectInput label="Gestor" optionText="name" emptyText="Nenhum" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="operationId"
                  reference="operations"
                  sort={{ field: 'code', order: 'ASC' }}
                  pagination={null}
                  perPage={null}
                  filter={
                    {
                      isInactive: false,
                      ...(identity?.role === UserRole.company ? { companyId: getCompanyId() }
                        : formData.companyId ? { companyId: formData.companyId } : null)
                    }
                  }
                >
                  <OperationSelectInput label="Operação" optionText="name" emptyText="Nenhum" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="fuelIds"
            reference="fuels"
          >
            <FormDataConsumer>
              {(fuelsProps) => {
                const { formData: { fuelIds }, choices } = fuelsProps;
                if (fuelIds && choices && choices.length) {
                  const hasLiquidFuel = fuelIds.some((fuelId) =>
                    choices.find(({ id }) => fuelId === id).acronym !== 'GNV');
                  setUseTankCapacity(hasLiquidFuel);
                }

                return <SelectArrayInput {...fuelsProps} optionText="name" label="Combustíveis" />;
              }}
            </FormDataConsumer>
          </ReferenceArrayInput>
          <TextInput source="name" label="Nome" />
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.companyId && (
              <ReferenceInput
                {...rest}
                source="vehicleGroupId"
                reference="vehicle-groups"
                filter={{ companyId: formData.companyId }}
                allowEmpty
              >
                <SelectInput optionText="name" label="Grupo de Veículos" emptyText="Nenhum" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <LicensePlateInput source="licensePlate" label="Placa" />
          <TextInput source="brand" label="Marca" />
          <TextInput source="model" label="Modelo" />
          <TextInput source="year" label="Ano" />
          <TextInput source="family" label="Família" />
          <FormDataConsumer>
            {({ formData }) => {
              !useTankCapacity && (formData.tankCapacity = null);

              return (useTankCapacity &&
                <NumberInput
                  source="tankCapacity"
                  label="Capacidade do tanque (L)"
                  locales="pt-BR"
                  validate={minValue(1)}
                />);
            }}
          </FormDataConsumer>
          <NumberInput source="arlaCapacity" label="Capacidade de arla" locales="pt-BR" validate={minValue(1)} />
          <NumberInput source="odometer" label="Odômetro" locales="pt-BR" />
          <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" />
          <BooleanInput source="isInactive" label="Inativar veículo" />
        </FormTab>
        <FormTab label="Desempenho">
          <NumberInput
            source="consumptionAverageTopLimit"
            label="Média máxima de consumo (km/L)"
            locales="pt-BR"
            validate={[minValue(1), required()]}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <NumberInput
                source="consumptionAverageBottomLimit"
                label="Média mínima de consumo (km/L)"
                locales="pt-BR"
                validate={[maxValue(formData.consumptionAverageTopLimit, "O valor deve ser menor ou igual a Média máxima de consumo"), required()]}
              />
            )}
          </FormDataConsumer>
          <NumberInput
            source="expectedAverage"
            label="Média de consumo esperada (km/L)"
            locales="pt-BR"
            validate={[minValue(1), required()]}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const VehicleCreate = props => {
  const { identity } = useGetIdentity();
  const [odometerDefault, setOdometerDefault] = useState(false);
  const [useTankCapacity, setUseTankCapacity] = useState(true);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const companyId = getCompanyId();

  useEffect(() => {
    if (identity?.role === UserRole.company) {
      dataProvider
        .getOne(`company-config`, { id: companyId })
        .then(({ data }) => setOdometerDefault(data.vehicleOdometer))
        .catch(() => notify('Erro ao buscar configurações atuais', { type: 'warning' }))
    }
  }, [identity, companyId]);

  return (
    <Create title='Adicionar veículo' {...props}>
      <TabbedForm defaultValues={{ companyId }}>
        <FormTab label="Geral">
          <Permission userRole={UserRole.admin}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Grupo de Transportadora" optionText="name" />
            </ReferenceInput>
          </Permission>
          <ReferenceInput
            label="Transportadora"
            source="subCompanyId"
            reference="sub-companies"
            filter={{ companyId: localStorage.getItem('companyId') }}
            sort={{ field: "name", order: "ASC" }}
            validate={required()}>
            <SelectInput label="Transportadora" optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="managerId"
                  reference="managers"
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={
                    identity?.role === UserRole.company ? { companyId }
                      : formData.companyId ? { companyId: formData.companyId } : null
                  }
                  pagination={null}
                  perPage={null}
                  emptyText={""}
                  allowEmpty
                >
                  <SelectInput label="Gestor" optionText="name" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="operationId"
                  reference="operations"
                  sort={{ field: 'code', order: 'ASC' }}
                  pagination={null}
                  perPage={null}
                  filter={
                    identity?.role === UserRole.company ? { companyId: getCompanyId() }
                      : formData.companyId ? { companyId: formData.companyId } : null
                  }
                >
                  <OperationSelectInput label="Operação" optionText="name" emptyText="Nenhum" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <ReferenceArrayInput
            helperText="O motorista será capaz de abastecer apenas com os combustíveis selecionados."
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="fuelIds"
            reference="fuels"
          >
            <FormDataConsumer>
              {(fuelsProps) => {
                const { formData: { fuelIds }, choices } = fuelsProps;
                if (fuelIds && choices && choices.length) {
                  const hasLiquidFuel = fuelIds.some((fuelId) =>
                    choices.find(({ id }) => fuelId === id).acronym !== 'GNV');
                  setUseTankCapacity(hasLiquidFuel);
                }

                return <SelectArrayInput {...fuelsProps} optionText="name" label="Combustíveis" />;
              }}
            </FormDataConsumer>
          </ReferenceArrayInput>
          <TextInput source="name" label="Nome" />
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.companyId && (
              <ReferenceInput
                {...rest}
                source="vehicleGroupId"
                reference="vehicle-groups"
                filter={{ companyId: formData.companyId }}
                allowEmpty
              >
                <SelectInput label="Grupo de Veículos" optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <LicensePlateInput source="licensePlate" label="Placa" isRequired />
          <TextInput source="brand" label="Marca" />
          <TextInput source="model" label="Modelo" />
          <TextInput source="year" label="Ano" />
          <TextInput source="family" label="Família" />
          <FormDataConsumer>
            {({ formData }) => {
              !useTankCapacity && (formData.tankCapacity = null);

              return (useTankCapacity &&
                <NumberInput
                  source="tankCapacity"
                  label="Capacidade do tanque (L)"
                  locales="pt-BR"
                  validate={minValue(1)}
                />);
            }}
          </FormDataConsumer>
          <NumberInput source="arlaCapacity" label="Capacidade de arla" locales="pt-BR" validate={minValue(1)} />
          <NumberInput source="odometer" label="Odômetro" locales="pt-BR" defaultValue={0} />
          <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" defaultValue={odometerDefault} />
        </FormTab>
        <FormTab label="Desempenho">
          <NumberInput
            source="consumptionAverageTopLimit"
            label="Média máxima de consumo (km/L)"
            locales="pt-BR"
            validate={[minValue(1), required()]}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <NumberInput
                source="consumptionAverageBottomLimit"
                label="Média mínima de consumo (km/L)"
                locales="pt-BR"
                validate={[maxValue(formData.consumptionAverageTopLimit, "O valor deve ser menor ou igual a Média máxima de consumo"), required()]}
              />
            )}
          </FormDataConsumer>
          <NumberInput
            source="expectedAverage"
            label="Média de consumo esperada (km/L)"
            locales="pt-BR"
            validate={[minValue(1), required()]}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const resource = {
  list: VehicleList,
  edit: VehicleEdit,
  create: VehicleCreate,
  show: VehicleShow,
  name: 'vehicles',
  permissions: [PERMISSIONS.LIST_VEHICLES]
}
export default resource;