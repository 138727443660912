import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Labeled,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { List, Permission } from '../components';
import { SearchFilter } from '../components/SearchFilter';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';
import { VehicleDatagrid } from './Vehicle';

const VehicleGroupShow = props => (
  <Show title="Grupo de veículos" {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
        <Permission permission={PERMISSIONS.ADMIN}>
          <Labeled label="Grupo de Transportadora">
            <ReferenceField source="companyId" reference="companies">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Permission>
      </Tab>
      <Tab label="Veículos">
        <ReferenceManyField
          reference="vehicles"
          target="vehicleGroupId"
          label={false}
          sort={{ field: "createdAt", order: "DESC" }}
          pagination={<Pagination />}
        >
          <VehicleDatagrid bulkActionButtons={false} />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const VehicleGroupList = props => (
  <List
    {...props}
    title="Grupos de veículos"
    filters={<SearchFilter />}
    filter={getCompanyId() ? { companyId: getCompanyId() } : null}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <Permission permission={PERMISSIONS.ADMIN} >
        <ReferenceField source="companyId" label="Grupo de Transportadora" reference="companies">
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Nome" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const VehicleGroupEdit = props => (
  <Edit title="Editar grupo de veículos" {...props}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <ReferenceInput
        label="Transportadora"
        source="subCompanyId"
        reference="sub-companies"
        filter={{ companyId: localStorage.getItem('companyId') }}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}>
        <SelectInput label="Transportadora" optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Nome" />
    </SimpleForm>
  </Edit>
);

const VehicleGroupCreate = props => (
  <Create title="Cadastrar grupo de veículos" {...props}>
    <SimpleForm defaultValues={{ companyId: getCompanyId() }}>
      <Permission permission={PERMISSIONS.ADMIN}>
        <ReferenceInput source="companyId" reference="companies">
          <SelectInput label="Transportadora" optionText="name" validate={required('Campo obrigatório')} />
        </ReferenceInput>
      </Permission>
      <ReferenceInput
        label="Transportadora"
        source="subCompanyId"
        reference="sub-companies"
        filter={{ companyId: localStorage.getItem('companyId') }}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}>
        <SelectInput label="Transportadora" optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Nome" validate={required('Campo obrigatório')} />
    </SimpleForm>
  </Create>
);

const resource = {
  list: VehicleGroupList,
  edit: VehicleGroupEdit,
  create: VehicleGroupCreate,
  show: VehicleGroupShow,
  name: 'vehicle-groups',
  permissions: [PERMISSIONS.LIST_VEHICLE_GROUPS]
}
export default resource;