import { useState } from 'react';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { BooleanField, Button, Datagrid, DateField, Filter, ReferenceArrayInput, ReferenceField, SelectArrayInput, SelectField, SelectInput, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { LazyLoadAutoCompleteInput, List, ListPagination, ApproveOrDenyDialog } from '../components';
import { FillinAttemptStatus, FillinAttemptStatusChoices } from '../models';
import { formatCpf } from '../utils/utils';
import { PERMISSIONS } from '../constants';

const FillinAttemptFilter = props => {
  return (
    <Filter {...props}>
      {/* @ts-ignore */}
      <LazyLoadAutoCompleteInput
        label="Motorista"
        reference="drivers"
        source="driverId"
        format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
        style={{ minWidth: 300, marginBottom: 2 }}
        alwaysOn
      />
      <ReferenceArrayInput
        reference="stations"
        source="stationIds"
        //@ts-ignore
        perPage={null}
        alwaysOn
      >
        <SelectArrayInput optionText="name" label="Postos" sx={{ marginBottom: 0.5, paddingTop: 1 }} />
      </ReferenceArrayInput>
      <SelectInput source="status" label="Status" choices={FillinAttemptStatusChoices} emptyText="Todos" alwaysOn />
    </Filter>
  );
};

const ActionButtons = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<FillinAttemptStatus>();
  const handleClose = () => setOpen(false);

  const ApproveButton = () => {
    const record = useRecordContext();

    return (
      <Button
        label="Aprovar"
        type="button"
        onClick={(e: any) => {
          e.stopPropagation();
          setOpen(true);
          setStatus(FillinAttemptStatus.approved);
        }}
        startIcon={<ThumbUp />}
        style={{ margin: 10 }}
        disabled={record.status === FillinAttemptStatus.approved}
      />
    );
  }

  const DenyButton = () => {
    const record = useRecordContext();

    return (
      <Button
        label="Reprovar"
        type="button"
        onClick={(e: any) => {
          e.stopPropagation();
          setOpen(true);
          setStatus(FillinAttemptStatus.denied);
        }}
        startIcon={<ThumbDown />}
        style={{ margin: 10, ...(record.status !== FillinAttemptStatus.denied ? { color: 'red' } : {}) }}
        disabled={record.status === FillinAttemptStatus.denied}
      />
    );
  }

  return (
    !record.isUsed ?
      <>
        <ApproveButton />
        <DenyButton />
        <ApproveOrDenyDialog open={open} handleClose={handleClose} status={status} />
      </>
      :
      <></>
  );
}

const FillinAttemptShow = props => {
  return (
    <Show title="Tentativa de abastecimento" {...props}>
      <SimpleShowLayout>
        <ReferenceField reference="drivers" source="driverId" label="Motorista">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="drivers" source="valetId" label="Manobrista" emptyText='--' link={(record, _) => `/valets/${record.id}/show`}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="stations" source="stationId" label="Posto" textAlign="center">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="vehicles" source="vehicleId" label="Veículo" textAlign="center" emptyText="--">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="qrcode" label="Qrcode" textAlign="center" />
        <TextField source="reason" label="Motivo" />
        <SelectField source="status" choices={FillinAttemptStatusChoices} label="Status" textAlign="center" />
        <DateField source="createdAt" label="Data" showTime textAlign="center" />
        <BooleanField source="isUsed" label="Usado" />
        <ActionButtons />
      </SimpleShowLayout>
    </Show>
  );
}


const FillinAttemptList = props => {
  return (
    <List
      {...props}
      title="Tentativas de abastecimento"
      filters={<FillinAttemptFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<ListPagination />}
      perPage={25}
      exporter={false}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField reference="drivers" source="driverId" label="Motorista" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="drivers" source="valetId" label="Manobrista" emptyText="--" textAlign="center" link={(record, _) => `/valets/${record.id}/show`}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="stations" source="stationId" label="Posto" textAlign="center" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="vehicles" source="vehicleId" label="Veículo" textAlign="center" emptyText="--" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="qrcode" label="Qrcode" textAlign="center" />
        <TextField source="reason" label="Motivo" />
        <SelectField source="status" choices={FillinAttemptStatusChoices} label="Status" textAlign="center" />
        <DateField source="createdAt" label="Data" showTime textAlign="center" />
        <BooleanField source="isUsed" label="Usado" />
        <ActionButtons />
      </Datagrid>
    </List>
  );
}


const resource = {
  list: FillinAttemptList,
  // edit: CustomerEdit,
  // create: CustomerCreate,
  show: FillinAttemptShow,
  name: 'fillin-attempts',
  permissions: [PERMISSIONS.LIST_FILLIN_ATTEMPTS]
};

export default resource;