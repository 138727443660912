import React, { MouseEvent } from 'react';
import { TextField, useNotify, useRecordContext } from 'react-admin';
import { Button as MuiButton } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { copyToClipboard } from '../../utils/utils';

const CopyToChipboardField: React.FC<{ Component?: any, source: string, label?: string, showClipButton: boolean }> = (props) => {

  const {
    Component = TextField,
    source,
    showClipButton = true,
    ...rest
  } = props;

  const record = useRecordContext();
  const notify = useNotify();

  const handleClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (record[source]) {
      copyToClipboard(record[source]);
      notify("Copiado para Área de transferência", { type: "info", autoHideDuration: 1000 });
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Component source={source} {...rest} />
      {
        (record && record[source] && showClipButton) &&
        <MuiButton onClick={handleClipboard} size="small" variant="text" sx={{ marginLeft: "5px", paddingLeft: '5px', paddingRight: '5px', minWidth: 'auto' }}>
          <ContentCopyIcon sx={{ fontSize: 18 }} />
        </MuiButton>
      }
    </div>
  );
}

export default CopyToChipboardField;