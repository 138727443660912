import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, DateInput, FormDataConsumer, Labeled, ReferenceInput, SelectInput, SelectInputProps, SimpleForm, TextInput, Toolbar, number, required, useChoicesContext, useCreate, useGetIdentity, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { ChatOrigin } from '../../models';
import { PriceInput } from '../../utils/Inputs';
import { dateInputFormatter, formatPrice, getChainId, getUserId, isDifferenceSignificant } from '../../utils/utils';
import ChatAccordion from '../ChatAccordion';
import { UserRole } from "../../providers/authProvider";
import Permission from '../Permission';
import React from 'react';

interface CustomSelectProps extends SelectInputProps {
  setData: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
}

const BillBulkAction = ({ selectedIds, setVehicleBase, setChain, resetInitialValues, totalValue, selectFillins, ...props }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [create, { isLoading }] = useCreate();
  const { identity } = useGetIdentity();

  const CustomSelectInput = ({ setData, ...props }: CustomSelectProps) => {
    const { selectedChoices } = useChoicesContext();

    useEffect(() => {
      setData(selectedChoices[0]);
    }, [selectedChoices[0]])

    return (
      <SelectInput {...props} />
    );
  }

  const createChatMessage = ({ billId, subject, body, participantIds, chatFiles }: { billId: string, subject: string, body: string, chatFiles: Object, participantIds?: string[] }) => new Promise((resolve, reject) => {
    if (subject || body || chatFiles) {
      notify('Enviando sua mensagem');

      const data: any = {
        subject: subject || '(Sem assunto)',
        chatMessage: {
          body,
          files: chatFiles,
        },
        employeeId: getUserId(),
        chainId: getChainId(),
        origin: ChatOrigin.bill,
        billId,
      }

      if (participantIds) {
        data.participantIds = participantIds;
      }

      create('chats', { data }, {
        onSettled: (_data, error: any) => {
          if (error.status) {
            notify(error.message || 'Não foi possível encaminhar a mensagem, mas pode ser feito uma nova tentativa na tela de notas fiscais', { type: 'warning' });
            reject(error);
            return;
          }
          notify('Mensagem enviada');
          resolve(_data);
        }
      });
    } else {
      resolve(null);
    }
  });

  const handleSubmit = async (props: any, clear: () => void) => {

    const { body, subject, chatFiles, participantIds, ...payload } = props;

    create('bills', { data: payload }, {
      onSuccess: (data) => {
        notify('Fatura criada com sucesso');

        createChatMessage({ billId: data.id, subject, body, chatFiles, participantIds }).finally(() => {
          clear();
          refresh();
          resetInitialValues();
        });
      },
      onError: (error: any) => {
        if (error.status) {
          notify(error.message || 'Não foi possível criar a Fatura', { type: 'warning' });
          return;
        }
      }
    });
  }

  const BillToolbar = ({ handleSubmit, selectedIds, ...props }: { handleSubmit: (value: object, clear: () => void) => void, selectedIds: string[]; }) => {
    const { getValues, resetField, getFieldState, ...form } = useFormContext();
    const billValue = getValues("billValue") || 0;
    const chainId = getValues("chainId");


    const clear = () => {
      resetField("billNumber");
      resetField("billValue");
      resetField("billExpiration");
      resetField("fillinIds");
      resetField("subject");
      resetField("body");
      resetField("chatFiles");
    }

    const checkRules = () => {
      if (!selectedIds?.length) {
        notify('Nenhum abastecimento selecionado', { type: 'warning' });
        return null;
      }

      if (UserRole.admin === identity?.role && selectFillins.find((f) => f.chainId !== chainId)) {
        notify('Rede divergente', { type: 'warning' });
        return null;
      }

      form.setValue("fillinIds", selectedIds);

      if (isDifferenceSignificant(billValue, totalValue)) {
        setOpenDialog(true);
        return null;
      }

      form.handleSubmit(() => handleSubmit(getValues(), clear))();
    }

    const DialogConfirm = () => {

      const closeDialog = () => {
        setOpenDialog(false);
      }

      const diff = (billValue - totalValue);

      return (
        <Dialog
          fullWidth
          open={openDialog}
        >
          <DialogTitle textAlign="center">Divergência</DialogTitle>
          <DialogContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 0, color: 'text.secondary' }}>Descrição</TableCell>
                  <TableCell sx={{ paddingRight: 0, color: 'text.secondary' }} align="right">Valores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '& th': { border: 0 } }}>
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>Valor do Boleto</TableCell>
                  <TableCell component="th" align="right" sx={{ paddingRight: 0, fontWeight: 600 }}>{formatPrice(billValue)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>Abastecimentos</TableCell>
                  <TableCell component="th" align="right" sx={{ paddingRight: 0, fontWeight: 600 }}>{formatPrice(totalValue)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack flexDirection="row" justifyContent="flex-end" marginTop={1.5}>
              <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '40%', minWidth: 200 }}>
                <Typography variant="body2" >Diferença</Typography>
                <Typography variant="body2" color="warning.main" fontWeight="600">{formatPrice(diff)}</Typography>
              </Stack>
            </Stack>
            <DialogActions sx={{ marginTop: 4, padding: 0 }}>
              <Button variant="outlined" onClick={closeDialog} label="ra.action.cancel" />
              <Button variant="contained" onClick={() => {
                closeDialog();
                form.handleSubmit(() => handleSubmit(getValues(), clear))();
              }}
                label="ra.action.confirm" />
            </DialogActions>
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <Toolbar {...props} >
        <Button
          startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon style={{ fontSize: 20 }} />}
          disabled={isLoading}
          label="Salvar"
          onClick={checkRules}
          variant="contained"
          size="medium"
        />
        <DialogConfirm />
      </Toolbar>
    );
  };


  return (
    <Card sx={{ marginTop: 2 }}>
      <Box>
        <SimpleForm toolbar={<BillToolbar selectedIds={selectedIds} handleSubmit={handleSubmit} />} sanitizeEmptyValues>
          <Grid container spacing={2} columns={12}>
            {Permission({
              userRole: UserRole.admin,
              children: (
                <Grid item sm={12} md={4}>
                  <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
                    <CustomSelectInput setData={setChain} label="Rede" optionText="name" fullWidth isRequired validate={[required()]} />
                  </ReferenceInput>
                </Grid>
              )
            })}
            <Grid item sm={12} md={4}>
              <FormDataConsumer>
                {({ formData }) => (
                  <ReferenceInput filter={{ chainId: formData.chainId }} source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
                    <SelectInput label="Grupo de Transportadora" optionText="name" fullWidth isRequired validate={[required()]} disabled={(UserRole.admin === identity?.role && !formData.chainId)} />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={4}>
              <FormDataConsumer>
                {({ formData }) => (
                  <ReferenceInput
                    source="vehicleBaseId"
                    reference="vehicle-bases"
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={null}
                    filter={{ companyId: formData.companyId }}
                  >
                    <CustomSelectInput setData={setVehicleBase} label="Filial" optionText="name" fullWidth disabled={selectedIds?.length || !formData.companyId} isRequired validate={[required()]} />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={12}>
            <Grid item sm={12} md={4}>
              <TextInput source="billNumber" label="Número do Boleto" inputProps={{ maxLength: 15 }} validate={[number()]} fullWidth />
            </Grid>
            <Grid item sm={12} md={4}>
              <PriceInput source="billValue" label="Valor do Boleto" locales="pt-BR" fullWidth />
            </Grid>
            <Grid item sm={12} md={4}>
              <DateInput
                source='billExpiration'
                type="date"
                label="Vencimento do Boleto"
                format={dateInputFormatter}
                parse={value => moment(value).toISOString()}
                fullWidth
              />
            </Grid>
            <Grid item md={12} sx={{ paddingTop: '0px!important' }}>
              <FormDataConsumer>
                {({ formData }) => (
                  <ChatAccordion companyId={formData?.companyId} disabledSelectOrigin />
                )}
              </FormDataConsumer>
            </Grid>
            <Grid item md={12}>
              <Divider sx={{ marginBottom: 2 }} />
              <Labeled label="Selecione os abastecimentos" fullWidth>
                {props.children}
              </Labeled>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Card>
  );
};

export default BillBulkAction;