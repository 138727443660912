import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import { downloadCSV, ExportButton as RaExportButton, useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import jsonexport from 'jsonexport/dist';
import { exportToXLSX } from '../../utils/utils';

interface ExportButtonProps {
  resource: string;
  label?: string;
  exporter: any,
}

const useStyles = makeStyles(() => {
  return ({
    menuOption: {
      padding: 0,
      minWidth: 150,
      '& button': {
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '5px 15px',
      },
    },
  })
});

const ExportButton: React.FC<ExportButtonProps> = (props) => {

  const {
    resource,
    label = 'ra.action.export',
    exporter,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const translate = useTranslate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleExporter = async (type: string, props: any) => {
    if (!exporter) { return; }

    const { data, fileName } = await exporter(...props);

    if (type === 'CSV') {
      jsonexport(data, (_err: any, csv: any) => downloadCSV(csv, fileName || resource));
    }
    if (type === 'XLSX') {
      exportToXLSX(data, fileName || resource);
    }
  }

  return (
    <>
      <Button
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<DownloadIcon />}
        size="small"
        sx={{ lineHeight: 1.1 }}
      >
        {translate(label)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose} className={classes.menuOption} disableRipple>
          <RaExportButton
            resource={resource}
            label="Arquivo CSV"
            maxResults={1000}
            exporter={(...props) => handleExporter('CSV', props)}
            startIcon={<FileIcon />}
            disableRipple
          />
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuOption} disableRipple>
          <RaExportButton
            resource={resource}
            label="Arquivo XLSX"
            maxResults={1000}
            exporter={(...props) => handleExporter('XLSX', props)}
            startIcon={<FileIcon />}
            disableRipple
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default ExportButton;