import React from 'react';
import { FormDataConsumer, minValue, NumberInput, RadioButtonGroupInput, required } from 'react-admin';
import { PriceInput } from '../../components';
import { Box, Typography, CircularProgress } from '@mui/material';
import { formatPrice } from '../../utils/utils';

export const SelectFuelAndLimitType: React.FC<any> = ({ status, hoses }) => {

  const formatFuelName = (hose) => {
    return `${hose.fuel.name} - ${formatPrice(hose.price, { minimumFractionDigits: 3 })}`;
  }

  return (
    status === 'pendingReserve' ?
      <Box sx={{ marginTop: '40px', textAlign: 'center', width: '100%' }}>
        <CircularProgress size={30} sx={{ marginBottom: '10px' }} />
        <Typography variant="h6" gutterBottom textAlign={'center'}>
          Aguardando a reserva
        </Typography>
      </Box>
      :
      <>
        <RadioButtonGroupInput
          source="fuelType"
          label="Combustível"
          choices={hoses.map(hose => ({ id: hose.fuel.fuelType, name: formatFuelName(hose) }))}
          row={false}
          validate={required()}
        />
        <RadioButtonGroupInput
          source="limitType"
          label="Abastecer por"
          defaultValue="X"
          choices={[
            // { id: '$', name: 'Valor' },
            { id: 'V', name: 'Volume' },
            { id: 'X', name: 'Encher tanque' },
          ]}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) => (
            formData.limitType === 'V' ?
              <NumberInput source="limitValue" label="Quantidade em litros" validate={[required(), minValue(1)]} />
              :
              formData.limitType === '$' ?
                <PriceInput source="limitValue" label="Valor em R$" validate={[required(), minValue(1)]} />
                : <></>
          )}
        </FormDataConsumer>
      </>
  );
};