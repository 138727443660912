import {
  Avatar,
  CardHeader,
  CardMedia,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  styled
} from '@mui/material';
import React from 'react';
import {
  Close as CloseIcon,
  Place as PlaceIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { formatCellphone, formatCnpj } from '../../utils/utils';
import { grey } from '@mui/material/colors';

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 10,
  left: 10,
}));

const StationInfoSidebar: React.FC<{ data: any, isVisible: boolean, toggleDrawer: (open: boolean) => void }> = ({ data, isVisible, toggleDrawer }) => {
  return (
    data &&
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          top: 48,
          minWidth: 400
        },
        left: 'auto'
      }}
      anchor={'right'}
      open={isVisible}
      hideBackdrop={true}
    >
      <CloseButton sx={{ color: data.coverImagePath ? 'white' : 'black' }} aria-label="fechar" onClick={() => toggleDrawer(false)}>
        <CloseIcon />
      </CloseButton>
      <CardMedia
        sx={{ height: 240, bgcolor: grey[100] }}
        image={data.coverImagePath || require('../../assets/no-image-map.jpeg')}
      />
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          }
        }}
        title={data.name}
        titleTypographyProps={{ fontSize: 20 }}
      />

      {
        data.chain &&
        <List
          sx={{ width: '100%' }}
          subheader={<ListSubheader>Rede</ListSubheader>}
        >
          <ListItem>
            <ListItemIcon>
              {
                data.chain.logoPath ?
                  <Avatar src={data.chain.logoPath} />
                  :
                  <Avatar>{data.chain.name.charAt(0).toUpperCase()}</Avatar>
              }
            </ListItemIcon>
            <ListItemText primary={data.chain.name} />
          </ListItem>
        </List>
      }

      <List
        sx={{ width: '100%' }}
        subheader={<ListSubheader>Informações básicas</ListSubheader>}
      >
        {
          (data.street && data.city && data.state) &&
          <ListItem>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <Link target='_blank' underline='hover' href={`https://www.google.com/maps/search/?api=1&query=${data.latitude}%2C${data.longitude}`} >
              <ListItemText sx={{ maxWidth: '312px' }} primary={`${data.street}, ${data.streetNumber || 's/n'}, ${data.city} - ${data.state}`} />
            </Link>
          </ListItem>
        }
        {
          data.phone &&
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={formatCellphone(data.phone)} />
          </ListItem>
        }
        {
          data.cnpj &&
          <ListItem>
            <ListItemIcon>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold', fontStyle: 'italic' }}>CNPJ</Typography>
            </ListItemIcon>
            <ListItemText primary={formatCnpj(data.cnpj)} />
          </ListItem>
        }
      </List>
    </Drawer>
  );
}

export default StationInfoSidebar;