import { Create, CreateButton, Datagrid, Edit, ExportButton, Filter, SelectInput, SimpleForm, TextField, TextInput, TopToolbar } from 'react-admin';
import { List } from '../components';
import { PERMISSIONS } from '../constants';
import { StateChoices } from '../models';

const required = (message = 'Obrigatório') =>
  value => value || value === 0 ? undefined : message;


const StateSelectInput = ({ source, ...props }) => (
  <SelectInput {...props} source={source} choices={StateChoices} optionValue="value" optionText="label" />
);

const CityEdit = (props) => (
  <Edit title='Editar cidade' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required()} />
      <StateSelectInput source="state" label="Estado" validate={required()} />
    </SimpleForm>
  </Edit>
);

const CityCreate = (props) => (
  <Create title='Adicionar cidade' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required()} />
      <StateSelectInput source="state" label="Estado" validate={required()} />
    </SimpleForm>
  </Create>
);

const CityFilter = props => {
  return (
    <Filter {...props}>
      <TextInput sx={{ marginBottom: '4px' }} label="Pesquisar" source="search" alwaysOn />
      <StateSelectInput source="state" label="Estado" alwaysOn />
    </Filter>
  );
};

const ToolbarListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const CityList = props => (
  <List
    {...props}
    title="Cidades"
    filters={<CityFilter />}
    actions={<ToolbarListActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nome" />
      <TextField source="state" label="Estado" />
    </Datagrid>
  </List>
);

const resource = {
  list: CityList,
  edit: CityEdit,
  create: CityCreate,
  name: 'cities',
  permissions: [PERMISSIONS.ADMIN]
};

export default resource;
