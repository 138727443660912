import { ArrayInputProps } from 'ra-ui-materialui';
import React from 'react';
import { ArrayInput as RaArrayInput } from 'ra-ui-materialui';

const ArrayInput: React.FC<ArrayInputProps> = (props) => {
  return (
    <RaArrayInput {...props}>
      {React.cloneElement(props.children,
        { sx: { '& .RaSimpleFormIterator-action': { visibility: 'visible' } } }
      )}
    </RaArrayInput>
  );
}

export default ArrayInput;