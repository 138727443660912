import {
  Datagrid,
  List,
  TextField
} from 'react-admin';
import ListPagination from '../../components/ListPagination';

const list = props => (
  <List {...props}
    perPage={25}
    pagination={<ListPagination />}
    exporter={false}
    title="Permissões">
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nome" />
    </Datagrid>
  </List>
)
export default list;