import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  Show,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  email,
  minLength,
  useGetIdentity,
  useRecordContext
} from 'react-admin';
import { List, Permission } from '../components';
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from '../constants';
import { CEPInput, CNPJInput, PhoneInput, StateInput } from '../utils/Inputs';
import { formatCellphone, formatCnpj, getCompanyId } from '../utils/utils';
import ComponentWithPermissions from './ComponentWithPermissions';
import { UserRole } from '../providers/authProvider';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const SubCompanyList = (props) => {
  const { identity } = useGetIdentity();

  return (
    <List {...props}
      title="Transportadoras"
      filters={<SearchFilter />}
      filter={identity?.role === UserRole.company ? { companyId: getCompanyId()} : {}}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ImageField
          source="logoPath"
          label="Logo"
        />
        <TextField source="name" label="Transportadora" />
        <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
        {
          Permission({
            userRole: UserRole.admin,
            children: (
              <ReferenceField
                source="companyId"
                label="Grupo"
                reference="companies"
              >
                <TextField source="name" />
              </ReferenceField>
            )
          })
        }
        <DateField source="createdAt" label="Adicionado em" locales="pt-BR" />
        <Permission permission={PERMISSIONS.EDIT_SUB_COMPANIES}>
          <EditButton />
        </Permission>
        <Permission userRole={UserRole.admin}>
          <DeleteButton />
        </Permission>
      </Datagrid>
    </List>
  )
}

const CustomToolbar = () => {
  return (
    <Toolbar style={{ justifyContent: 'space-between' }}>
      <SaveButton />
      <Permission userRole={UserRole.admin}>
        <DeleteButton />
      </Permission>
    </Toolbar>
  )
}

const SubCompanyEdit = (props) => (
  <Edit title='Editar Transportadora' {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Identificação">
        <TextField disabled label="Código" source="code" />
        <Permission userRole={UserRole.admin}>
          <ReferenceInput
            label="Grupo de transportadora"
            source="companyId"
            reference="companies"
            sort={{ field: "name", order: "ASC" }}>
            <SelectInput optionText="name" label="Grupo de transportadora" validate={required()} />
          </ReferenceInput>
        </Permission>
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="email" label="Email" type="email" validate={createCompanyEmailValidate} />
        <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
        <PhoneInput source="phone" label="Telefone" />
        <ImageInput source="logofile" label="Logo" accept="image/*" placeholder="Selecione ou arraste aqui">
          <ImageField source="src" label="Imagem" />
        </ImageInput>
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" validate={required()} />
        <TextInput source="streetNumber" label="Número" validate={required()} />
        <TextInput source="neighbourhood" label="Bairro" validate={required()} />
        <TextInput source="city" label="Cidade" validate={required()} />
        <StateInput source="state" label="Estado" validate={required()} />
        <CEPInput source="cep" label="CEP" isRequired />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const createCompanyEmailValidate = [email(), required()];
const createStationPhoneValidate = [minLength(10), required()];

const SubCompanyCreate = (props) => (
  <Create title='Nova Transportadora' {...props}>
    <TabbedForm>
      <FormTab label="Identificação">
        <ReferenceInput
          label="Grupo de transportadora"
          source="companyId"
          reference="companies"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" label="Grupo de transportadora" validate={required()} />
        </ReferenceInput>
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="email" label="Email" type="email" validate={createCompanyEmailValidate} />
        <CNPJInput source="cnpj" label="CNPJ" isRequired />
        <PhoneInput source="phone" label="Telefone" validate={createStationPhoneValidate} />
        <ImageInput source="logofile" label="Logo" accept="image/*" placeholder="Selecione ou arraste aqui">
          <ImageField source="src" label="Imagem" />
        </ImageInput>
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" validate={required()} />
        <TextInput source="streetNumber" label="Número" validate={required()} />
        <TextInput source="neighbourhood" label="Bairro" validate={required()} />
        <TextInput source="city" label="Cidade" validate={required()} />
        <StateInput source="state" label="Estado" validate={required()} />
        <CEPInput source="cep" label="CEP" isRequired />
      </FormTab>
    </TabbedForm>
  </Create>
);

const SubCompanyName = () => {
  const record = useRecordContext();
  return <span>{record && record.name}</span>;
};

const SubCompanyShow = props => (
  <Show title={<SubCompanyName />} {...props}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <TextField disabled label="ID" source="id" />
        <TextField source="code" label="Código" />
        <ReferenceField label="Grupo de transportadora" source="companyId" reference="companies">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="Nome" />
        <TextField label="Email" source="email" type="email" />
        <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
        <ImageField
          source="logoPath"
          label="Logo"
          emptyText='Nenhuma logo encontrada.'
        />
      </Tab>
      <Tab label="Endereço">
        <TextField source="street" label="Rua" />
        <TextField source="streetNumber" label="Número" />
        <TextField source="neighbourhood" label="Bairro" />
        <TextField source="city" label="Cidade" />
        <TextField source="state" label="Estado" />
        <NumberField source="cep" label="CEP" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = {
  list: SubCompanyList,
  edit: new ComponentWithPermissions({ component: SubCompanyEdit, permissions: [PERMISSIONS.EDIT_SUB_COMPANIES] }),
  create: new ComponentWithPermissions({ component: SubCompanyCreate, permissions: [PERMISSIONS.ADMIN] }),
  show: SubCompanyShow,
  name: 'sub-companies',
  permissions: [PERMISSIONS.LIST_SUB_COMPANIES]
}
export default resource;