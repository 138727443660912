import React from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { FillinReviewStatus } from '../../models';

const FillinStatusField: React.FC<FunctionFieldProps> = ({ ...props }) => {

  const convertStatusLabel = (status) => {
    if (status === 'approved') {
      return 'Aprovado';
    } else if (status === 'reproved') {
      return 'Reprovado';
    } else {
      return '-';
    }
  }

  return (
    <FunctionField {...props} render={record => record.stationReviewStatus !== FillinReviewStatus.approved ? '--' : convertStatusLabel(record.status)} />
  );
}

export default FillinStatusField;