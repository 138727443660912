import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1d71b8',
      light: '#01baef',
      dark: '#1a1d3e',
    },
    secondary: {
      main: '#1d71b8',
      light: '#01baef',
      dark: '#1a1d3e',
    },
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: '#e5e5e5',
        width: '100%',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
  },
  sidebar: {
    width: 270
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #4f3cc9',
          },
        },
      },
    },
  },
})
