import React from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography, AccordionProps, styled } from '@mui/material';
import { ReferenceArrayInput, SelectArrayInput, SelectInput, TextInput } from 'ra-ui-materialui';
import ChatInput from '../ChatInput';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const ChatAccordion: React.FC<{ disabledSelectOrigin?: boolean, style?: object, companyId?: string }> = ({ disabledSelectOrigin = false, style, companyId }) => {

  const choicesOrigin = [
    {
      value: 'contract',
      label: "Contrato",
    },
    {
      value: 'invoice',
      label: "Nota fiscal",
    },
  ];

  return (
    <>
      <Accordion sx={style}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Enviar uma mensagem</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextInput fullWidth source="subject" label="Assunto" />
          {
            disabledSelectOrigin === false &&
            <SelectInput
              fullWidth
              choices={choicesOrigin}
              source="origin"
              label="Origem"
              optionValue="value"
              optionText="label"
            />
          }
          {
            companyId &&
            <ReferenceArrayInput reference="employees" filter={{ companyId }} source="participantIds">
              <SelectArrayInput optionText="name" label="Participantes (Todos)" fullWidth />
            </ReferenceArrayInput>
          }
          <ChatInput source="body" sourceFiles="chatFiles" />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ChatAccordion;