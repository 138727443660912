import React, { useCallback, useEffect, useState } from 'react';
import { ListPagination, Permission, UploadInvoiceXml, List } from '../../components';
import { PERMISSIONS } from '../../constants';
import { Filter, ReferenceInput, SelectInput, NumberInput, Button, DateTimeInput } from 'ra-ui-materialui';
import { getChainId } from '../../utils/utils';
import { useListContext, useRecordContext } from 'react-admin';
import { UserRole } from '../../providers/authProvider';
import dayjs from 'dayjs';
import { FillinDatagrid, ScreenType } from '../Fillin';
import { FillinReviewStatus, FillinStatus } from '../../models';
import { Box, Radio, Typography } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const RadioButtonRow: React.FC<any> = ({ selectedIds, currentSelectedId, onChange }) => {
  const record = useRecordContext();

  const onChangeHandler = (e: any) => {
    e.stopPropagation();
    onChange(record);
  };

  const activeCheckBox = useCallback(() => {
    return !!(selectedIds.includes(record.id) || record.id === currentSelectedId);
  }, [selectedIds, currentSelectedId]);

  return (
    <Radio
      checked={activeCheckBox()}
      onChange={onChangeHandler}
      onClick={(e) => {
        e.stopPropagation();
      }}
      disabled={(currentSelectedId !== record.id && selectedIds.includes(record.id)) || (currentSelectedId && selectedIds.includes(currentSelectedId))}
    />
  );
};


const FillinList = (props: any) => {
  return (
    <FillinDatagrid
      rowClick={null}
      screen={ScreenType.billShow}
      bulkActions={(
        <RadioButtonRow selectedIds={props.selectedIds} currentSelectedId={props.currentSelectedId} onChange={props.handleRadioClick} />
      )}
    />
  );
}

const ListFilters = ({ xmlLoaded, ...props }: any) => {
  const { filterValues, filter, setFilters, } = useListContext();

  useEffect(() => {
    setFilters({
      ...filter,
      ...filterValues,
      ...(xmlLoaded.fuel ? {
        fuelId: xmlLoaded.fuel.id,
      } : {}),
      ...(xmlLoaded.amount ? {
        estimateAmount: xmlLoaded.amount,
      } : {}),
      ...(xmlLoaded.value ? {
        estimateValue: xmlLoaded.value,
      } : {}),
    }, {}, true);
  }, [xmlLoaded]);

  const clearFilters = () => {
    setFilters({
      ...filter,
      from: dayjs().subtract(1, 'month').startOf('day').toISOString(),
      to: dayjs().endOf('day').toISOString()
    }, {}, true);
  }

  return (
    <Filter {...props}>
      <DateTimeInput
        source="from"
        label="De"
        sx={{ marginBottom: 0.5 }}
        alwaysOn
      />
      <DateTimeInput
        source="to"
        label="Até"
        sx={{ marginBottom: 0.5 }}
        alwaysOn
      />
      {
        Permission({
          userRole: UserRole.admin,
          children: (
            <ReferenceInput
              source="chainId"
              reference="chains"
              alwaysOn
            >
              <SelectInput label="Rede" optionText="name" emptyText="Todos" />
            </ReferenceInput>
          )
        })
      }
      <NumberInput
        sx={{ maxWidth: 110 }}
        source="estimateAmount"
        label="Litros"
        InputProps={{
          disabled: true
        }}
        alwaysOn
      />
      <NumberInput
        sx={{ maxWidth: 110 }}
        source="estimateValue"
        label="Valor"
        InputProps={{
          disabled: true
        }}
        alwaysOn
      />
      <ReferenceInput
        reference="stations"
        source="stationId"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={false}
        filter={filterValues?.chainId ? { chainId: filterValues.chainId } : getChainId() ? { chainId: getChainId() } : {}}
        alwaysOn
      >
        <SelectInput optionText="name" label="Posto" emptyText="Todos" />
      </ReferenceInput>
      <ReferenceInput
        reference="fuels"
        source="fuelId"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={false}
        alwaysOn
      >
        <SelectInput optionText="name" label="Combustível" emptyText="Todos" />
      </ReferenceInput>
      <ReferenceInput
        reference="sub-companies"
        source="subCompanyId"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={false}
        alwaysOn
      >
        <SelectInput optionText="name" label="Transportadora" emptyText="Todas" />
      </ReferenceInput>
      <Button
        startIcon={<FilterAltOffIcon />}
        variant="outlined"
        size="large"
        label="Limpar"
        onClick={clearFilters}
        sx={{ marginBottom: 0.8 }}
        //@ts-ignore
        alwaysOn
      />
    </Filter>
  );
}

const InvoiceUpload: React.FC = (props) => {

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedFillins, setSelectedFillins] = useState<{ id: string }[]>([]);
  const [currentSelectedId, setCurrentSelectedId] = useState<string | null>(null);
  const [xmlLoaded, setXmlLoaded] = useState<any>({});

  useEffect(() => {
    setSelectedFillins((fillins: any[]) => fillins.filter((fillin) => [...selectedIds, currentSelectedId].includes(fillin.id)));
  }, [selectedIds, currentSelectedId]);

  const initialFilters: any = {
    status: FillinStatus.approved,
    stationReviewStatus: FillinReviewStatus.approved,
    generateFinancialMovement: true,
    hasInvoice: false,
  }

  if (getChainId()) {
    initialFilters.chainId = getChainId();
  }

  const resetInitialValues = (hardReset?: boolean) => {
    setCurrentSelectedId(null);
    if (hardReset) {
      setSelectedFillins([]);
      setSelectedIds([]);
      setXmlLoaded({});
    }
  }

  const handleRadioClick = (record: any) => {
    setCurrentSelectedId(record.id);
    setSelectedFillins(fillins => ([...fillins, record]));
  }

  return (
    <Permission permission={PERMISSIONS.CREATE_INVOICE}>
      <UploadInvoiceXml
        selectedIds={selectedIds}
        selectedFillins={selectedFillins}
        setSelectedIds={setSelectedIds}
        currentSelectedId={currentSelectedId}
        setCurrentSelectedId={setCurrentSelectedId}
        xmlLoaded={xmlLoaded}
        setXmlLoaded={setXmlLoaded}
        resetInitialValues={resetInitialValues}
        totalValue={4}
      >
        {
          (xmlLoaded && xmlLoaded.destCnpj) ?
            <List
              {...props}
              resource="fillins"
              perPage={25}
              pagination={<ListPagination />}
              exporter={false}
              title="importar nota fiscal"
              filter={getChainId() ? { chainId: getChainId(), ...initialFilters } : initialFilters}
              filterDefaultValues={{
                ...(xmlLoaded.issueDate ? {
                  from: dayjs(xmlLoaded.issueDate).subtract(1, 'day').startOf('day').toISOString(),
                  to: dayjs(xmlLoaded.issueDate).endOf('day').toISOString(),
                } : {
                  from: dayjs().subtract(1, 'month').startOf('day').toISOString(),
                  to: dayjs().endOf('day').toISOString(),
                }),
                ...(xmlLoaded.fuel ? {
                  fuelId: xmlLoaded.fuel.id,
                } : {}),
                ...(xmlLoaded.station ? {
                  stationId: xmlLoaded.station.id,
                } : {}),
                ...(xmlLoaded.vehicleBase ? {
                  subCompanyId: xmlLoaded.vehicleBase.subCompanyId,
                } : {}),
                ...(xmlLoaded.amount ? {
                  estimateAmount: xmlLoaded.amount,
                } : {}),
                ...(xmlLoaded.value ? {
                  estimateValue: xmlLoaded.value,
                } : {}),
              }}
              filters={<ListFilters {...props} xmlLoaded={xmlLoaded} />}
              sort={{ field: 'receivedAt', order: 'DESC' }}
              hasCreate={false}
              storeKey={false}
            >
              <FillinList
                selectedIds={selectedIds}
                currentSelectedId={currentSelectedId}
                setCurrentSelectedId={setCurrentSelectedId}
                handleRadioClick={handleRadioClick}
              />
            </List>
            :
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="subtitle1" align="center" gutterBottom>Informe o arquivo XML para listar os abastecimentos</Typography>
            </Box>
        }
      </UploadInvoiceXml>
    </Permission>
  );
}

export default InvoiceUpload;