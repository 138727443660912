export enum FillinReviewStatus {
  approved = 'approved',
  denied = 'denied',
  pending = 'pending',
};

export namespace FillinReviewStatus {
  export const translateStatus = (type: FillinReviewStatus): string => {
    switch (type) {
      case FillinReviewStatus.approved:
        return "Aprovado";
      case FillinReviewStatus.denied:
        return "Reprovado"
      case FillinReviewStatus.pending:
        return "Pendente"
      default: return "";
    };
  };

  export const fillinReviewStatusChoices: Array<{ id: string, name: string }> = Object.values(FillinReviewStatus)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: FillinReviewStatus.translateStatus(type as FillinReviewStatus),
    } as { id: string, name: string }));
};